import { takeEvery, put } from 'redux-saga/effects';
import { Fetch } from '../../utils/fetch';
import { request_faq, successFaq } from '../actions/faq';
import config from '../../config/url';
import { setError } from '../actions/error';

export function* FaqMiddleware() {
    yield takeEvery(request_faq, getFaq);
}

function* getFaq() {
    const faq = yield Fetch(`${config.apiCMS}/v1/faq/list`);
    if (faq) {
        yield put(successFaq(faq));
    } else {
        yield put(setError(true));
    }
}
