import {
    request_news,
    request_news_success,
    request_single_news,
    request_single_news_success
} from '../actions/news';

const initialState = {
    newsList: {
        isLoading: false,
        news: []
    },
    singleNews: {
        isLoading: false,
        news: {}
    }
};

export const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_news:
            return {
                ...state,
                newsList: {
                    ...state.newsList,
                    isLoading: true
                }
            };
        case request_news_success:
            return {
                ...state,
                newsList: {
                    ...state.newsList,
                    isLoading: false,
                    news: action.payload
                }
            };

        case request_single_news:
            return {
                ...state,
                singleNews: {
                    ...state.singleNews,
                    isLoading: true
                }
            };
        case request_single_news_success:
            return {
                ...state,
                singleNews: {
                    ...state.singleNews,
                    news: {
                        ...state.singleNews.news,
                        [action.payload.alias]: action.payload.news
                    },
                    isLoading: false
                }
            };
        default:
            return state;
    }
};
