import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
`;

export const ListWrapper = styled.div`
    flex: 1;
`;

export const ImageWrapper = styled.div`
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 720px) {
        display: none;
    }
`;

export const Image = styled.img`
    display: ${({ active }) => (active ? 'block' : 'none')};
    width: 80%;
`;

export const AdaptiveImage = styled.img`
    display: none;
    width: 100%;
    @media (max-width: 720px) {
        display: block;
    }
`;

export const ListItem = styled.div``;

export const ListTitle = styled.h3`
    display: block;
    width: fit-content;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px; 
    color: ${({ theme, active }) => (active ? theme.color11 : theme.color5)};
    border-bottom: 2px solid
        ${({ theme, active }) => (active ? theme.color11 : 'transparent')};
    cursor: pointer;
    text-transform: uppercase;

    @media (max-width: 720px) {
        cursor: default;
        color: ${({ theme }) => theme.color11};
        border-bottom-color: transparent;
    }
`;

export const Ol = styled.ol`
    overflow: hidden;
    padding: ${({ active }) => (active ? '25px 0 40px 22px' : '0 0 0 22px')};
    max-height: ${({ active }) => (active ? '500px' : '0')};
    box-sizing: border-box;
    margin: 0;
    transition: padding 150ms, max-height 150ms;

    @media (max-width: 720px) {
        max-height: 300px;
        padding: 25px 0 40px 22px;
    }
`;

export const Li = styled.li`
    line-height: 36px;
`;
