import React /*, { useEffect }*/ from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMobileMenu } from '../../../services/actions/mobileMenu';
import { Hamburger as HamburgerWrapper, HamburgerInner } from './styled';

export const Hamburger = () => {
    const d = useDispatch();
    const { open } = useSelector(state => state.mobileMenu);

    const activeHandler = () => d(toggleMobileMenu(!open));

    // useEffect(() => {
    // const body = document.body;
    // const html = body.parentNode;
    // if (open) {
    //     body.style.overflow = 'hidden';
    //     html.style.overflow = 'hidden';
    // } else {
    //     body.style.overflow = 'auto';
    //     html.style.overflow = 'auto';
    //     }
    // }, [open]);

    return (
        <HamburgerWrapper onClick={activeHandler}>
            <HamburgerInner active={open} />
        </HamburgerWrapper>
    );
};
