import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import { Text, Icon, SelectText } from './styled';

export const Fees = () => {
    const { formatMessage } = useIntl();
    return (
        <Section>
            <Container>
                <Title>{formatMessage({ id: 'content_fees_tittle' })}</Title>
                <Text>
                    {formatMessage({ id: 'fees_content_fees_header_all' })}
                    &nbsp;
                    <SelectText>0.25%</SelectText>
                    &nbsp;
                    {formatMessage({
                        id: 'fees_content_fees_header_commission'
                    })}
                </Text>
                <Icon className="icon-fees" />
            </Container>
        </Section>
    );
};
