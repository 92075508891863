import styled from 'styled-components';

export const ContactWrapper = styled.div`
    margin-top: 40px;
`;

export const ContactItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 60px;
    flex-wrap: wrap;
`;

export const ContactIcon = styled.i`
    margin-right: 25px;
    color: ${({ theme }) => theme.color11};
`;

export const ContactTitle = styled.span`
    margin-right: 18px;
`;

export const ContactLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.color11};
    transition: color 150ms;
    &:hover {
        color: ${({ theme }) => theme.color9};
    }
`;

export const SocialWrapper = styled.div`
    margin-top: 40px;
`;

export const SocialTitle = styled.div`
    font-size: 24px;
    line-height: 60px;
`;

export const SocialLinkWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const SocialLink = styled.a`
    text-decoration: none;
    margin-right: 14px;
`;

export const SocialIcon = styled.i`
    font-size: 32px;
    color: ${({ theme }) => theme.color8};
    transition: color 150ms;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color11};
    }
`;
