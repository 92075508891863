import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Section } from '../../Section';
import { Container } from '../../Container';
import { InnerLink } from '../../InnerLink';
import {
    NewsContainer,
    NewsTitle,
    NewsCard,
    NewsDate,
    NewsImage,
    NewsCardContent,
    Button
} from './styled';

export const News = ({ addPages }) => {
    const [isVisible, setVisibleBtn] = useState(true);
    const { news, isLoading } = useSelector(state => state.news.newsList);
    const { formatMessage } = useIntl();
    if (isLoading && !news.length) return null;

    return (
        <Section clearTopOffset>
            <Container>
                <NewsContainer>
                    {news.map((newsData, idx) => {
                        if (idx === 0) return null;
                        return (
                            <InnerLink
                                key={newsData.alias}
                                to={`/news/${newsData.alias}`}
                            >
                                <NewsCard>
                                    <NewsImage
                                        src={newsData.image}
                                        alt={newsData.title}
                                    />
                                    <NewsCardContent>
                                        <NewsTitle>{newsData.title}</NewsTitle>
                                        <NewsDate>
                                            {moment
                                                .unix(newsData.created_at)
                                                .format('MMM DD, YYYY | HH:mm')}
                                        </NewsDate>
                                    </NewsCardContent>
                                </NewsCard>
                            </InnerLink>
                        );
                    })}
                </NewsContainer>
                {isVisible && (
                    <Button
                        onClick={() => {
                            addPages();
                            setVisibleBtn(false);
                        }}
                    >
                        {formatMessage({ id: 'content_news_button_more' })}
                    </Button>
                )}
            </Container>
        </Section>
    );
};
