export const localization_languages_request = 'localization.languages.request';
export const localization_languages_request_failed =
    'localization.languages.request.failed';
export const localization_languages_request_success =
    'localization.languages.request.success';

export const localization_translation_request =
    'localization.translation.request';
export const localization_translation_request_failed =
    'localization.translation.request.failed';
export const localization_translation_request_success =
    'localization.translation.request.success';

export const localization_set_default_locale =
    'localization.default-locale.set';
export const localization_set_locale = 'localization.locale.set';

export const setLocale = locale => ({
    type: localization_set_locale,
    payload: locale
});

export const setDefaultLocale = locale => ({
    type: localization_set_default_locale,
    payload: locale
});

export const getLocalizationLanguages = () => ({
    type: localization_languages_request
});

export const getLocalizationLanguagesSuccess = payload => ({
    type: localization_languages_request_success,
    payload
});

export const getLocalizationLanguagesFailed = payload => ({
    type: localization_languages_request_failed,
    payload
});

export const getLocalizationTranslation = languages => ({
    type: localization_translation_request,
    payload: languages
});

export const getLocalizationTranslationSuccess = payload => ({
    type: localization_translation_request_success,
    payload
});

export const getLocalizationTranslationFailed = payload => ({
    type: localization_translation_request_failed,
    payload
});
