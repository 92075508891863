import React from 'react';
import { useIntl } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    AdvantagesWrapper,
    AdvantagesIcon,
    AdvantagesItem,
    AdvantagesText,
    AdvantagesTitle
} from './styled';
import { Section } from '../../Section';
import { Container } from '../../Container';
import { TitleH2 } from '../../Title';

export const Advantages = () => {
    const { formatMessage } = useIntl();
    return (
        <Section second>
            <Container>
                <TitleH2>
                    {formatMessage({ id: 'advantages_block_main_title' })}
                </TitleH2>
                <AdvantagesWrapper>
                    <ScrollAnimation animateOnce animateIn="fadeInUp" delay={0}>
                        <AdvantagesItem>
                            <AdvantagesIcon className="icon-advantages-1" />
                            <AdvantagesTitle>
                                {formatMessage({
                                    id: 'advantages_block_header_1'
                                })}
                            </AdvantagesTitle>
                            <AdvantagesText>
                                {formatMessage({
                                    id: 'advantages_block_text_1'
                                })}
                            </AdvantagesText>
                        </AdvantagesItem>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        delay={300}
                    >
                        <AdvantagesItem>
                            <AdvantagesIcon className="icon-advantages-2" />
                            <AdvantagesTitle>
                                {formatMessage({
                                    id: 'advantages_block_header_2'
                                })}
                            </AdvantagesTitle>
                            <AdvantagesText>
                                {formatMessage({
                                    id: 'advantages_block_text_2'
                                })}
                            </AdvantagesText>
                        </AdvantagesItem>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        delay={600}
                    >
                        <AdvantagesItem>
                            <AdvantagesIcon className="icon--advantages-3" />
                            <AdvantagesTitle>
                                {formatMessage({
                                    id: 'advantages_block_header_3'
                                })}
                            </AdvantagesTitle>
                            <AdvantagesText>
                                {formatMessage({
                                    id: 'advantages_block_text_3'
                                })}
                            </AdvantagesText>
                        </AdvantagesItem>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        delay={900}
                    >
                        <AdvantagesItem>
                            <AdvantagesIcon className="icon-advantages-4" />
                            <AdvantagesTitle>
                                {formatMessage({
                                    id: 'advantages_block_header_4'
                                })}
                            </AdvantagesTitle>
                            <AdvantagesText>
                                {formatMessage({
                                    id: 'advantages_block_text_4'
                                })}
                            </AdvantagesText>
                        </AdvantagesItem>
                    </ScrollAnimation>
                </AdvantagesWrapper>
            </Container>
        </Section>
    );
};
