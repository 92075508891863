export const theme = {
    // colors
    color1: '#FFFFFF',
    color2: '#EFEFF1',
    color3: '#999',
    color4: '#9296A2',
    color5: '#333',
    color6: '#333',
    color7: '#3EB557',
    color8: '#676970',
    color9: '#3EB557',
    color10: '#2B9241',
    color11: '#2B9241',
    color12: '#EB4267',
    color13: '#EF6785',
    color14: '#F38DA3',
    color15: '#2B9241',
    color16: '#BF0000',
    color17: '#FFE42C',
    color18: '#22CDEE',
    color19: '#41B9DA',
    color20: '#3599B5',
    color21: '#93A3C1',
    color22: '#f3f3f5',
    color23: '#f5f5f5',
    color24: '#EFEFF1',
    color25: '#999',
    color26: '#676970',
    color27: '#ea265e',
    color28: '#009966',
    color29: '#676970',
    color30: '#FFC014',
    color31: '#BF0000',
    color32: '#000000',

    //other
    buttonBorderRadius: '25px',
    dropdownBorderRadius: '20px',
    containerWidthLg: '1485px',
    containerWidthMd: '1220px',
    containerWidthSm: '980px',
    fontMd: '15px'
};
