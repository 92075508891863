import React from 'react';
import { useDispatch } from 'react-redux';
import { setError } from '../../../services/actions/error';
import { InnerLink } from '../../InnerLink';
import logo from '../../../images/yuppex/logo-white.svg';
import relax from '../../../images/yuppex/relax.svg';
import {
    Container,
    Title,
    TextWrapper,
    Logo,
    Text,
    Relax,
    Content
} from './styled';

export const ServerError = () => {
    const d = useDispatch();
    const linkHandler = () => d(setError(false));
    return (
        <Container>
            <Logo src={logo} alt="Yuppex" />
            <Content>
                <TextWrapper>
                    <Title>Don't worry! Relax!</Title>
                    <Text>
                        It looks like something went wrong. <br />
                        Please try again.
                    </Text>
                    <InnerLink to="/" btn onClick={linkHandler}>
                        GO TO HOMEPAGE
                    </InnerLink>
                </TextWrapper>
                <Relax src={relax} alt="Relax" />
            </Content>
        </Container>
    );
};
