import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import {
    ContactWrapper,
    ContactIcon,
    ContactItem,
    ContactLink,
    ContactTitle,
    SocialIcon,
    SocialLink,
    SocialTitle,
    SocialWrapper,
    SocialLinkWrapper
} from './styled';

export const Contacts = () => {
    const { formatMessage } = useIntl();
    return (
        <Section>
            <Container>
                <Title>{formatMessage({ id: 'contact_title' })}</Title>
                <ContactWrapper>
                    {/*<ContactItem>*/}
                    {/*    <ContactIcon className="icon-telephone" />*/}
                    {/*    <ContactTitle>*/}
                    {/*        {formatMessage({ id: 'contact_tel' })}*/}
                    {/*    </ContactTitle>*/}
                    {/*    <ContactLink*/}
                    {/*        href={`tel:${formatMessage({*/}
                    {/*            id: 'contact_tel_value'*/}
                    {/*        })}`}*/}
                    {/*    >*/}
                    {/*        {formatMessage({ id: 'contact_tel_value' })}*/}
                    {/*    </ContactLink>*/}
                    {/*</ContactItem>*/}
                    {/*<ContactItem>*/}
                    {/*    <ContactIcon className="icon-email" />*/}
                    {/*    <ContactTitle>*/}
                    {/*        {formatMessage({ id: 'contact_mail' })}*/}
                    {/*    </ContactTitle>*/}
                    {/*    <ContactLink*/}
                    {/*        href={`mailto:${formatMessage({*/}
                    {/*            id: 'contact_mail_value'*/}
                    {/*        })}`}*/}
                    {/*    >*/}
                    {/*        {formatMessage({ id: 'contact_mail_value' })}*/}
                    {/*    </ContactLink>*/}
                    {/*</ContactItem>*/}
                    <ContactItem>
                        <ContactIcon className="icon-chat" />
                        <ContactTitle>
                            {formatMessage({ id: 'contact_chat' })}
                        </ContactTitle>
                        <ContactLink
                            href={`${
                                formatMessage({
                                    id: 'contact_chat_value_url_text'
                                }).includes('http')
                                    ? ''
                                    : 'https://'
                            }${formatMessage({
                                id: 'contact_chat_value_url_text'
                            })}`}
                        >
                            {formatMessage({
                                id: 'contact_chat_value_url_text'
                            })}
                        </ContactLink>
                    </ContactItem>
                    <ContactItem>
                        <ContactIcon className="icon-tickets" />
                        <ContactTitle>
                            {formatMessage({ id: 'contact_ticket' })}
                        </ContactTitle>
                        <ContactLink
                            href={`${
                                formatMessage({
                                    id: 'contact_ticket_value_url'
                                }).includes('http')
                                    ? ''
                                    : 'https://'
                            }${formatMessage({
                                id: 'contact_ticket_value_url'
                            })}`}
                        >
                            {formatMessage({
                                id: 'contact_ticket_value_url_text'
                            })}
                        </ContactLink>
                    </ContactItem>
                    <ContactItem>
                        <ContactIcon className="icon-address" />
                        <ContactTitle>
                            {formatMessage({ id: 'contact_company' })}
                        </ContactTitle>
                        <ContactTitle>
                            {formatMessage({ id: 'contact_company_value' })}
                        </ContactTitle>
                    </ContactItem>
                    <ContactItem>
                        <ContactIcon className="icon-license" />
                        <ContactTitle>
                            {formatMessage({ id: 'contact_license' })}
                        </ContactTitle>
                        <ContactTitle>
                            {formatMessage({ id: 'contact_license_value' })}
                        </ContactTitle>
                    </ContactItem>
                </ContactWrapper>
                {/*<SocialWrapper>*/}
                {/*    <SocialTitle>*/}
                {/*        {formatMessage({ id: 'contact_social' })}*/}
                {/*    </SocialTitle>*/}
                {/*    <SocialLinkWrapper>*/}
                {/*        <SocialLink*/}
                {/*            href={formatMessage({ id: 'contact_social_fb' })}*/}
                {/*        >*/}
                {/*            <SocialIcon className="icon-fb" />*/}
                {/*        </SocialLink>*/}
                {/*        <SocialLink*/}
                {/*            href={formatMessage({*/}
                {/*                id: 'contact_social_telegram'*/}
                {/*            })}*/}
                {/*        >*/}
                {/*            <SocialIcon className="icon-telegram" />*/}
                {/*        </SocialLink>*/}
                {/*        <SocialLink*/}
                {/*            href={formatMessage({*/}
                {/*                id: 'contact_social_instagram'*/}
                {/*            })}*/}
                {/*        >*/}
                {/*            <SocialIcon className="icon-instagram" />*/}
                {/*        </SocialLink>*/}
                {/*        <SocialLink*/}
                {/*            href={formatMessage({ id: 'contact_social_skype' })}*/}
                {/*        >*/}
                {/*            <SocialIcon className="icon-skype" />*/}
                {/*        </SocialLink>*/}
                {/*        <SocialLink*/}
                {/*            href={formatMessage({*/}
                {/*                id: 'contact_social_linkedin'*/}
                {/*            })}*/}
                {/*        >*/}
                {/*            <SocialIcon className="icon-linkedin" />*/}
                {/*        </SocialLink>*/}
                {/*        <SocialLink*/}
                {/*            href={formatMessage({*/}
                {/*                id: 'contact_social_twitter'*/}
                {/*            })}*/}
                {/*        >*/}
                {/*            <SocialIcon className="icon-twitter" />*/}
                {/*        </SocialLink>*/}
                {/*        <SocialLink*/}
                {/*            href={formatMessage({*/}
                {/*                id: 'contact_social_whatsapp'*/}
                {/*            })}*/}
                {/*        >*/}
                {/*            <SocialIcon className="icon-whatsapp" />*/}
                {/*        </SocialLink>*/}
                {/*    </SocialLinkWrapper>*/}
                {/*</SocialWrapper>*/}
            </Container>
        </Section>
    );
};
