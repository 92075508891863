export const request_ratings = 'ratings.request';
export const request_ratings_success = 'ratings.request.success';
export const request_users_success = 'users.request.success';
export const request_ratings_failed = 'ratings.request.failed';
export const request_full_user_rating = 'ratings.full.requset';
export const request_full_ratings_success = 'ratings.full.succes';

export const getRatings = (value) => ({
    type: request_ratings,
    value,
});

export const successRatings = data => ({
    type: request_ratings_success,
    payload: data
});

export const successUsers = data => ({
    type: request_users_success,
    payload: data
});

export const failedAssets = () => ({
    type: request_ratings_failed
});


export const getFullUserRating = id => ({
    type: request_full_user_rating,
    id,
});


export const successFullUserRating = data => ({
    type: request_full_ratings_success,
    payload: data
});
