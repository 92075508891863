export const request_ticker = 'ticker.request';
export const request_ticker_success = 'ticker.request.success';
export const request_ticker_failed = 'ticker.request.failed';

export const getTicker = () => ({
    type: request_ticker
});

export const successTicker = data => ({
    type: request_ticker_success,
    payload: data
});

export const failedTicker = () => ({
    type: request_ticker_failed
});
