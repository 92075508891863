import { takeEvery, put } from 'redux-saga/effects';
import { Fetch } from '../../utils/fetch';
import { request_tuition, successTuition } from '../actions/tuition';
import config from '../../config/url';
import { setError } from '../actions/error';

export function* TuitionMiddleware() {
    yield takeEvery(request_tuition, getTuition);
}

function* getTuition() {
    const tuition = yield Fetch(`${config.apiCMS}/v1/video/list`);
    if (tuition) {
        const t = [];
        for (let i = 0; i < tuition.length; i++) {
            const { title, description, code } = tuition[i];
            const data = yield getVideoData(code);
            if (!data) continue;
            t.push({ code, title, description, ...data });
        }
        yield put(successTuition(t));
    } else {
        yield put(setError(true));
    }
}

function* getVideoData(id) {
    const video = yield Fetch(
        `${config.youTubeApi}?id=${id}&key=AIzaSyCJxemw3jbF7anjSzNKu1QVEt1zANkQw7c&part=snippet,statistics,contentDetails`
    );
    if (video) {
        if (!video.items[0]) {
            return null;
        }
        const duration = yield parseDuration(
            video.items[0].contentDetails.duration
        );
        return {
            image: video.items[0].snippet.thumbnails.high.url,
            viewCount: video.items[0].statistics.viewCount,
            duration
        };
    } else {
        yield put(setError(true));
    }
}

const parseDuration = value => {
    if (value.includes('PT0S')) {
        return 'live';
    } else {
        const h = value.match(/[0-9]{1,2}H/)
            ? +value.match(/[0-9]{1,2}H/)[0].replace('H', '') < 10
                ? `0${value.match(/[0-9]{1,2}H/)[0].replace('H', '')}:`
                : `${value.match(/[0-9]{1,2}H/)[0].replace('H', '')}:`
            : '';
        const m = value.match(/[0-9]{1,2}M/)
            ? +value.match(/[0-9]{1,2}M/)[0].replace('M', '') < 10
                ? `0${value.match(/[0-9]{1,2}M/)[0].replace('M', '')}:`
                : `${value.match(/[0-9]{1,2}M/)[0].replace('M', '')}:`
            : '00:';
        const s = value.match(/[0-9]{1,2}S/)
            ? +value.match(/[0-9]{1,2}S/)[0].replace('S', '') < 10
                ? `0${value.match(/[0-9]{1,2}S/)[0].replace('S', '')}`
                : `${value.match(/[0-9]{1,2}S/)[0].replace('S', '')}`
            : '00';
        return `${h}${m}${s}`;
    }
};
