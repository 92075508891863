import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';
import { Section } from '../../Section';
import { Container } from '../../Container';
import {
    Table,
    HeaderCell,
    TableHeader,
    TableBody,
    TableCell,
    TableRow,
    Icon,
    OuterLink,
    Title
} from './styled';
import { InnerLink } from '../../InnerLink';
import config from '../../../config/url';
import { formatNumber } from '../../../utils/formatNumber';

export const Assets = () => {
    const { tickers } = useSelector(state => state.ticker);
    const { error, userInfo } = useSelector(state => state.auth);
    const { formatMessage } = useIntl();

    return (
        <Section>
            <Container>
                <ScrollAnimation animateOnce animateIn="fadeInDown" delay={0}>
                    <Title>
                        {formatMessage({ id: 'markets_table_title' })}
                    </Title>
                </ScrollAnimation>
                <Table>
                    <TableHeader>
                        <HeaderCell>
                            {formatMessage({ id: 'markets_table_token' })}
                        </HeaderCell>
                        <HeaderCell>
                            {formatMessage({ id: 'markets_table_name' })}
                        </HeaderCell>
                        <HeaderCell>
                            {formatMessage({ id: 'markets_table_last_traded' })}
                        </HeaderCell>
                        <HeaderCell>
                            {formatMessage({ id: 'markets_table_change' })}
                        </HeaderCell>
                        <HeaderCell>
                            {formatMessage({ id: 'markets_table_low' })}
                        </HeaderCell>
                        <HeaderCell>
                            {formatMessage({ id: 'markets_table_high' })}
                        </HeaderCell>
                        <HeaderCell>
                            {formatMessage({ id: 'markets_table_volume' })}
                        </HeaderCell>
                    </TableHeader>
                    <TableBody>
                        {tickers &&
                            tickers
                                .slice(0, 7)
                                .map(
                                    ({
                                        isFrozen,
                                        volume24hrUSD,
                                        last,
                                        lowestAsk,
                                        highestBid,
                                        percentChange,
                                        name
                                    }) => {
                                        const [currency] = name.split('/');
                                        if (isFrozen) return null;
                                        return (
                                            <OuterLink
                                                href={`${config.platformLink}/${
                                                    !error &&
                                                    userInfo.hasOwnProperty(
                                                        'id'
                                                    )
                                                        ? 'dashboard'
                                                        : 'market'
                                                }/${name.replace('/', '-')}`}                                                
                                                key={name}
                                            >
                                                <TableRow>
                                                    <TableCell>
                                                        <Icon
                                                            className={`icon-${currency.toLowerCase()}`}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {name}
                                                    </TableCell>
                                                    <TableCell>{`${formatNumber(
                                                        last
                                                    )}`}</TableCell>
                                                    <TableCell
                                                        isHigh={
                                                            Number(
                                                                percentChange
                                                            ) >= 0
                                                        }
                                                        isColored
                                                    >
                                                        {percentChange
                                                            ? `${Number(
                                                                  percentChange
                                                              ).toFixed(2)}%`
                                                            : '0%'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatNumber(
                                                            lowestAsk
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatNumber(
                                                            highestBid
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatNumber(
                                                            Number(
                                                                volume24hrUSD
                                                            )
                                                                .toFixed(8)
                                                                .replace(
                                                                    /\.?0+$/,
                                                                    ''
                                                                )
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </OuterLink>
                                        );
                                    }
                                )}
                    </TableBody>
                </Table>
                <InnerLink btn to="/markets">
                    {formatMessage({ id: 'content_news_button_more' })}
                </InnerLink>
            </Container>
        </Section>
    );
};
