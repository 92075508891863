import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Page from './pages';
import { LocalizationRouter } from './components/LocalizationRouter';
import { getLocalizationLanguages } from './services/actions/localization';
import { getDocumentationList } from './services/actions/documentation';
import { checkAuth } from './services/actions/auth';
import { LocalizationSwitch } from './components/LocalizationSwitch';
import { Layout } from './components/Layout';
import { Loader } from './components/Loader';
import { ServerError } from './components/Errors/ServerError';
import { ScrollToTop } from './components/ScrollToTop';
import { Cookies } from './components/Cookies';

export const Router = () => {
    const d = useDispatch();
    const {
        isLoading,
        defaultLocale,
        languages,
        translations,
        locale
    } = useSelector(state => state.localization);
    const { isLoading: authIsLoading } = useSelector(state => state.auth);
    const error = useSelector(state => state.error);
    const [appError, setAppError] = useState(false);

    useEffect(() => {
        d(getLocalizationLanguages());
        d(getDocumentationList());
        d(checkAuth());
    }, [d]);

    useEffect(() => {
        d(getDocumentationList());
    }, [locale]);

    useEffect(() => {
        if (error) {
            setAppError(error);
        }
    }, [error]);

    if (isLoading || authIsLoading) return <Loader full />;

    return (
        <LocalizationRouter>
            <ScrollToTop />
            <IntlProvider locale={locale.split('-')[0]} messages={translations}>
                <Cookies />
                {appError ? (
                    <ServerError />
                ) : (
                    <Layout>
                        <LocalizationSwitch
                            languages={languages}
                            defaultLocale={defaultLocale}
                            locale={locale}
                        >
                            <Route exact path="/">
                                <Page.Home />
                            </Route>
                            <Route exact path="/markets">
                                <Page.Assets />
                            </Route>
                            <Route exact path="/game-coins">
                                <Page.GameCoins />
                            </Route>
                            <Route exact path="/assets-status">
                                <Page.AssetsStatus />
                            </Route>
                            <Route exact path="/team">
                                <Page.OurTeam />
                            </Route>
                            <Route exact path="/review">
                                <Page.Press />
                            </Route>
                            <Route exact path="/news">
                                <Page.News />
                            </Route>
                            <Route exact path="/contacts">
                                <Page.ContactUs />
                            </Route>
                            <Route exact path="/fees">
                                <Page.Fees />
                            </Route>
                            <Route exact path="/listing">
                                <Page.Listing />
                            </Route>
                            <Route exact path="/banners">
                                <Page.PromoActivities />
                            </Route>
                            <Route exact path="/exchange">
                                <Page.Exchange />
                            </Route>
                            <Route exact path="/faq">
                                <Page.FAQ />
                            </Route>
                            <Route exact path="/academy">
                                <Page.Tuition />
                            </Route>
                            <Route exact path="/rating">
                                <Page.Rating />
                            </Route>
                            <Route exact path="/functionality">
                                <Page.CustomizableInterface />
                            </Route>
                            <Route exact path="/news/:alias">
                                <Page.SingleNews />
                            </Route>
                            <Route exact path="/documentation/:alias">
                                <Page.Documentation />
                            </Route>
                            <Route exact path="*">
                                <Page.NotFound />
                            </Route>
                        </LocalizationSwitch>
                    </Layout>
                )}
            </IntlProvider>
        </LocalizationRouter>
    );
};
