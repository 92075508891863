import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
    VolumeContainer,
    VolumeItem,
    VolumeItemTitle,
    VolumeItemValue,
    VolumeItemWrapper
} from './styled';
import { Container } from '../../Container';
import { getVolume } from '../../../services/actions/volume';
import { formatNumber } from '../../../utils/formatNumber';

export const Volume = () => {
    const d = useDispatch();
    const { volume, isLoading } = useSelector(state => state.volume);
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (!Object.keys(volume).length) d(getVolume());
    }, []);

    return (
        <VolumeContainer>
            <Container>
                {isLoading ? null : (
                    <VolumeItemWrapper>
                        <VolumeItem>
                            <VolumeItemValue>{`$ ${formatNumber(
                                Number(volume.volume24hrUSD)
                                    .toFixed(8)
                                    .replace(/\.?0+$/, '')
                            )}`}</VolumeItemValue>
                            <VolumeItemTitle>
                                {formatMessage({ id: 'crypto_info_block1' })}
                            </VolumeItemTitle>
                        </VolumeItem>
                        <VolumeItem>
                            <VolumeItemValue>{`BTC ${formatNumber(
                                Number(volume.volume24hrBTC)
                                    .toFixed(8)
                                    .replace(/\.?0+$/, '')
                            )}`}</VolumeItemValue>
                            <VolumeItemTitle>
                                {formatMessage({ id: 'crypto_info_block1' })}
                            </VolumeItemTitle>
                        </VolumeItem>
                        <VolumeItem>
                            <VolumeItemValue>{`${Number(
                                volume.volume48hrBTC
                            ).toFixed(2)}%`}</VolumeItemValue>
                            <VolumeItemTitle>
                                {formatMessage({ id: 'crypto_info_block2' })}
                            </VolumeItemTitle>
                        </VolumeItem>
                    </VolumeItemWrapper>
                )}
            </Container>
        </VolumeContainer>
    );
};
