import {
    request_assets,
    request_assets_success,
    request_assets_failed
} from '../actions/assets';

const initialState = {
    isLoading: true,
    assets: []
};

export const assetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_assets:
            return {
                ...state,
                isLoading: true
            };
        case request_assets_success:
            return {
                ...state,
                assets: action.payload,
                isLoading: false
            };
        case request_assets_failed:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
