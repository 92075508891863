export const formatNumber = number => {
    if (!number) return 0;
    const _n = String(number);
    const _nSplit = _n.split('.');
    const integerPart = _nSplit[0];
    const fractionalPart = _nSplit[1] || '00';
    if (integerPart.length > 4) {
        let ip = integerPart.split('');
        for (let i = integerPart.length - 3; i > 0; i -= 3) {
            ip.splice(i, 0, ',');
        }
        return ip.join('') + '.' + fractionalPart;
    }
    return _n;
};
