import React, {useState} from 'react';
import moment from 'moment';
import { TableRow, TableCell, User, UserData, ChartWrapper, ToggleArrow, RatingCell, DefaultAvatar } from '../styled';
import Avatar from './avatar';
import Chart from './chart';
import FullRating from '../FullRating';

export default function Row({ data, isOpen, active }) {
    const [id, setId] = useState(active === data.wallet_id ? data.wallet_id : '');
    const handleClick = () => id ? setId('') : setId(data.wallet_id);
    return (
        <>
            <TableRow onClick={handleClick}>
                <TableCell first>
                    <RatingCell>
                        <ToggleArrow className={isOpen ? 'icon-arrow-up' : 'icon-arrow-down'} />{data.rating.toFixed(2)}
                    </RatingCell>                
                </TableCell>
                <TableCell>
                    <User>
                        {data.profile && data.profile.avatar ? <Avatar src={data.profile.avatar} /> : <DefaultAvatar className="icon-default-avatar" />}
                        <UserData>{data.profile && data.profile.nickname}</UserData>
                    </User>
                </TableCell>
                <TableCell>{data.wallet_id}</TableCell>
                <TableCell center><ChartWrapper><Chart name={data.profile && data.profile.nickname} isNegative={data.rating < 0} data={data.profit_loss_trend} /></ChartWrapper></TableCell>
                <TableCell right>{data.balance.toFixed(2)}</TableCell>
                <TableCell right>{data.equity.toFixed(2)}</TableCell>
                <TableCell right>{data.investors}</TableCell>
                <TableCell right>{data.profit_loss_total.toFixed(2)}</TableCell>
                <TableCell right last>{moment.unix(data.created_at).format('MM.DD.YYYY')}</TableCell>
            </TableRow>
            {id && <FullRating key={`full-rating-${id}`} id={id} userId={data.user_id} nickname={data.profile && data.profile.nickname} isNegative={data.rating < 0} chart={data.profit_loss_trend} rating={data.rating} />}
        </>
    );
}
