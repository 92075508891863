import styled from 'styled-components';

export const Content = styled.div`
    margin-top: 40px;
    @media (max-width: 1024px) {
        text-align: justify;
        text-indent: 45px;
    }
`;

export const P = styled.p`
    font-size: 21px;
    line-height: 36px;
    margin: 0;
    text-indent: 0px;
    text-align: justify;
`;

export const Span = styled.span`
    color: ${({ theme }) => theme.color11};
`;

export const Ul = styled.ul`
    margin-bottom: 40px;
`;

export const Li = styled.li`
    font-size: 21px;
    line-height: 36px;
`;

export const TitleText = styled.h3`
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: ${({ theme }) => theme.color11};
    text-align: center;
`;

export const SelectedTitle = styled.span`
    color: ${({ theme }) => theme.color11};
`;

export const TitleDescription = styled.p`
    text-align: center;
    font-size: 21px;
    line-height: 36px;
    margin-bottom: 0;
`;
