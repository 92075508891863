import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const SimpleLink = styled.a`
  text-decoration:none;
`;
export const Link = styled(NavLink)`
    text-decoration: none;
    &.selected {
        color: ${({ theme }) => theme.color11};
    }
    ${({ btn }) =>
        btn &&
        css`
            display: block;
            padding: 15px 25px;
            background: ${({ theme, second }) =>
                second ? theme.color8 : theme.color11};
            text-decoration: none; 
            color: ${({ theme }) => theme.color1};
            font-size: 18px;
            transition: background 150ms;
            white-space: nowrap;
            margin: 0 auto;
            width: fit-content;
            text-align: center;

            &:hover {
                background: ${({ theme, second }) =>
                    second ? theme.color7 : theme.color7};
            }
            &:active {
                background: ${({ theme, second }) =>
                    second ? theme.color6 : theme.color11};
            }

            &:disabled {
                background: ${({ theme }) => theme.color3};
            }

            ${({ fluid }) =>
                fluid &&
                css`
                    width: 100%;
                    box-sizing: border-box;
                    text-align: center;
                `}

            ${({ width }) =>
                width &&
                css`
                    min-width: ${width}px;
                    text-align: center;
                    box-sizing: border-box;
                `}
        `}
`;
