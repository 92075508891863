import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from '../Container';
import { Section } from '../Section';
import { Title } from '../Title';
import {
    Text,
    PromoContainer,
    Banner,
    BannerImage,
    BannerTitle,
} from './styled';
import img800x100 from '../../images/yuppex/800х100px.png';
import img120x240 from '../../images/yuppex/120х240px.png';
import img120x600 from '../../images/yuppex/120х600px.png';
import img300x250 from '../../images/yuppex/300х250px.png';
import img300x600 from '../../images/yuppex/300х600px.png';
import img728x90 from '../../images/yuppex/728х90px.png';

export const PromoActivities = () => {
    const { formatMessage } = useIntl();
    return (
        <Section>
            <Container>
                <Title>{formatMessage({ id: 'promo_header' })}</Title>
                <Text>{formatMessage({ id: 'promo_section_text' })}</Text>
                <Text>{formatMessage({ id: 'promo_call_action' })}</Text>
                <PromoContainer>
                    <Banner>
                        <BannerTitle>300x600px</BannerTitle>
                        <BannerImage src={img300x600} alt="Banner 300x600px" />
                    </Banner>
                    <Banner>
                        <BannerTitle>800x100px</BannerTitle>
                        <BannerImage src={img800x100} alt="Banner 800x100px" />
                    </Banner>
                    <Banner>
                        <BannerTitle>120x600px</BannerTitle>
                        <BannerImage src={img120x600} alt="Banner 120x600px" />
                    </Banner>
                    <Banner>
                        <BannerTitle>728x90px</BannerTitle>
                        <BannerImage src={img728x90} alt="Banner 728x90px" />
                    </Banner>
                    <Banner>
                        <BannerTitle>300x250px</BannerTitle>
                        <BannerImage src={img300x250} alt="Banner 300x250px" />
                    </Banner>
                    <Banner>
                        <BannerTitle>120x240px</BannerTitle>
                        <BannerImage src={img120x240} alt="Banner 120x240px" />
                    </Banner>
                </PromoContainer>
            </Container>
        </Section>
    );
};
