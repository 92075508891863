import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 720px;
`;
export const TextWrapper = styled.div`
    a {
        margin: 0;
        @media (max-width: 720px) {
            margin: 0 auto;
        }
    }

    @media (max-width: 660px) {
        padding: 0 25px;
        box-sizing: border-box;
    }
`;
export const Title = styled.h1`
    font-size: 40px;
    line-height: 50px;
    color: ${({ theme }) => theme.color6}; 
    @media (max-width: 720px) {
        text-align: center;
    }
`;
export const Text = styled.p`
    font-size: 24px;
    line-height: 36px;
    margin: 45px 0;

    @media (max-width: 720px) {
        text-align: center;
    }
`;
export const Logo = styled.img`
    display: block;
    width: 375px;
    margin-bottom: 100px;

    @media (max-width: 470px) {
        width: 80%;
    }
`;
export const Relax = styled.img`
    @media (max-width: 1150px) {
        display: none;
    }
`;
export const Content = styled.div`
    display: flex;
`;
