import styled from 'styled-components';

export const AdvantagesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    justify-content: space-between;
    margin-top: 68px;

    @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

export const AdvantagesItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const AdvantagesIcon = styled.i`
    font-size: 60px;
    color: ${({ theme }) => theme.color11};
    margin-bottom: 30px;

    @media (max-width: 1400px) {
        font-size: 48px;
    }
`;

export const AdvantagesTitle = styled.h3`
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: ${({ theme }) => theme.color6};

    @media (max-width: 1400px) {
        font-size: 20px;
        line-height: normal;
    }
`;

export const AdvantagesText = styled.p`
    line-height: 28px;
    text-align: center;

    @media (max-width: 1400px) {
        font-size: 18px;
        line-height: normal;
    }
`;
