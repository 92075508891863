import { takeEvery, put, call } from 'redux-saga/effects';
import { debounce } from 'lodash';
import {
    localization_languages_request,
    localization_translation_request,
    localization_set_locale,
    getLocalizationTranslation,
    getLocalizationLanguagesSuccess,
    getLocalizationTranslationSuccess,
    setDefaultLocale,
    setLocale
} from '../actions/localization';
import { Fetch } from '../../utils/fetch';
import config from '../../config/url';
import { setError } from '../actions/error';
import { getCookie } from '../../utils/cookies';

export function* LocalizationMiddleware() {
    yield takeEvery(localization_languages_request, getLanguages);
    yield takeEvery(
        [localization_translation_request, localization_set_locale],
        getTranslations
    );
}

function* getLanguages() {
    const languages = yield call(Fetch, `${config.apiCMS}/v1/language/list`);

    if (languages) {
        const cookieLang = getCookie('allLocale');
        const langList = languages.map(item => item.code);
        const defaultLocale = yield getDefaultLanguage(languages);
        const urlLocale = yield getLocaleFromUrl(languages);
        if (urlLocale || cookieLang) {
            yield put(setLocale((langList.includes(cookieLang) ? cookieLang : urlLocale) || defaultLocale));
            yield put(getLocalizationTranslation(langList.includes(cookieLang) ? cookieLang : urlLocale));
        } else {
            yield put(setLocale(defaultLocale));
            yield put(getLocalizationTranslation(langList.includes(cookieLang) ? cookieLang : defaultLocale));
        }

        yield put(setDefaultLocale(defaultLocale));
        yield put(getLocalizationLanguagesSuccess(languages));
    } else {
        yield put(setError(true));
    }
}

function getLocaleFromUrl(languages) {
    const { href } = window.location;
    const regx = href.match(/\/[a-z]{2}\//);
    if (regx) {
        const [localePath] = regx;
        const [, locale] = localePath.split('/');
        const [lang] = languages.filter(l => l.url === locale);
        if (lang) return lang.code;
    }
    return null;
}

function getDefaultLanguage(languages) {
    const def = languages.filter(l => l.default)[0];
    if (def) return def.code;
    return languages[0].code;
}

function* getTranslations({ payload }) {
    const translation = yield call(
        Fetch,
        `${config.apiCMS}/v1/translation/list`,
        { 'accept-language': payload }
    );
    if (translation) {
        yield put(getLocalizationTranslationSuccess(translation));
    } else {
        yield put(setError(true));
    }
}
