import styled, { css, keyframes } from 'styled-components';

const blink = keyframes`
  0%, 100% {
  opacity: 1;
  transform: scale(1);
  }
  50% {
  opacity: 0;
  transform: scale(0.8);
  }
`;
export const LoaderContainer = styled.div`
    ${({ full }) =>
        full
            ? css`
                  position: fixed;
                  z-index: 5;
                  top: 50%;
                  left: 50%;
                  margin-right: -50%;
                  transform: translate(-50%, -50%);
              `
            : css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 20px;
              `}
`;

export const LoaderWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1f);
    grid-gap: 10px;
    width: 150px;
`;

export const LoaderBlock = styled.div`
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.color11};
    animation: ${blink} infinite 500ms;

    &:nth-child(1) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
    &:nth-child(2) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
    &:nth-child(3) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
    &:nth-child(4) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
    &:nth-child(5) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
    &:nth-child(6) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
    &:nth-child(7) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
    &:nth-child(8) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
    &:nth-child(9) {
        animation-delay: ${`${Math.floor(Math.random() * 500)}ms`};
    }
`;
