import {
    request_user_info,
    failed_user_info,
    success_user_info
} from '../actions/auth';

const initialState = {
    isLoading: false,
    error: false,
    userInfo: {}
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_user_info:
            return {
                ...state,
                isLoading: true
            };
        case success_user_info:
            return {
                ...state,
                userInfo: action.payload,
                isLoading: false,
                error: false
            };
        case failed_user_info:
            return {
                ...state,
                error: true,
                isLoading: false
            };
        default:
            return state;
    }
};
