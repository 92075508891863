import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { FirstScreen } from '../components/HomePageComponents/FirstScreen';
import { Volume } from '../components/HomePageComponents/Volume';
import { Assets } from '../components/HomePageComponents/Assets';
import { Choose } from '../components/HomePageComponents/Choose';
import { Advantages } from '../components/HomePageComponents/Advantages';
import { Exchange } from '../components/HomePageComponents/Exchenge';
import { ExchangeAssets } from '../components/HomePageComponents/ExchangeAssets';
import { SocialTraders } from '../components/HomePageComponents/SocialTraders';

export default () => {
    const { formatMessage } = useIntl();
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content={formatMessage({ id: 'homepage_meta_description' })}
                />
                <meta
                    name="keywords"
                    content={formatMessage({ id: 'homepage_meta_keywords' })}
                />
                <title>{formatMessage({ id: 'homepage_meta_title' })}</title>
            </Helmet>
            <FirstScreen />
            <Volume />
            <Assets />
            <ExchangeAssets />
            <Choose />
            <SocialTraders />

            <Advantages />
            <Exchange />
        </React.Fragment>
    );
};
