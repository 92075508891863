import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { localizationReducer } from './reducers/localization';
import { mobileMenuReducer } from './reducers/mobileMenu';
import { ticketReducer } from './reducers/ticker';
import { volumeReducer } from './reducers/volume';
import { newsReducer } from './reducers/news';
import { faqReducer } from './reducers/faq';
import { pressReducer } from './reducers/press';
import { documentationReducer } from './reducers/documentation';
import { authReducer } from './reducers/auth';
import { errorReducer } from './reducers/error';
import { assetsReducer } from './reducers/assets';
import { ratingsReducer } from './reducers/ratings';
import { tuitionReducer } from './reducers/tuition';
import { tickerPrivateReducer } from './reducers/gameCoins';

import { LocalizationMiddleware } from './middleware/localization';
import { TickerMiddleware } from './middleware/ticker';
import { VolumeMiddleware } from './middleware/volume';
import { NewsMiddleware } from './middleware/news';
import { FaqMiddleware } from './middleware/faq';
import { PressMiddleware } from './middleware/press';
import { DocumentationMiddleware } from './middleware/documentation';
import { AuthMiddleware } from './middleware/auth';
import { AssetsMiddleware } from './middleware/assets';
import { RatingsMiddleware } from './middleware/ratings';
import { TuitionMiddleware } from './middleware/tuition';
import { TickerPrivateMiddleware } from './middleware/gameCoins';

const reducers = combineReducers({
    localization: localizationReducer,
    auth: authReducer,
    mobileMenu: mobileMenuReducer,
    ticker: ticketReducer,
    volume: volumeReducer,
    news: newsReducer,
    faq: faqReducer,
    press: pressReducer,
    documentation: documentationReducer,
    error: errorReducer,
    assets: assetsReducer,
    tuition: tuitionReducer,
    tickerPrivate: tickerPrivateReducer,
    ratings: ratingsReducer
});

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

export const store = createStore(reducers, enhancer);

sagaMiddleware.run(LocalizationMiddleware);
sagaMiddleware.run(AuthMiddleware);
sagaMiddleware.run(TickerMiddleware);
sagaMiddleware.run(VolumeMiddleware);
sagaMiddleware.run(NewsMiddleware);
sagaMiddleware.run(FaqMiddleware);
sagaMiddleware.run(PressMiddleware);
sagaMiddleware.run(DocumentationMiddleware);
sagaMiddleware.run(AssetsMiddleware);
sagaMiddleware.run(RatingsMiddleware);
sagaMiddleware.run(TuitionMiddleware);
sagaMiddleware.run(TickerPrivateMiddleware);
