export const request_news = 'news.request';
export const request_news_success = 'news.request.success';
export const request_news_failed = 'news.request.failed';

export const request_single_news = 'single-news.request';
export const request_single_news_success = 'single-news.request.success';

export const getNews = pages => ({
    type: request_news,
    payload: pages
});

export const successNews = news => ({
    type: request_news_success,
    payload: news
});

export const getSingleNews = alias => ({
    type: request_single_news,
    payload: alias
});

export const successSingleNews = (alias, news) => ({
    type: request_single_news_success,
    payload: { alias, news }
});
