export const request_ticker_private = 'ticker_private.request';
export const request_ticker_private_success = 'ticker_private.request.success';
export const request_ticker_private_failed = 'ticker_private.request.failed';

export const getTickerPrivate = () => ({
    type: request_ticker_private
});

export const successTickerPrivate = data => ({
    type: request_ticker_private_success,
    payload: data
});

export const failedTickerPrivate = () => ({
    type: request_ticker_private_failed
});
