import styled from 'styled-components';

export const Text = styled.p`
    font-size: 21px;
    line-height: 36px;
    text-indent: 0px;
    text-align: justify;
`;

export const PromoContainer = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 3.33fr 3.33fr 1fr;
    grid-column-gap: 3%;
    margin: 50px 0;
    overflow: hidden;
    @media (max-width: 768px) {
        overflow: scroll;
    }
`;

export const Banner = styled.div`
    &:nth-child(1) {
        grid-column: 1/2;
        grid-row: 1/4;
        img {
            width: auto;
        }
    }
    &:nth-child(2) {
        grid-column: 2/4;
        grid-row: 1/2;
        img {
            width: 100%;
            height: auto;
            max-width: fit-content;
        }
    }
    &:nth-child(3) {
        grid-column: 4/5;
        grid-row: 1/4;
        align-self: self-end;
        img {
            width: 100%;
            height: auto;
            max-width: fit-content;
        }
    }
    &:nth-child(4) {
        grid-column: 2/4;
        grid-row: 2/3;
        img {
            width: 100%;
            height: auto;
            max-width: fit-content;
        }
    }
    &:nth-child(5) {
        img {
            width: 100%;
            height: auto;
            max-width: fit-content;
        }
    }
    &:nth-child(6) {
        img {
            width: auto;
        }
    }
`;

export const BannerTitle = styled.div`
    font-size: 24px;
    line-height: 36px;
`;

export const BannerImage = styled.img`
    // width: 100%;
`;
