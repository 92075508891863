import {
    request_volume,
    request_volume_failed,
    request_volume_success
} from '../actions/volume';

const initialState = {
    isLoading: true,
    volume: {}
};

export const volumeReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_volume:
            return {
                ...state,
                isLoading: true
            };
        case request_volume_success:
            return {
                ...state,
                volume: action.payload,
                isLoading: false
            };
        case request_volume_failed:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};
