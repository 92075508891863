import styled from 'styled-components';

export const VolumeContainer = styled.section`    
    display: flex;
    align-items: center;    

    @media (max-width: 950px) {
        height: inherit;
    }
`;

export const VolumeItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 950px) {
        flex-direction: column;
    }
`;

export const VolumeItem = styled.div`
    flex: 1;
    padding: 28px 0 22px;
    &:nth-child(1) {
      background: ${({ theme }) => theme.color11};
    }
    &:nth-child(2) {
      background: ${({ theme }) => theme.color31};
    }
    &:nth-child(3) {
      background: ${({ theme }) => theme.color30};
    }
    @media (max-width: 950px) {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const VolumeItemValue = styled.div`
    text-align: center;
    font-size: 36px;
    line-height: normal;
    color: ${({ theme }) => theme.color1};
    margin-bottom: 5px;
`;

export const VolumeItemTitle = styled.div`
    text-align: center;
    font-size: 18px;
    color: ${({ theme }) => theme.color1};
`;
