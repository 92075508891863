import { toggle_mobile_menu } from '../actions/mobileMenu';

const initialState = {
    open: false
};

export const mobileMenuReducer = (state = initialState, action) => {
    if (action.type === toggle_mobile_menu) {
        return {
            ...state,
            open: action.payload
        };
    } else {
        return state;
    }
};
