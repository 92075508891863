import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useLanguageSwitcher } from './useLanguageSwitcher';
import {
    LanguageList,
    Language,
    LanguageSelector,
    FlagContainer,
} from './styled';
import ReactCountryFlag from 'react-country-flag';

export const LanguageSwitcher = () => {
    const {
        languages,
        clickHandler,
        getToPath,
        isOpen,
        closeHandler,
        openHandler,
        locale,
    } = useLanguageSwitcher();
    const countryCode = locale.split('-')[1];
    return (
        <LanguageSelector
            onMouseEnter={openHandler}
            onMouseLeave={closeHandler}
        >
            <FlagContainer open={isOpen}>
                <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                    }}
                    title={countryCode}
                />
            </FlagContainer>
            <CSSTransition
                in={isOpen}
                timeout={150}
                classNames="language-switcher"
            >
                <LanguageList>
                    {languages.map(({ code, url, name }) => (
                        <Language
                            to={getToPath(code, url)}
                            key={code}
                            onClick={clickHandler(code)}
                        >
                            <ReactCountryFlag
                                countryCode={code.split('-')[1]}
                                svg
                                style={{
                                    width: '32px',
                                    height: '21px',
                                    marginRight: '10px',
                                }}
                            />
                            <span>{name}</span>
                        </Language>
                    ))}
                </LanguageList>
            </CSSTransition>
        </LanguageSelector>
    );
};
