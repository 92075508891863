import styled, { css } from 'styled-components';

export const FilterWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.color3};

    @media (max-width: 1200px) {
        min-width: 980px;
    }
`;

export const Filter = styled.div`
    flex: 1;
    text-align: center;
    padding: 20px 0;
    cursor: pointer;
    font-size: 24px; 
    border-bottom: 2px solid
        ${({ theme, active }) => (active ? theme.color11 : 'transparent')};
    transition: border-bottom-color 150ms;
    color: ${({ theme, active }) => (active ? theme.color11 : theme.color4)};

    &:hover {
        border-bottom-color: ${({ theme }) => theme.color11};
    }
`;

export const FilterIcon = styled.i`
    flex: 0.5;
    text-align: center;
    padding: 20px 0;
    cursor: pointer;
    font-size: 24px;
    border-bottom: 2px solid
        ${({ theme, active }) => (active ? theme.color11 : 'transparent')};
    transition: border-bottom-color 150ms;
    color: ${({ theme, active }) => (active ? theme.color11 : theme.color4)};

    &:hover {
        border-bottom-color: ${({ theme }) => theme.color11};
    }
`;

export const FilterInputWrapper = styled.div`
    position: relative;
    width: 344px;
    display: flex;
    justify-content: flex-end;
    height: 35px;
`;
export const FilterInputIcon = styled.i`
    position: absolute;
    font-size: 14px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.color3};
`;

export const FilterInput = styled.input`
    flex: 1;
    margin-left: 50px;
    border: 1px solid ${({ theme }) => theme.color3};
    padding: 0 10px;
    box-sizing: border-box;
    font-weight: 300;
    &::placeholder {
        color: ${({ theme }) => theme.color4};
        opacity: 1;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.color4};
    }
`;

export const Table = styled.div`
    margin-top: 45px;
`;

export const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr 1fr;
    //border-bottom: 1px solid ${({ theme }) => theme.color3};
    padding-right: 10px;
    box-sizing: border-box;

    @media (max-width: 1200px) {
        min-width: 980px;
    }
`;

export const TableHeaderCell = styled.div`
    padding-bottom: 16px;
    color: ${({ theme }) => theme.color4};
    &:last-child {
        text-align: right;
    }
`;

export const AssetsContent = styled.div`
    @media (max-width: 1200px) {
        overflow-y: auto;
    }
`;

export const TableRow = styled.a`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr 1fr;
    border: 1px solid transparent;
    border-top-color: ${({ theme }) => theme.color3};
    padding-right: 10px;
    box-sizing: border-box;
    text-decoration: none;
    //transition: border-radius 150ms;

    &:hover {
        background: ${({ theme }) => theme.color2};
        border-color: ${({ theme }) => theme.color3};

        & + a {
            border-top-color: transparent;
        }
    }

    &:last-child {
        border-bottom-color: ${({ theme }) => theme.color3};
    }

    @media (max-width: 1200px) {
        min-width: 980px;
    }
`;

export const TableRowCell = styled(TableHeaderCell)`
    padding-top: 16px;
    color: ${({ theme }) => theme.color5};
    &:first-child {
        padding-left: 10px;
        box-sizing: border-box;
    }

    ${({ isColored, isHigh, theme }) =>
        isColored &&
        css`
            color: ${isHigh ? theme.color15 : theme.color16};
        `}
`;

export const TableRowCellIcon = styled.i`
    margin-right: 14px;
    font-size: 18px;
    color: ${({ theme, active }) => (active ? theme.color11 : theme.color4)};
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color11};
    }
`;

export const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
`;

export const ErrorText = styled.h3`
    text-align: center; 
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: ${({ theme }) => theme.color6};
`;
