import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container } from '../../Container';
import {
    FirstScreenWrapper,
    TitleH1,
    TitleH2,
    Logo,
    Description,
    ButtonOffset,
    ButtonWrapper,
    TitleSpan,
} from './styled';
import { Button } from '../../Button';
import { CryptoCarousel } from '../Carousel/CruptoCarousel';
import bg from '../../../images/yuppex/serf-group-gr-shark-678x488-opt.svg';
import logo from '../../../images/yuppex/logo.svg';
import config from '../../../config/url';

export const FirstScreen = () => {
    const { formatMessage } = useIntl();
    const { error, userInfo } = useSelector((state) => state.auth);
    return (
        <FirstScreenWrapper background={bg}>
            <Container flex>
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    delay={300}
                    offset={-600}
                >
                    <TitleH1>
                        {formatMessage({
                            id: 'main_header_content_label_title1',
                        })}
                    </TitleH1>
                </ScrollAnimation>
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    delay={600}
                    offset={-600}
                >
                    <Logo src={logo} alt="Yuppex" />
                </ScrollAnimation>
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    delay={900}
                    v
                >
                    <TitleSpan>
                        {formatMessage({
                            id: 'main_header_content_label_title2',
                        })}
                    </TitleSpan>
                </ScrollAnimation>
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    delay={1200}
                    offset={-600}
                >
                    <Description>
                        {formatMessage({
                            id: 'main_header_content_label_description1',
                        })}{' '}
                        &nbsp;
                        {formatMessage({
                            id: 'main_header_content_label_description2',
                        })}
                    </Description>
                </ScrollAnimation>
                {(error || !Object.keys(userInfo).length) && (
                    <ButtonWrapper>
                        <ButtonOffset>
                            <ScrollAnimation
                                animateOnce
                                animateIn="fadeInLeft"
                                delay={2000}
                                offset={-600}
                            >
                                <Button
                                    width={217}
                                    second
                                    href={formatMessage({
                                        id: 'label_home_page_main_picture_part_button_login_url',
                                    })}
                                >
                                    {formatMessage({
                                        id: 'label_home_page_main_picture_part_button_login',
                                    })}
                                </Button>
                            </ScrollAnimation>
                        </ButtonOffset>
                        <ButtonOffset>
                            <ScrollAnimation
                                animateOnce
                                animateIn="fadeInRight"
                                delay={2000}
                                offset={-600}
                            >
                                <Button
                                    width={217}
                                    href={formatMessage({
                                        id: 'label_home_page_main_picture_part_button_open_account_url',
                                    })}
                                >
                                    {formatMessage({
                                        id: 'label_home_page_main_picture_part_button_open_account',
                                    })}
                                </Button>
                            </ScrollAnimation>
                        </ButtonOffset>
                    </ButtonWrapper>
                )}
            </Container>
            <CryptoCarousel />
        </FirstScreenWrapper>
    );
};
