import styled from 'styled-components';

export const Text = styled.p`
    font-size: 24px;
    line-height: 36px;
    margin-top: 40px;
    text-align: center;
`;

export const SelectText = styled.span`
    color: ${({ theme }) => theme.color11};
`;

export const Icon = styled.i`
    display: block;
    width: 100%;
    margin-top: 60px;
    text-align: center;
    font-size: 300px;
    color: ${({ theme }) => theme.color8};
`;
