import React from 'react';
import { useIntl } from 'react-intl';
import {
    Template,
    Navigation,
    ButtonOffset,
    TemplateBlock,
    Shadow,
    OuterLink,
    AvatarImage,
    AvatarWrapper,
    DefaultAvatar,
} from './styled';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem } from '../Dropdown';
import { InnerLink } from '../../InnerLink';
import { Logo } from '../../Logo';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { Button } from '../../Button';
import { Hamburger } from '../Hamburger';
import config from '../../../config/url';

import logoImg from '../../../images/yuppex/logo-white.svg';

export const Header = () => {
    const { formatMessage } = useIntl();
    const { error, userInfo } = useSelector((state) => state.auth);
    return (
        <Template>
            <TemplateBlock>
                <InnerLink to="/">
                    <Logo src={logoImg} alt="Yuppex" />
                </InnerLink>
                <Navigation>
                    <Dropdown
                        title={formatMessage({
                            id: 'label_menu_header_about_us',
                        })}
                    >
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/team"
                                title={formatMessage({
                                    id: 'label_menu_header_home_team',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/review"
                                title={formatMessage({
                                    id: 'label_menu_header_home_press',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/news"
                                title={formatMessage({
                                    id: 'label_menu_header_home_news',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/contacts"
                                title={formatMessage({
                                    id: 'label_menu_header_home_contact',
                                })}
                            />
                        </DropdownItem>
                    </Dropdown>
                    <Dropdown
                        title={formatMessage({
                            id: 'label_menu_header_trading',
                        })}
                    >
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/markets"
                                title={formatMessage({
                                    id: 'label_menu_header_home_markets',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/game-coins"
                                title={formatMessage({
                                    id: 'label_menu_header_home_game_coins',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/assets-status"
                                title={formatMessage({
                                    id: 'label_menu_header_home_assets_status',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/fees"
                                title={formatMessage({
                                    id: 'label_menu_header_home_fees',
                                })}
                            />
                        </DropdownItem>
                    </Dropdown>
                    <Dropdown
                        title={formatMessage({
                            id: 'label_menu_header_features',
                        })}
                    >
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/exchange"
                                title={formatMessage({
                                    id: 'label_menu_header_home_exchange',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/functionality"
                                title={formatMessage({
                                    id: 'label_menu_header_home_customizable_interface',
                                })}
                            />
                        </DropdownItem>
                    </Dropdown>
                    <Dropdown
                        title={formatMessage({
                            id: 'label_menu_header_bonus',
                        })}
                    >
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/news/investment-opportunities"
                                title={formatMessage({
                                    id: 'label_menu_header_home_invest_opportunity',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/news/local-master-franchise"
                                title={formatMessage({
                                    id: 'label_menu_header_home_master_franchise',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/news/yuppie-coins"
                                title={formatMessage({
                                    id: 'label_menu_header_home_company_coins',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/banners"
                                title={formatMessage({
                                    id: 'label_menu_header_home_promo_activities',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/listing"
                                title={formatMessage({
                                    id: 'label_menu_header_home_listing',
                                })}
                            />
                        </DropdownItem>
                    </Dropdown>

                    <InnerLink
                        activeClassName="selected"
                        to="/rating"
                        title={
                            <Dropdown
                                title={formatMessage({
                                    id: 'label_menu_header_p2p_rating',
                                })}
                            />
                        }
                    />
                    <InnerLink
                        simpleLink
                        activeClassName="selected"
                        to={`${formatMessage({
                            id: 'label_menu_header_launchpad_url',
                        })}`}
                        title={
                            <Dropdown
                                withLabel={formatMessage({
                                    id: 'label_menu_header_top_hot_label',
                                })}
                                title={formatMessage({
                                    id: 'label_menu_header_launchpad',
                                })}
                            />
                        }
                    />
                    {/*<InnerLink*/}
                    {/*    simpleLink*/}
                    {/*    activeClassName="selected"*/}
                    {/*    to={config.launcher}*/}
                    {/*    title={<Dropdown*/}
                    {/*        withLabel={formatMessage({*/}
                    {/*            id: 'label_menu_header_new_label'*/}
                    {/*        })}*/}
                    {/*        title={formatMessage({*/}
                    {/*            id: 'label_menu_header_launcher'*/}
                    {/*        })}*/}
                    {/*    />}*/}
                    {/*/>*/}
                    <Dropdown
                        title={formatMessage({
                            id: 'label_menu_header_support',
                        })}
                    >
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/faq"
                                title={formatMessage({
                                    id: 'label_menu_header_home_faq',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <InnerLink
                                activeClassName="selected"
                                to="/academy"
                                title={formatMessage({
                                    id: 'label_menu_header_home_tuition',
                                })}
                            />
                        </DropdownItem>
                        <DropdownItem>
                            <OuterLink
                                href={formatMessage({
                                    id: 'label_menu_header_home_ticket_url',
                                })}
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_ticket',
                                })}
                            </OuterLink>
                        </DropdownItem>
                    </Dropdown>
                </Navigation>
            </TemplateBlock>
            <TemplateBlock>
                <LanguageSwitcher />
                <Hamburger />
                <ButtonOffset>
                    <OuterLink
                        href={formatMessage({
                            id: 'label_menu_header_side_button_supportchat_url',
                        })}
                    >
                        {formatMessage({
                            id: 'label_menu_header_side_button_supportchat',
                        })}
                    </OuterLink>
                </ButtonOffset>
                {!error && userInfo.profile ? (
                    <OuterLink
                        href={formatMessage({
                            id: 'label_menu_header_side_avatar_url',
                        })}
                    >
                        <AvatarWrapper>
                            {userInfo.profile.avatar ? (
                                <AvatarImage
                                    src={userInfo.profile.avatar}
                                    alt={`Yuppex | ${userInfo.profile.first_name} ${userInfo.profile.last_name} ${userInfo.profile.nickname}`}
                                />
                            ) : (
                                <DefaultAvatar className="icon-default-avatar" />
                            )}
                        </AvatarWrapper>
                    </OuterLink>
                ) : (
                    <React.Fragment>
                        <ButtonOffset>
                            <OuterLink
                                third
                                href={formatMessage({
                                    id: 'label_menu_header_side_button_registration_url',
                                })}
                            >
                                {formatMessage({
                                    id: 'label_menu_header_side_button_registration',
                                })}
                            </OuterLink>
                        </ButtonOffset>
                        <ButtonOffset>
                            <Button
                                href={formatMessage({
                                    id: 'label_menu_header_side_button_login_url',
                                })}
                            >
                                {formatMessage({
                                    id: 'label_menu_header_side_button_login',
                                })}
                            </Button>
                        </ButtonOffset>
                    </React.Fragment>
                )}
            </TemplateBlock>
            <Shadow />
        </Template>
    );
};
