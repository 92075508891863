import React, { useState } from 'react';
import { Expand, ExpandContainer, ExpandTitle, LinkTitle } from './styled';
import { DropdownLabel } from '../Dropdown/styled';

export const ExpandMenuItem = ({ title, children, link, withLabel }) => {
    const [isExpand, setExpand] = useState(false);

    const expandHandler = () => setExpand(!isExpand);

    return (
        <>
            {link ? (
                <LinkTitle href={link}><span>{title}<DropdownLabel>{withLabel}</DropdownLabel></span></LinkTitle>
            ) : (
                <Expand onClick={expandHandler}>
                    <ExpandTitle>{title}</ExpandTitle>
                    <ExpandContainer expand={isExpand}>
                        {children}
                    </ExpandContainer>
                </Expand>
            )}
        </>
    );
};
