import { request_press, success_press, failed_press } from '../actions/press';

const initialState = {
    isLoading: false,
    press: []
};

export const pressReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_press:
            return {
                ...state,
                isLoading: true
            };

        case success_press:
            return {
                ...state,
                press: action.payload,
                isLoading: false
            };
        case failed_press:
            return {
                ...state,
                isLoading: false,
                error: true
            };
        default:
            return state;
    }
};
