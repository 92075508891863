import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from '../../Button';
import { toggleMobileMenu } from '../../../services/actions/mobileMenu';
import {
    MobileMenuContainer,
    ButtonContainer,
    ButtonOffset,
    MenuContainer,
    AvatarImage,
    OuterLink,
    AvatarWrapper,
    UserName,
    DefaultAvatar,
    InnerLinkWrapper,
} from './styled';
import { ExpandMenuItem } from '../ExpandMenuItem';
import { InnerLink } from '../../InnerLink';
import config from '../../../config/url';

export const MobileMenu = () => {
    const d = useDispatch();
    const { open } = useSelector((state) => state.mobileMenu);
    const { error, userInfo } = useSelector((state) => state.auth);
    const { formatMessage } = useIntl();

    const clickHandler = () => d(toggleMobileMenu(false));

    useEffect(() => {
        if (open) {
            const x = window.scrollX;
            const y = window.scrollY;
            window.onscroll = function () {
                window.scrollTo(x, y);
            };
        } else {
            window.onscroll = function () {};
        }
    }, [open]);

    return (
        <MobileMenuContainer open={open}>
            {Object.keys(userInfo).length > 0 && (
                <OuterLink
                    href={formatMessage({
                        id: 'label_menu_header_side_avatar_url',
                    })}
                >
                    <AvatarWrapper>
                        {userInfo.profile.avatar ? (
                            <AvatarImage
                                src={userInfo.profile.avatar}
                                alt={`Yuppex | ${userInfo.profile.first_name} ${userInfo.profile.last_name} ${userInfo.profile.nickname}`}
                            />
                        ) : (
                            <DefaultAvatar className="icon-default-avatar" />
                        )}
                        <UserName>
                            {userInfo.profile.first_name &&
                            userInfo.profile.last_name
                                ? `${userInfo.profile.first_name} ${userInfo.profile.last_name}  ${userInfo.profile.nickname}`
                                : userInfo.profile.nickname}
                        </UserName>
                    </AvatarWrapper>
                </OuterLink>
            )}
            <MenuContainer>
                <ExpandMenuItem
                    title={formatMessage({ id: 'label_menu_header_about_us' })}
                >
                    <InnerLink onClick={clickHandler} to="/team">
                        {formatMessage({ id: 'label_menu_header_home_team' })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/review">
                        {formatMessage({ id: 'label_menu_header_home_press' })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/news">
                        {formatMessage({ id: 'label_menu_header_home_news' })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/contacts">
                        {formatMessage({
                            id: 'label_menu_header_home_contact',
                        })}
                    </InnerLink>
                </ExpandMenuItem>
                <ExpandMenuItem
                    title={formatMessage({ id: 'label_menu_header_trading' })}
                >
                    <InnerLink onClick={clickHandler} to="/markets">
                        {formatMessage({
                            id: 'label_menu_header_home_markets',
                        })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/game-coins">
                        {formatMessage({
                            id: 'label_menu_header_home_game_coins',
                        })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/assets-status">
                        {formatMessage({
                            id: 'label_menu_header_home_assets_status',
                        })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/fees">
                        {formatMessage({ id: 'label_menu_header_home_fees' })}
                    </InnerLink>
                </ExpandMenuItem>
                <ExpandMenuItem
                    title={formatMessage({ id: 'label_menu_header_features' })}
                >
                    <InnerLink onClick={clickHandler} to="/exchange">
                        {formatMessage({
                            id: 'label_menu_header_home_exchange',
                        })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/functionality">
                        {formatMessage({
                            id: 'label_menu_header_home_customizable_interface',
                        })}
                    </InnerLink>
                </ExpandMenuItem>
                <ExpandMenuItem
                    title={formatMessage({ id: 'label_menu_header_bonus' })}
                >
                    <InnerLink
                        onClick={clickHandler}
                        to="/news/investment-opportunities"
                    >
                        {formatMessage({
                            id: 'label_menu_header_home_invest_opportunity',
                        })}
                    </InnerLink>
                    <InnerLink
                        onClick={clickHandler}
                        to="/news/local-master-franchise"
                    >
                        {formatMessage({
                            id: 'label_menu_header_home_master_franchise',
                        })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/news/yuppie-coins">
                        {formatMessage({
                            id: 'label_menu_header_home_company_coins',
                        })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/listing">
                        {formatMessage({
                            id: 'label_menu_header_home_listing',
                        })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/banners">
                        {formatMessage({
                            id: 'label_menu_header_home_promo_activities',
                        })}
                    </InnerLink>
                </ExpandMenuItem>
                <InnerLinkWrapper>
                    <InnerLink onClick={clickHandler} to="/rating">
                        {formatMessage({ id: 'label_menu_header_p2p_rating' })}
                    </InnerLink>
                </InnerLinkWrapper>
                <ExpandMenuItem
                    withLabel={formatMessage({
                        id: 'label_menu_header_top_hot_label',
                    })}
                    onClick={clickHandler}
                    link={formatMessage({
                        id: 'label_menu_header_launchpad_url',
                    })}
                    title={formatMessage({ id: 'label_menu_header_launchpad' })}
                />
                <ExpandMenuItem
                    title={formatMessage({ id: 'label_menu_header_support' })}
                >
                    <InnerLink onClick={clickHandler} to="/faq">
                        {formatMessage({ id: 'label_menu_header_home_faq' })}
                    </InnerLink>
                    <InnerLink onClick={clickHandler} to="/academy">
                        {formatMessage({
                            id: 'label_menu_header_home_tuition',
                        })}
                    </InnerLink>
                    <a
                        onClick={clickHandler}
                        href={formatMessage({
                            id: 'label_menu_header_home_ticket_url',
                        })}
                    >
                        {formatMessage({ id: 'label_menu_header_home_ticket' })}
                    </a>
                </ExpandMenuItem>
            </MenuContainer>
            <ButtonContainer>
                <ButtonOffset>
                    <OuterLink
                        fluid
                        second
                        href={formatMessage({
                            id: 'label_menu_header_side_button_supportchat_url',
                        })}
                    >
                        {formatMessage({
                            id: 'label_menu_header_side_button_supportchat',
                        })}
                    </OuterLink>
                </ButtonOffset>
                {!error && !userInfo.profile && (
                    <React.Fragment>
                        <ButtonOffset>
                            <OuterLink
                                fluid
                                second
                                href={formatMessage({
                                    id: 'label_menu_header_side_button_registration_url',
                                })}
                            >
                                {formatMessage({
                                    id: 'label_menu_header_side_button_registration',
                                })}
                            </OuterLink>
                        </ButtonOffset>
                        <ButtonOffset>
                            <Button
                                fluid
                                href={formatMessage({
                                    id: 'label_menu_header_side_button_login_url',
                                })}
                            >
                                {formatMessage({
                                    id: 'label_menu_header_side_button_login',
                                })}
                            </Button>
                        </ButtonOffset>
                    </React.Fragment>
                )}
            </ButtonContainer>
        </MobileMenuContainer>
    );
};
