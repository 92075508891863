import React from 'react';
import { VideoWrapper, Times, VideoIframe } from './styled';

export const Video = ({ id, onClose }) => {
    return (
        <VideoWrapper>
            <Times onClick={onClose}>&times;</Times>
            <VideoIframe
                src={`https://www.youtube.com/embed/${id}?autoplay=1&frameborder="0"`}
            ></VideoIframe>
        </VideoWrapper>
    );
};
