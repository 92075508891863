import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import * as Page from '../pages';

export const LocalizationSwitch = ({
    children,
    languages,
    defaultLocale,
    locale
}) => {
    const getLocalizationPath = path => {
        if (locale === defaultLocale) {
            return path;
        }
        const url = languages.filter(el => el.code === locale)[0] ? languages.filter(el => el.code === locale)[0].url : 'en-US';
        return `/${url}` + path;
    };
    const modifiedRoutes = React.Children.map(children, child => {
        return React.isValidElement(child)
            ? React.cloneElement(child, {
                ...child.props,
                path: getLocalizationPath(child.props.path)
            })
            : child;
    });
    return (
        <Switch>
            <Route exact path="/">
                <Page.Home />
            </Route>
            <Route exact path="/markets">
                <Page.Assets />
            </Route>
            <Route exact path="/game-coins">
                <Page.GameCoins />
            </Route>
            <Route exact path="/assets-status">
                <Page.AssetsStatus />
            </Route>
            <Route exact path="/team">
                <Page.OurTeam />
            </Route>
            <Route exact path="/review">
                <Page.Press />
            </Route>
            <Route exact path="/news">
                <Page.News />
            </Route>
            <Route exact path="/contacts">
                <Page.ContactUs />
            </Route>
            <Route exact path="/fees">
                <Page.Fees />
            </Route>
            <Route exact path="/listing">
                <Page.Listing />
            </Route>
            <Route exact path="/banners">
                <Page.PromoActivities />
            </Route>
            <Route exact path="/exchange">
                <Page.Exchange />
            </Route>
            <Route exact path="/faq">
                <Page.FAQ />
            </Route>
            <Route exact path="/academy">
                <Page.Tuition />
            </Route>
            <Route exact path="/rating">
                <Page.Rating />
            </Route>
            <Route exact path="/functionality">
                <Page.CustomizableInterface />
            </Route>
            <Route exact path="/news/:alias">
                <Page.SingleNews />
            </Route>
            <Route exact path="/documentation/:alias">
                <Page.Documentation />
            </Route>
            {modifiedRoutes}
        </Switch>
    );
};
