import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getFaq } from '../../services/actions/faq';

export const useFaq = () => {
    const d = useDispatch();
    const { faq, isLoading } = useSelector(state => state.faq);
    const { locale } = useSelector(state => state.localization);
    const [active, setActive] = useState([]);
    const [value, setValue] = useState('');
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        setFilterList(faq);
    }, [faq]);

    useEffect(() => {
        const f = faq.filter(f => {
            return (
                f.title.toLowerCase().includes(value.toLowerCase()) ||
                f.content.toLowerCase().includes(value.toLowerCase())
            );
        });

        setFilterList(f);
    }, [value]);

    useEffect(() => {
        if (!faq.length) d(getFaq());
    }, []);

    useEffect(() => {
        d(getFaq());
    }, [locale]);

    const filterHandler = e => setValue(e.target.value);

    const activeHandler = idx => () => {
        if (!active.length) setActive([idx]);
        else {
            if (active.includes(idx)) {
                setActive(active.filter(i => i !== idx));
            } else {
                setActive([...active, idx]);
            }
        }
    };

    return {
        filterHandler,
        value,
        isLoading,
        faq,
        filterList,
        activeHandler,
        active
    };
};
