import styled, { css } from 'styled-components';

export const CopyIcon = styled.i`
    color: ${({ theme }) => theme.color11};
    font-size: 18px;
    cursor: pointer;
    position: relative;
    vertical-align: text-bottom;
    &:hover {
        color: ${({ theme }) => theme.color9};
    }

    ${({ white }) =>
        white &&
        css`
            color: ${({ theme }) => theme.color2};
            font-size: 24px;
            &:hover {
                color: ${({ theme }) => theme.color11};
            }
        `}
`;

export const SelectedText = styled.span`
    color: ${({ theme }) => theme.color1};
    background: ${({ theme }) => theme.color8};
    padding: 5px; 
    position: absolute;
    font-family: 'Museo Sans Cyrl', Arial, sans-serif;
    font-weight: 300;
    font-size: 21px;
    left: 50%;
    transform: translateX(-50%);
    top: -37px;
    transition: opacity 150ms;
    opacity: ${({ isShow }) => (isShow ? '1' : '0')};
    pointer-events: none;

    ${({ white }) =>
        white &&
        css`
            color: ${({ theme }) => theme.color5};
            background: ${({ theme }) => theme.color2};
        `}
`;
