import React, { useEffect, useState } from 'react';
import { Title } from './Title';
import { MainNews } from './MainNews';
import { News as NewsList } from './News';
import { useDispatch, useSelector } from 'react-redux';
import { getNews } from '../../services/actions/news';

export const News = () => {
    const d = useDispatch();
    const { news } = useSelector(state => state.news.newsList);
    const { locale } = useSelector(state => state.localization);
    const [pages, setPages] = useState(12);

    useEffect(() => {
        if (!news.length) d(getNews(pages));
    }, []);

    useEffect(() => {
        d(getNews(pages));
    }, [locale, pages]);

    const addPages = () => setPages(pages + 12);

    return (
        <React.Fragment>
            <Title />
            <MainNews />
            <NewsList addPages={addPages} />
        </React.Fragment>
    );
};
