import { takeEvery, put } from 'redux-saga/effects';
import { request_ticker, successTicker } from '../actions/ticker';
import { Fetch } from '../../utils/fetch';
import config from '../../config/url';
import { setError } from '../actions/error';

export function* TickerMiddleware() {
    yield takeEvery(request_ticker, getTicker);
}

function* getTicker() {
    const tickers = yield Fetch(`${config.apiPlatform}/v1/public/tickers`);
    if (tickers) {
        const parsedTickers = yield Object.keys(tickers).map(key => ({
            name: key.replace('_', '/'),
            ...tickers[key]
        }));
        const filteredTicker = yield parsedTickers.filter(
            ticker => ticker.isFrozen !== 1
        );
        yield put(successTicker(filteredTicker));
    } else {
        yield put(setError(true));
    }
}
