import {
    localization_languages_request,
    localization_languages_request_success,
    localization_languages_request_failed,
    localization_translation_request,
    localization_translation_request_success,
    localization_translation_request_failed,
    localization_set_default_locale,
    localization_set_locale
} from '../actions/localization';

const initialState = {
    isLoading: true
};

//temp
const route = {
    'route.home': '/',
    'route.assets': '/assets',
    'route.our-team': '/team',
    'route.press': '/review',
    'route.news': '/news',
    'route.single-news': '/news/:alias',
    'route.contacts': '/contacts',
    'route.fees': '/fees',
    'route.exchange': '/exchange',
    'route.faq': '/faq',
    'route.game-coins': '/game-coins',
    'route.customizable-interface': '/functionality'
};

export const localizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case localization_set_default_locale:
            return {
                ...state,
                defaultLocale: action.payload
            };
        case localization_set_locale:
            return {
                ...state,
                locale: action.payload
            };
        case localization_languages_request:
            return {
                ...state,
                isLoading: true
            };
        case localization_languages_request_success:
            return {
                ...state,
                languages: action.payload
            };
        case localization_languages_request_failed:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case localization_translation_request:
            return {
                ...state,
                isLoading: true
            };
        case localization_translation_request_success:
            return {
                ...state,
                translations: { ...route, ...action.payload },
                isLoading: false
            };
        case localization_translation_request_failed:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
