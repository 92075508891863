import React from 'react';
import { useIntl } from 'react-intl';
import {
    ButtonHolder,
} from './styled';
import { Section } from '../../Section';
import { Container } from '../../Container';
import { TitleH2, SubTitle } from '../../Title';
import { Rating } from '../../RatingPageComponents';
import { InnerLink } from '../../InnerLink';

export const SocialTraders = () => {
    const { formatMessage } = useIntl();
    return (
        <Section>
            <Container>
                <TitleH2>
                    {formatMessage({ id: 'social_traders_main_title' })}
                </TitleH2>
                <SubTitle>
                    {formatMessage({ id: 'social_traders_subtitle' })}
                </SubTitle>
                <Rating isHome />
                <ButtonHolder>
                    <InnerLink btn to="/rating">
                        <h3>{formatMessage({ id: 'social_traders_managers_search' })}</h3>
                    </InnerLink>
                </ButtonHolder>
            </Container>
        </Section>
    );
};
