export const request_assets = 'assets.request';
export const request_assets_success = 'assets.request.success';
export const request_assets_failed = 'assets.request.failed';

export const getAssets = () => ({
    type: request_assets
});

export const successAssets = data => ({
    type: request_assets_success,
    payload: data
});

export const failedAssets = () => ({
    type: request_assets_failed
});
