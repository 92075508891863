export const Fetch = async (url, options) => {
    try {
        const response = await fetch(url, {
            headers: options || {}
        });
        if ( response.status === 401 ){
            document.cookie = 'token =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
        }
        return await response.json();
    } catch (e) {
        return false;
    }
};
