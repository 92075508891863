import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../../Section';
import { Container } from '../../Container';
import {
    Content,
    ImageWrapper,
    ListWrapper,
    Li,
    ListItem,
    ListTitle,
    Ol,
    Image,
    AdaptiveImage,
} from './styled';

import dashboardImg from '../../../images/yuppex/dashboard.png';
import billingImg from '../../../images/yuppex/billing.png';
import chartImg from '../../../images/yuppex/support.png';
import tfaImg from '../../../images/yuppex/2fa.png';
import ticketImg from '../../../images/yuppex/ticket.png';

export const CustomizableContent = () => {
    const [idx, setIdx] = useState(0);
    const { formatMessage } = useIntl();
    const changeIdx = (_idx) => () => setIdx(_idx);

    return (
        <Section clearTopOffset>
            <Container>
                <Content>
                    <ListWrapper>
                        <ListItem>
                            <AdaptiveImage src={dashboardImg} alt="Image" />
                            <ListTitle
                                active={idx === 0}
                                onClick={changeIdx(0)}
                            >
                                {formatMessage({
                                    id: 'customizable_interface_dashboard_tittle',
                                })}
                            </ListTitle>
                            <Ol active={idx === 0}>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_chart',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_trading',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_market_depth',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_history',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_order_interface',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_trades',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_indicators',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_assets',
                                    })}
                                </Li>
                            </Ol>
                        </ListItem>
                        <ListItem>
                            <AdaptiveImage src={billingImg} alt="Image" />
                            <ListTitle
                                active={idx === 1}
                                onClick={changeIdx(1)}
                            >
                                {formatMessage({
                                    id: 'customizable_interface_billing_tittle',
                                })}
                            </ListTitle>
                            <Ol active={idx === 1}>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_billing_deposit',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_billing_withdraw',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_billing_transaction',
                                    })}
                                </Li>
                            </Ol>
                        </ListItem>
                        <ListItem>
                            <AdaptiveImage src={ticketImg} alt="Image" />
                            <ListTitle
                                active={idx === 2}
                                onClick={changeIdx(2)}
                            >
                                {formatMessage({
                                    id: 'customizable_interface_ticket_tittle',
                                })}
                            </ListTitle>
                            <Ol active={idx === 2}>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_ticket_live',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_ticket_history',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_content_support',
                                    })}
                                </Li>
                            </Ol>
                        </ListItem>
                        <ListItem>
                            <AdaptiveImage src={chartImg} alt="Image" />
                            <ListTitle
                                active={idx === 3}
                                onClick={changeIdx(3)}
                            >
                                {formatMessage({
                                    id: 'customizable_interface_content_support',
                                })}
                            </ListTitle>
                            <Ol active={idx === 3}>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_content_support',
                                    })}
                                </Li>
                            </Ol>
                        </ListItem>
                        <ListItem>
                            <AdaptiveImage src={tfaImg} alt="Image" />
                            <ListTitle
                                active={idx === 4}
                                onClick={changeIdx(4)}
                            >
                                {formatMessage({
                                    id: 'customizable_interface_profile_prof',
                                })}
                            </ListTitle>
                            <Ol active={idx === 4}>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_profile_settings',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_password_settings',
                                    })}
                                </Li>
                                <Li>
                                    {formatMessage({
                                        id: 'customizable_interface_visual',
                                    })}
                                </Li>
                            </Ol>
                        </ListItem>
                    </ListWrapper>
                    <ImageWrapper>
                        <Image
                            active={idx === 0}
                            src={dashboardImg}
                            alt="image"
                        />
                        <Image
                            active={idx === 1}
                            src={billingImg}
                            alt="image"
                        />
                        <Image active={idx === 2} src={ticketImg} alt="image" />
                        <Image active={idx === 3} src={chartImg} alt="image" />
                        <Image active={idx === 4} src={tfaImg} alt="image" />
                    </ImageWrapper>
                </Content>
            </Container>
        </Section>
    );
};
