import _Home from './Home';
import _Assets from './Assets';
import _AssetsStatus from './AssetsStatus';
import _ContactUs from './ContactUs';
import _CustomizableInterface from './CustomizableInterface';
import _Exchange from './Exchange';
import _FAQ from './FAQ';
import _Fees from './Fees';
import _Listing from './Listing';
import _News from './News';
import _OurTeam from './OurTeam';
import _Press from './Press';
import _PromoActivities from './PromoActivities';
import _SingleNews from './SingleNews';
import _Tuition from './Tuition';
import _Documentation from './Documentation';
import _NotFound from './NotFound';
import _GameCoins from './GameCoins';
import _Rating from './Rating';

export const Home = _Home;
export const Assets = _Assets;
export const AssetsStatus = _AssetsStatus;
export const ContactUs = _ContactUs;
export const CustomizableInterface = _CustomizableInterface;
export const Exchange = _Exchange;
export const FAQ = _FAQ;
export const Fees = _Fees;
export const Listing = _Listing;
export const News = _News;
export const OurTeam = _OurTeam;
export const Press = _Press;
export const PromoActivities = _PromoActivities;
export const SingleNews = _SingleNews;
export const Tuition = _Tuition;
export const Rating = _Rating;
export const Documentation = _Documentation;
export const NotFound = _NotFound;
export const GameCoins = _GameCoins;
