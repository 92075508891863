import styled from 'styled-components';
import a from 'color-alpha';

export const CardWrapper = styled.div`
    display: block;
    min-width: 200px;
    background: ${({ theme }) => theme.color1};
    margin: 10px;
    box-shadow: 0 0 10px ${({ theme }) => a(theme.color5, 0.16)};
    padding: 10px 16px;
    box-sizing: border-box;
    position: relative;
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

export const CardText = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const CardCurrency = styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.color29};
    font-weight: 600;
    margin-right: 10px;
`;

export const CardPercent = styled.div`
    font-size: 18px;
    color: ${({ theme, isHigh }) => (isHigh ? theme.color15 : theme.color16)};
`;

export const CardDescription = styled.div`
    font-size: 14px;
    line-height: normal;
    color: ${({ theme }) => theme.color5};
`;

export const Icon = styled.i`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 90px;
    color: ${({ theme }) => theme.color2};
`;
