import styled from 'styled-components';

export const Text = styled.p`
    margin: 40px auto 0; 
    font-size: 24px;
    line-height: 36px;
    max-width: 965px;
    text-indent: 0px;
    text-align: justify;
`;
export const SelectedText = styled.span`
    color: ${({ theme }) => theme.color11};
`;

export const InputWrapper = styled.div`
    margin: 35px auto 0;
    position: relative;
    max-width: 965px;
`;

export const Input = styled.input`
    line-height: 40px;
    border: 1px solid ${({ theme }) => theme.color3};
    font-weight: 300;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    &::placeholder {
        color: ${({ theme }) => theme.color3};
        opacity: 1;
    }
`;

export const InputIcon = styled.i`
    position: absolute;
    right: 15px;
    top: 9px;
    color: ${({ theme }) => theme.color3};
`;

export const FaqContainer = styled.div`
    max-width: 965px;
    margin: 26px auto 0;
`;

export const FaqItem = styled.div``;

export const FaqTitleText = styled.span`
    font-weight: 300;
    transition: color 150ms;
    color: ${({ theme, active }) => (active ? theme.color11 : theme.color6)};
`;

export const FaqTitle = styled.h3`
    line-height: 48px;
    cursor: pointer;
    transition: color 150ms;
    font-size: 20px;

    &:hover ${FaqTitleText} {
        color: ${({ theme }) => theme.color11};
    }
`;

export const FaqIcon = styled.i`
    font-size: 16px;
    margin-right: 25px;
`;

export const FaqContent = styled.div`
    padding-left: 40px;
    font-weight: 300;
    overflow: hidden;
    transition: max-height 300ms;
    max-height: ${({ active }) => (active ? '3000px' : '0')};

    @media (max-width: 450px) {
        padding-left: 0;
    }
`;

export const Error = styled.div`
    text-align: center;
    font-size: 40px;
    line-height: 50px;
    color: ${({ theme }) => theme.color6};
`;

export const Link = styled.a`
    display: block;
    padding: 15px 25px;
    background: ${({ theme, second }) =>
        second ? theme.color8 : theme.color11};
    text-decoration: none;
    color: ${({ theme }) => theme.color1};
    font-size: 18px;
    font-weight: 300;
    transition: background 150ms;
    white-space: nowrap;
    width: 160px;
    text-align: center;
    margin: 65px auto 0;

    &:hover {
        background: ${({ theme, second }) =>
            second ? theme.color26 : theme.color7};
    }
    &:active {
        background: ${({ theme, second }) =>
            second ? theme.color26 : theme.color11};
    }

    &:disabled {
        background: ${({ theme }) => theme.color3};
    }
`;
