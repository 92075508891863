import React from 'react';
import { useRouteMatch } from 'react-router';
import { SingleNews } from '../components/SingleNewsPageComponents';

export default () => {
    const {
        params: { alias }
    } = useRouteMatch();
    return (
        <React.Fragment>
            <SingleNews alias={alias} />
        </React.Fragment>
    );
};
