import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import { Loader } from '../Loader';
import {
    Text,
    SelectedText,
    InputWrapper,
    Input,
    InputIcon,
    FaqContainer,
    FaqContent,
    FaqIcon,
    FaqItem,
    FaqTitle,
    FaqTitleText,
    Error,
    Link
} from './styled';
import { useFaq } from './useFaq';
import config from '../../config/url';
import { Button } from '../Button';

export const FAQ = () => {
    const {
        filterHandler,
        value,
        isLoading,
        faq,
        filterList,
        activeHandler,
        active
    } = useFaq();
    const { formatMessage } = useIntl();

    return (
        <Section>
            <Container>
                <Title>{formatMessage({ id: 'faq_section_title' })}</Title>
                <Text>
                    <SelectedText>
                        {formatMessage({
                            id: 'content_our_team_section_header_company'
                        })}
                    </SelectedText>
                    &nbsp;
                    {formatMessage({ id: 'faq_section' })}
                </Text>
                <InputWrapper>
                    <Input
                        type="text"
                        placeholder={formatMessage({ id: 'find_placeholder' })}
                        onChange={filterHandler}
                        value={value}
                    />
                    <InputIcon className="icon-find" />
                </InputWrapper>
                <FaqContainer>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        filterList.map(({ title, content }, idx) => (
                            <FaqItem key={title}>
                                <FaqTitle onClick={activeHandler(idx)}>
                                    <FaqIcon
                                        className={`icon-${
                                            active.includes(idx)
                                                ? 'arrow-down'
                                                : 'plus'
                                        }`}
                                    />
                                    <FaqTitleText active={active.includes(idx)}>
                                        {title}
                                    </FaqTitleText>
                                </FaqTitle>
                                <FaqContent
                                    active={active.includes(idx)}
                                    dangerouslySetInnerHTML={{
                                        __html: content
                                    }}
                                />
                            </FaqItem>
                        ))
                    )}
                </FaqContainer>
                {!filterList.length && (
                    <Error> {formatMessage({ id: 'faq_empty_search' })}</Error>
                )}
                <Link
                    href={formatMessage({ id: 'faq_ask_button_url' })}                    
                >
                    {formatMessage({ id: 'faq_ask_button' })}
                </Link>
            </Container>
        </Section>
    );
};
