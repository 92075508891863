import React, { useEffect, memo } from 'react';
import { useIntl } from 'react-intl';
import { Loader } from '../../Loader';
import { TableRow, TableCell, Inner, TwoTables, ButtonsHolder, ButtonsBlock, Speed } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import { getRatings, getFullUserRating } from '../../../services/actions/ratings';
import Table from './components/table';
import { firstTable, secondTable, thirdTable } from './adapter';
import Chart from '../components/chart';
import RatingChart from '../../RatingChart'
import { theme } from '../../../config/yuppex.theme';
import { Button } from '../../../components/Button';
import config from '../../../config/url'


const FullRating = ({id, isNegative, chart, rating, nickname, userId}) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const {fullRatings} = useSelector(state => state.ratings);
    const {locale} = useSelector(state => state.localization);
    useEffect(() => {
        dispatch(getFullUserRating(id));
    }, []);

    const data = fullRatings.find(item => item.id === id);    
    
    return (
        <>
            {data &&
                <TableRow>
                    <TableCell isInner colSpan="10">
                        <Inner>
                            <Table data={firstTable(data)} />
                            <TwoTables>
                                <Table highlighted data={secondTable(data)} />
                                <Table highlighted data={thirdTable(data)} />                                
                            </TwoTables>   
                            <Chart locale={locale} alt data={chart} isNegative={isNegative} />
                            <Speed>
                                <RatingChart value={rating} ratingColor={theme.color11} />
                            </Speed>                            
                        </Inner>
                        <ButtonsHolder>
                            <ButtonsBlock>
                                <Button small href={`${config.wsLink}/billing/transfer-funds?user=${userId}&wallet=${data.id}&nickname=${nickname}`}>{formatMessage({id: 'p2p_rating_table_details_invest_button'})}</Button>
                                <Button third small href={`${config.wsLink}/dashboard/invested?walletId=${data.id}`}>{formatMessage({id: 'p2p_rating_table_details_add_to_wishlist_button'})}</Button>
                            </ButtonsBlock>
                            <Button small href={`${config.wsLink}/p/${nickname}`}>{formatMessage({id: 'p2p_rating_table_details_view_profile_button'})}</Button>
                        </ButtonsHolder>                        
                    </TableCell>
                </TableRow>
            }
        </>
    );
};

export default memo(FullRating);

