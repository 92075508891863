import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../../Section';
import { Container } from '../../Container';
import { Title } from '../../Title';
import { Text, TextWrapper } from './styled';

export const CustomizableInterface = () => {
    const { formatMessage } = useIntl();
    return (
        <Section>
            <Container>
                <Title>
                    {formatMessage({ id: 'customizable_interface_content_h1' })}
                </Title>
                <TextWrapper>
                    <Text>
                        {formatMessage({
                            id:
                                'customizable_interface_content_interface_header'
                        })}
                    </Text>
                </TextWrapper>
            </Container>
        </Section>
    );
};
