import React from 'react';
import { Header } from './HeaderComponents/Header';
import { Offset } from './HeaderComponents/Header/styled';
import { MobileMenu } from './HeaderComponents/MobileMenu';
import { Footer } from './Footer';

export const Layout = ({ children }) => (
    <>
        <Header />
        <Offset />
        <MobileMenu />
        {children}
        <Footer />
    </>
);
