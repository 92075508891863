import React, { useRef, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default-member
import Guage from './Guage';
import { RatingWrapper } from './style';

export default ({ value = 0, ratingColor }) => {
  const getWrapper = useRef(null);
  const [size, setSize] = useState({width: 0, height:0});
  useEffect(() => {
    setSize({width: getWrapper.current.offsetWidth - 50, height: getWrapper.current.offsetHeight - 50});

  }, []);

  return (
    <RatingWrapper ref={getWrapper}>
      <Guage value={value} size={size} ratingColor={ratingColor}/>
    </RatingWrapper>
  );
};
