import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title, SubTitle } from '../Title';
import {
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableWrapper,
    TableBody,
    TableContainer
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { getRatings } from '../../services/actions/ratings';
import Row from './components/row';

export const Rating = ({ isHome }) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const ratings = useSelector(state => state.ratings.ratings);
    const ratingsNumber = isHome ? 5 : 10;
    useEffect(() => {
        dispatch(getRatings(ratingsNumber));
    }, []);

    return (
        <Section isRating={isHome}>
            <Container>
                {!isHome && (
                    <>
                        <Title>
                            {formatMessage({ id: 'p2p_rating_table_title_h2' })}
                        </Title>
                        <SubTitle>
                            {formatMessage({
                                id: 'p2p_rating_table_subtitle_h3'
                            })}
                        </SubTitle>
                    </>
                )}

                <TableContainer isHome={isHome}>
                    <TableWrapper>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell first>
                                    {formatMessage({
                                        id: 'p2p_rating_table_rating'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell nickname>
                                    {formatMessage({
                                        id: 'p2p_rating_table_nickname'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    {formatMessage({
                                        id: 'p2p_rating_table_account'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell center>
                                    {formatMessage({
                                        id: 'p2p_rating_table_total_gross_p_l'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell right>
                                    {formatMessage({
                                        id: 'p2p_rating_table_balance'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell right>
                                    {formatMessage({
                                        id: 'p2p_rating_table_equity'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell right>
                                    {formatMessage({
                                        id: 'p2p_rating_table_investors_num'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell right>
                                    {formatMessage({
                                        id: 'p2p_rating_table_profit_percent'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell right last>
                                    {formatMessage({
                                        id: 'p2p_rating_table_account_created'
                                    })}
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {ratings.map(rating => (
                                <Row data={rating} key={rating.rating} active={isHome && ratings[0].wallet_id} />
                            ))}
                        </TableBody>
                    </TableWrapper>
                </TableContainer>
            </Container>
        </Section>
    );
};
