import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import { Loader } from '../Loader';
import { Copy } from '../Copy';
import {
    Text,
    PressContainer,
    PressIcon,
    PressImage,
    PressItem,
    PressLink,
    PressLinkWrapper,
    EmptyText,
    SelectedText
} from './styled';
import { getPress } from '../../services/actions/press';

export const Press = () => {
    const d = useDispatch();
    const { press, isLoading, error } = useSelector(state => state.press);
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (!press.length) d(getPress());
    }, []);

    return (
        <Section>
            <Container>
                <Title>{formatMessage({ id: 'content_press_tittle' })}</Title>
                <Text>{formatMessage({ id: 'content_press_header' })}</Text>
                {press.length === 0 ? (
                    <EmptyText>
                        {formatMessage({ id: 'press_empty' })}
                    </EmptyText>
                ) : isLoading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <PressContainer>
                            {press.map(({ link, image, created_at }) => (
                                <PressItem key={created_at}>
                                    <PressImage src={image} />
                                    <PressLinkWrapper>
                                        <PressLink>{link}</PressLink>
                                        <Copy value={link} white />
                                    </PressLinkWrapper>
                                </PressItem>
                            ))}
                        </PressContainer>
                        {error && <p>List is empty</p>}
                    </React.Fragment>
                )}
            </Container>
        </Section>
    );
};
