import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CryptoCarouselCard } from '../CryptoCarouselCard';
import { CustomSlider, SliderContainer } from './styled';
import { useCryptoCarousel } from './useCryptoCarousel';
import { Container } from '../../../Container';

export const CryptoCarousel = () => {
    const { isLoading, settings, tickers } = useCryptoCarousel();

    return (
        <SliderContainer show={isLoading}>
            <Container>
                <CustomSlider {...settings}>
                    {tickers &&
                        tickers
                            .slice(0, 10)
                            .map(
                                ({
                                    isFrozen,
                                    volume24hrUSD,
                                    percentChange,
                                    last,
                                    name
                                }) => {
                                    if (isFrozen) return null;
                                    return (
                                        <CryptoCarouselCard
                                            key={name}
                                            currency={name}
                                            volume={volume24hrUSD}
                                            percent={percentChange}
                                            lastPrice={last}
                                        />
                                    );
                                }
                            )}
                </CustomSlider>
            </Container>
        </SliderContainer>
    );
};
