import {
    request_ticker,
    request_ticker_success,
    request_ticker_failed
} from '../actions/ticker';

const initialState = {
    isLoading: true,
    tickers: []
};

export const ticketReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_ticker:
            return {
                ...state,
                isLoading: true
            };
        case request_ticker_success:
            return {
                ...state,
                tickers: action.payload,
                isLoading: false
            };
        case request_ticker_failed:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
