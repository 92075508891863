import styled from 'styled-components';

export const FooterWrapper = styled.footer`
    padding: 58px 0 23px 0;
    background: #000;
`;

export const Logo = styled.img`
    height: 40px;
    margin: auto 0;
`;

export const Navigation = styled.nav`
    margin-top: 45px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 24px;

    @media (max-width: 940px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 450px) {
        grid-template-columns: 1fr;
    }
`;

export const NavigationItem = styled.div``;

export const NavigationTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.color11};
    margin-bottom: 41px;
    line-height: 24px;
    cursor: default;
    white-space: nowrap;
`;

export const NavigationLinks = styled.div`
    display: flex;
    flex-direction: column;

    a {
        line-height: 24px;
        text-decoration: none;
        font-size: 18px;
        font-weight: 300;
        color: ${({ theme }) => theme.color1};

        &:hover {
            color: ${({ theme }) => theme.color11};
        }

        &:active {
            color: ${({ theme }) => theme.color7};
        }
    }
`;

export const CopyDescription = styled.div`
    margin-top: 24px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;

    div {
        margin-bottom: 10px;

        &:nth-child(1),
        &:nth-child(2) {
            text-indent: 0px;
            text-align: justify;
        }
    }
    @media (max-width: 1024px) {
        text-align: justify;
        text-indent: 45px;
    }
`;

export const Copy = styled.div`
    color: rgb(103, 105, 112);
    &.donate {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 0;

        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
        }
    }
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.color11};
        transition: color 150ms;
        &:hover {
            color: ${({ theme }) => theme.color9};
        }
    }
`;
export const CopyRight = styled.div`
    text-align: center;
    color: rgb(103, 105, 112);
`;
export const LogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
export const IconContainer = styled.div`
    display: flex;

    img {
        &:first-child {
            margin-right: 36px;
        }
    }
    @media (max-width: 720px) {
        margin-top: 20px;
    }
`;
export const Image = styled.img``;

export const CopyDonate = styled.div`
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
`;
