import {
    request_ticker_private,
    request_ticker_private_success,
    request_ticker_private_failed
} from '../actions/gameCoins';

const initialState = {
    isLoading: true,
    tickerPrivate: []
};

export const tickerPrivateReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_ticker_private:
            return {
                ...state,
                isLoading: true
            };
        case request_ticker_private_success:
            return {
                ...state,
                tickerPrivate: action.payload,
                isLoading: false
            };
        case request_ticker_private_failed:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
