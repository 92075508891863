import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { setLocale } from '../../../services/actions/localization';
import { setCookie } from '../../../utils/cookies';

export const useLanguageSwitcher = () => {
    const d = useDispatch();
    const { pathname } = useLocation();
    const { languages, defaultLocale, locale } = useSelector(
        state => state.localization
    );

    const [isOpen, setOpen] = useState(false);

    const openHandler = () => setOpen(true);
    const closeHandler = () => setOpen(false);

    const getToPath = (code, url) => {
        if (pathname.match(/^\/[a-z]{2}\//)) {
            const [pathCode] = pathname.match(/^\/[a-z]{2}/);
            const [, path] = pathname.split(pathCode);
            if (code === defaultLocale) return path;
            return `/${url}` + path;
        }
        if (code === defaultLocale) return pathname;
        return `/${url}` + pathname;
    };

    const clickHandler = locale => () => {
        setCookie('allLocale', locale, 365);
        d(setLocale(locale))
    };

    return {
        languages,
        clickHandler,
        getToPath,
        isOpen,
        openHandler,
        closeHandler,
        locale
    };
};
