import styled, { css } from 'styled-components';
import a from 'color-alpha';
import { Link } from '../../InnerLink/styled';

export const Dropdown = styled.div`
    position: relative;
    margin-right: 30px;
    @media (max-width: 1400px) {
        margin-right: 15px;
    }
`;

export const DropdownLabel = styled.div`
    background-color: ${({ theme }) => theme.color11};
    border-radius: 20px;
    color: ${({ theme }) => theme.color1};
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    right: -10px;
    bottom: 100%;
    margin-bottom: -35px;
    padding: 2px 5px;
    line-height: 1;
`;
export const DropdownTitle = styled.div`
    font-size: 18px;
    color: #fff;
    cursor: default;
    position: relative;
    padding: 35px 0 32px 0;
    &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.color11};
    }
    border-bottom: 2px solid
        ${({ theme, open }) => (open ? theme.color11 : 'transparent')};
    ${({ open }) =>
        open &&
        css`
            color: ${({ theme }) => theme.color10};
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 60px;
            }
        `}

    @media (max-width: 1400px) {
        font-size: ${({ theme }) => theme.fontMd};
    }
`;

export const DropdownList = styled.div`
    position: absolute;
    background: ${({ theme }) => theme.color1};
    box-shadow: 0 3px 10px ${({ theme }) => a(theme.color5, 0.16)};
    overflow: hidden;
    top: 100%;
    min-width: max-content;
    opacity: 0;
    pointer-events: none;
    &.dropdown-list {
        &-enter {
            opacity: 0;
            pointer-events: none;
        }
        &-enter-active {
            opacity: 1;
            transition: opacity 150ms;
            pointer-events: auto;
        }
        &-enter-done {
            opacity: 1;
            pointer-events: auto;
        }
        &-exit {
            opacity: 1;
        }
        &-exit-active {
            opacity: 0;
            transition: opacity 150ms;
        }
        &-exit-done {
            opacity: 0;
            pointer-events: none;
        }
    }
`;

export const DropdownItem = styled.div`
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.color2};
    }
    ${Link} {
        height: auto;
    }
    a {
        height: auto;
        text-decoration: none;
        color: ${({ theme }) => theme.color6};
        display: block;
        width: 100%;
        padding: 7px 15px;
        font-weight: 300;
        font-size: 15px;
    }
`;
