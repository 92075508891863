import React from 'react';
import { useIntl } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    AdvantagesWrapper,
    AdvantagesIcon,
    AdvantagesItem,
    AdvantagesText,
    AdvantagesTitle,
    AdvantagesImage,
    ImagesWrapper,
    QrImage,
    QrImageWrapper,
} from './styled';
import { Section } from '../../Section';
import { Container } from '../../Container';
import { TitleH2 } from '../../Title';
import android from '../../../images/yuppex/apk_badge_564x168.png'
import appStore from '../../../images/yuppex/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png'
import googlePlay from '../../../images/yuppex/en_badge_web_generic.png'
import comQr from '../../../images/yuppex/com.yuppex.exchange.apk.svg'
import playQr from '../../../images/yuppex/com.yuppex.svg'
import exQr from '../../../images/yuppex/ex.yuppex.com.svg'
import galaxyQr from '../../../images/yuppex/galaxy.store_280x280.png'
import galaxyStoreRu from '../../../images/yuppex/GalaxyStore_ru-ru.png'
import galaxyStoreEn from '../../../images/yuppex/GalaxyStore_en-us.png'
import galaxyStoreZh from '../../../images/yuppex/GalaxyStore_zh-cn.png'
import { useSelector } from 'react-redux';

export const ExchangeAssets = () => {
    const { formatMessage } = useIntl();
    const {locale} = useSelector(state => state.localization);
    let galaxyImage = '';
    switch (locale) {
        case 'zh-CN':
            galaxyImage = galaxyStoreZh;
            break;
        case 'ru-RU':
            galaxyImage = galaxyStoreRu;
            break;
        default: galaxyImage = galaxyStoreEn;
    }
    return null
     // TODO uncomment when mobile app will be available
    // return (
    //     <Section second>
    //         <Container>
    //             <TitleH2>
    //                 {formatMessage({ id: 'exchange_assets_main_title' })}
    //             </TitleH2>
    //             <AdvantagesWrapper>
    //                 <ScrollAnimation animateOnce animateIn="fadeInUp" delay={0}>
    //                     <AdvantagesItem>
    //                         <ImagesWrapper>
    //                             <AdvantagesImage src={googlePlay} alt={formatMessage({ id: 'exchange_assets_google_play_alt' })} />
    //                             <QrImageWrapper>
    //                                 <QrImage src={playQr} alt={formatMessage({ id: 'exchange_assets_google_play_alt' })} />
    //                             </QrImageWrapper>
    //                         </ImagesWrapper>
    //                         <AdvantagesTitle href="https://play.google.com/store/apps/details?id=com.yuppex">
    //                             {formatMessage({
    //                                 id: 'exchange_assets_header_1'
    //                             })}
    //                         </AdvantagesTitle>
    //                         <AdvantagesText>
    //                             {formatMessage({
    //                                 id: 'exchange_assets_text_1'
    //                             })}
    //                         </AdvantagesText>
    //                     </AdvantagesItem>
    //                 </ScrollAnimation>
    //                 <ScrollAnimation animateOnce animateIn="fadeInUp" delay={0}>
    //                     <AdvantagesItem>
    //                         <ImagesWrapper>
    //                             <AdvantagesImage src={galaxyImage} alt={formatMessage({ id: 'exchange_assets_galaxy_store_alt' })} />
    //                             <QrImageWrapper>
    //                                 <QrImage src={galaxyQr} alt={formatMessage({ id: 'exchange_assets_galaxy_store_alt' })} />
    //                             </QrImageWrapper>
    //                         </ImagesWrapper>
    //                         <AdvantagesTitle href="https://galaxy.store/yuppex">
    //                             {formatMessage({
    //                                 id: 'exchange_assets_header_5'
    //                             })}
    //                         </AdvantagesTitle>
    //                         <AdvantagesText>
    //                             {formatMessage({
    //                                 id: 'exchange_assets_text_5'
    //                             })}
    //                         </AdvantagesText>
    //                     </AdvantagesItem>
    //                 </ScrollAnimation>
    //                 <ScrollAnimation
    //                     animateOnce
    //                     animateIn="fadeInUp"
    //                     delay={300}
    //                 >
    //                     <AdvantagesItem>
    //                         <ImagesWrapper>
    //                             <AdvantagesImage src={android} alt={formatMessage({ id: 'exchange_assets_apk_alt' })} />
    //                             <QrImageWrapper>
    //                                 <QrImage src={comQr} alt={formatMessage({ id: 'exchange_assets_apk_alt' })} />
    //                             </QrImageWrapper>
    //                         </ImagesWrapper>
    //                         <AdvantagesTitle href="https://app.yuppex.com/com.yuppex.exchange.apk">
    //                             {formatMessage({
    //                                 id: 'exchange_assets_header_2'
    //                             })}
    //                         </AdvantagesTitle>
    //                         <AdvantagesText>
    //                             {formatMessage({
    //                                 id: 'exchange_assets_text_2'
    //                             })}
    //                         </AdvantagesText>
    //                     </AdvantagesItem>
    //                 </ScrollAnimation>
    //                 <ScrollAnimation
    //                     animateOnce
    //                     animateIn="fadeInUp"
    //                     delay={600}
    //                 >
    //                     <AdvantagesItem>
    //                         <ImagesWrapper>
    //                             <AdvantagesImage src={appStore} alt={formatMessage({ id: 'exchange_assets_app_store_alt' })} />
    //                             <QrImageWrapper>
    //                                 <QrImage src={exQr} alt={formatMessage({ id: 'exchange_assets_app_store_alt' })} />
    //                             </QrImageWrapper>
    //                         </ImagesWrapper>
    //                         <AdvantagesTitle href="https://ex.yuppex.com">
    //                             {formatMessage({
    //                                 id: 'exchange_assets_header_3'
    //                             })}
    //                         </AdvantagesTitle>
    //                         <AdvantagesText>
    //                             {formatMessage({
    //                                 id: 'exchange_assets_text_3'
    //                             })}
    //                         </AdvantagesText>
    //                     </AdvantagesItem>
    //                 </ScrollAnimation>
    //             </AdvantagesWrapper>
    //         </Container>
    //     </Section>
    // );
};
