import {
    request_ratings,
    request_ratings_success,
    request_ratings_failed,
    request_users_success,
    request_full_user_rating,
    request_full_ratings_success,
} from '../actions/ratings';

const initialState = {
    isLoading: true,
    ratings: [],
    fullRatings: [],
};

export const ratingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_full_user_rating:
        case request_ratings:
            return {
                ...state,
                isLoading: true
            };
        case request_ratings_success:
            return {
                ...state,
                ratings: action.payload,
                isLoading: false
            };            
        case request_full_ratings_success:            
            return {
                ...state,
                fullRatings: [...state.fullRatings, action.payload],
                isLoading: false
            };
        case request_users_success:
            let modifiedRatings = [];
            state.ratings.map(item => {
                const rating = action.payload.find(({id}) => +item.user_id === +id);
                modifiedRatings.push({...rating, ...item});
            })
            return {
                ...state,
                ratings: modifiedRatings.sort((a,b) => (a.rating > b.rating) ? -1 : ((b.rating > a.rating) ? 1 : 0)),
                isLoading: false
            };
        case request_ratings_failed:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
