import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    color: ${({ theme }) => theme.color6};
    font-size: 14px;
    position: fixed;
    background: ${({ theme }) => theme.color2};
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 20px 0;
    box-shadow: 0 -1px 3px 0 ${({ theme }) => theme.color11};   
    @media (max-width: 1024px) {
      font-size: 12px;
      padding: 0;
    }
`;
export const Title = styled.div`
    color: ${({ theme }) => theme.color11};
    font-weight: bold;
    font-size: 18px;
    position: relative;
    display: flex;
    justify-content: space-between;
`;

export const IconClose = styled.i`
    color: ${({ theme }) => theme.color11};    
    font-size: 18px;
    position: relative;
    transform: rotate(45deg);
    display: none;
    @media (max-width: 1024px) {
      display: inline-block;
    }
`;

export const ButtonHolder = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  a {
    cursor:pointer;
  }
  @media (max-width: 1024px) {
      display: none;
    }
`;
export const TextWrap = styled.div`
@media (max-width: 1024px) {
      line-height: 1;
    }
`;
export const ImageHolder = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
      display: none;
    }
`;
export const Content = styled.div`
    width: 75%;
    margin: 0 auto;
    display: flex;
    @media (max-width: 1024px) {
      width: auto;
      padding: 10px;
    }
`;
export const Text = styled.div`
    margin-top: 20px;
     a {
      color: ${({ theme }) => theme.color11};;
    }
    @media (max-width: 1024px) {
      margin-top: 5px;
    }
`;

export const Image = styled.img`
    color: ${({ theme }) => theme.color2};
    font-size: 14px;
    position: relative;
    width: 120px;
`;
