export const firstTable = (data) => [
    {title: 'p2p_rating_table_details_account_status', value: +data.active === 0 ? 'Not Active' : 'Active'},
    {title: 'p2p_rating_table_details_trades_num', value: data.total_count.toFixed(2)},
    {title: 'p2p_rating_table_details_winning_trades_num', value: data.profit_count.toFixed(2)},
    {title: 'p2p_rating_table_details_winning_trades_percent', value: data.profit_percent.toFixed(2)},
    {title: 'p2p_rating_table_details_losing_trades_num', value: data.loss_count.toFixed(2)},
    {title: 'p2p_rating_table_details_losing_trades_percent', value: data.loss_percent.toFixed(2)},
];

export const secondTable = (data) => [
    {title: 'p2p_rating_table_details_gross_profit', value: data.gross_profit.toFixed(2)},
    {title: 'p2p_rating_table_details_gross_loss', value: data.gross_loss.toFixed(2)},    
];

export const thirdTable = (data) => [
    {title: 'p2p_rating_table_details_profit_factor', value: data.profit_factor.toFixed(2)},
    {title: 'p2p_rating_table_details_expected_payoff', value: data.expected_payoff.toFixed(2)},    
];