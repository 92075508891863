import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { InnerLink } from '../InnerLink';
import { Container } from '../Container';
import {
    FooterWrapper,
    Logo,
    Navigation,
    NavigationItem,
    NavigationLinks,
    NavigationTitle,
    Copy,
    LogoContainer,
    IconContainer,
    CopyDescription,
    CopyRight,
} from './styled';
import { OuterLink } from '../HeaderComponents/Header/styled';
import logo from '../../images/yuppex/logo-white.svg';

export const Footer = () => {
    const { documentation, isLoading } = useSelector(
        (state) => state.documentation.documentationList
    );
    const { formatMessage } = useIntl();
    return (
        <FooterWrapper>
            <Container>
                <LogoContainer>
                    <Logo src={logo} alt="Yuppex" />
                    <IconContainer />
                </LogoContainer>
                <Navigation>
                    {!isLoading && documentation.length ? (
                        <NavigationItem>
                            <NavigationTitle>
                                {formatMessage({
                                    id: 'label_menu_header_documentation',
                                })}
                            </NavigationTitle>

                            <NavigationLinks>
                                {documentation.map(
                                    ({ title, alias, created_at }) => (
                                        <InnerLink
                                            key={created_at}
                                            to={`/documentation/${alias}`}
                                            activeClassName="selected"
                                        >
                                            {title}
                                        </InnerLink>
                                    )
                                )}
                            </NavigationLinks>
                        </NavigationItem>
                    ) : null}
                    <NavigationItem>
                        <NavigationTitle>
                            {formatMessage({
                                id: 'label_menu_header_about_us',
                            })}
                        </NavigationTitle>
                        <NavigationLinks>
                            <InnerLink to="/team" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_team',
                                })}
                            </InnerLink>
                            <InnerLink to="/review" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_press',
                                })}
                            </InnerLink>
                            <InnerLink to="/news" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_news',
                                })}
                            </InnerLink>
                            <InnerLink
                                to="/contacts"
                                activeClassName="selected"
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_contact',
                                })}
                            </InnerLink>
                        </NavigationLinks>
                    </NavigationItem>
                    <NavigationItem>
                        <NavigationTitle>
                            {formatMessage({ id: 'label_menu_header_trading' })}
                        </NavigationTitle>
                        <NavigationLinks>
                            <InnerLink to="/markets" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_markets',
                                })}
                            </InnerLink>
                            <InnerLink
                                to="/game-coins"
                                activeClassName="selected"
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_game_coins',
                                })}
                            </InnerLink>
                            <InnerLink
                                to="/assets-status"
                                activeClassName="selected"
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_assets_status',
                                })}
                            </InnerLink>
                            <InnerLink to="/fees" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_fees',
                                })}
                            </InnerLink>
                        </NavigationLinks>
                    </NavigationItem>
                    <NavigationItem>
                        <NavigationTitle>
                            {formatMessage({
                                id: 'label_menu_header_features',
                            })}
                        </NavigationTitle>
                        <NavigationLinks>
                            <InnerLink
                                to="/exchange"
                                activeClassName="selected"
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_exchange',
                                })}
                            </InnerLink>
                            <InnerLink
                                to="/functionality"
                                activeClassName="selected"
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_customizable_interface',
                                })}
                            </InnerLink>
                        </NavigationLinks>
                    </NavigationItem>
                    <NavigationItem>
                        <NavigationTitle>
                            {formatMessage({
                                id: 'label_menu_header_bonus',
                            })}
                        </NavigationTitle>
                        <NavigationLinks>
                            <InnerLink
                                to="/news/investment-opportunities"
                                activeClassName="selected"
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_invest_opportunity',
                                })}
                            </InnerLink>
                            <InnerLink
                                to="/news/local-master-franchise"
                                activeClassName="selected"
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_master_franchise',
                                })}
                            </InnerLink>
                            <InnerLink
                                to="/news/yuppie-coins"
                                activeClassName="selected"
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_company_coins',
                                })}
                            </InnerLink>
                            <InnerLink to="/banners" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_promo_activities',
                                })}
                            </InnerLink>
                            <InnerLink to="/listing" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_listing',
                                })}
                            </InnerLink>
                        </NavigationLinks>
                    </NavigationItem>
                    <NavigationItem>
                        <NavigationTitle>
                            {formatMessage({ id: 'label_menu_header_support' })}
                        </NavigationTitle>
                        <NavigationLinks>
                            <InnerLink to="/faq" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_faq',
                                })}
                            </InnerLink>
                            <InnerLink to="/academy" activeClassName="selected">
                                {formatMessage({
                                    id: 'label_menu_header_home_tuition',
                                })}
                            </InnerLink>
                            <OuterLink
                                href={formatMessage({
                                    id: 'label_menu_header_home_ticket_url',
                                })}
                            >
                                {formatMessage({
                                    id: 'label_menu_header_home_ticket',
                                })}
                            </OuterLink>
                        </NavigationLinks>
                    </NavigationItem>
                </Navigation>
                <CopyDescription>
                    <Copy>{formatMessage({ id: 'footer_address' })}</Copy>
                    <Copy>
                        {formatMessage(
                            { id: 'footer_donate_desc_1' },
                            {
                                footer_donate_desc_1_url_text: (
                                    <a
                                        href={formatMessage({
                                            id: 'footer_donate_desc_1_url',
                                        })}
                                    >
                                        {formatMessage({
                                            id: 'footer_donate_desc_1_url_text',
                                        })}
                                    </a>
                                ),
                            }
                        )}
                    </Copy>
                    <CopyRight>
                        {formatMessage({ id: 'footer_copyright' })}
                    </CopyRight>
                </CopyDescription>
            </Container>
            {/*<CookieContainer/>*/}
        </FooterWrapper>
    );
};
