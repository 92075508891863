import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 50px;
    font-weight: 700; 
    text-align: center;
    line-height: 50px;
    color: ${({ theme }) => theme.color5};

    @media (max-width: 560px) {
        font-size: 40px;
    }
`;
export const SubTitle = styled.p`
    font-size: 24px;
    line-height: 36px;    
    text-align: justify;
    margin-top: 40px;
`;
export const TitleH2 = styled.h2`
    font-size: 50px;
    font-weight: 700; 
    text-align: center;
    line-height: 50px;
    color: ${({ theme }) => theme.color5};

    @media (max-width: 560px) {
        font-size: 40px;
    }
`;
