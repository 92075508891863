import React from 'react';
import { useIntl } from 'react-intl';
import {
    CardWrapper,
    CardCurrency,
    CardHeader,
    CardPercent,
    CardDescription,
    Icon,
    CardText
} from './styled';
import { formatNumber } from '../../../../utils/formatNumber';

export const CryptoCarouselCard = ({
    currency,
    volume,
    percent,
    lastPrice
}) => {
    const { formatMessage } = useIntl();
    const [iconName] = currency.split('/');
    return (
        <CardWrapper>
            <Icon className={`icon-${iconName.toLowerCase()}`} />
            <CardHeader>
                <CardCurrency>{currency}</CardCurrency>
                <CardPercent isHigh={Number(percent) >= 0}>
                    {percent ? `${Number(percent).toFixed(2)}%` : '0%'}
                </CardPercent>
            </CardHeader>
            <CardText>
                <CardDescription>
                    {formatMessage({
                        id: 'crypto_label_last_price'
                    })}
                </CardDescription>
                <CardDescription>{formatNumber(lastPrice)}</CardDescription>
                <CardDescription>
                    {formatMessage({
                        id: 'crypto_label_volume'
                    })}
                </CardDescription>
                <CardDescription>
                    {
                        formatNumber(Number(volume)
                        .toFixed(8)
                        .replace(/\.?0+$/, ''))
                    }
                </CardDescription>
            </CardText>
        </CardWrapper>
    );
};
