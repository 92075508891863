export const request_faq = 'faq.request';
export const success_faq = 'faq.success';

export const getFaq = () => ({
    type: request_faq
});

export const successFaq = faq => ({
    type: success_faq,
    payload: faq
});
