import styled from 'styled-components';

export const Table = styled.div`
    margin: 43px 0;
    overflow: auto;
`;

export const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 2.5fr 2.5fr 2.5fr 2.5fr 2.5fr 2.5fr;
    padding: 0 25px;
    box-sizing: border-box;
    border-bottom: 1px solid ${({ theme }) => theme.color2};
    padding-bottom: 26px;

    @media (max-width: 1200px) {
        min-width: 1485px;
    }
`;

export const HeaderCell = styled.div`
    font-size: 21px;
    line-height: 24px;
    color: ${({ theme }) => theme.color3};

    &:last-child {
        text-align: right;
    }
`;

export const TableBody = styled.div`
    @media (max-width: 1200px) {
        min-width: 1485px;
    }
`;

export const TableRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2.5fr 2.5fr 2.5fr 2.5fr 2.5fr 2.5fr;
    padding: 33px 25px;
    box-sizing: border-box;
    border-bottom: 1px solid ${({ theme }) => theme.color2};
    align-items: center;
    cursor: pointer;
    transition: background 150ms;

    &:hover {
        background: ${({ theme }) => theme.color2};
    }
`;

export const TableCell = styled.div`
    font-size: 21px;
    color: ${({ theme, isHigh, isColored }) =>
        isColored && (isHigh ? theme.color15 : theme.color16)};
        
    &:last-child {
        text-align: right;
    }
    &:nth-child(2) {
        font-size: 24px;
    }
`;

export const Icon = styled.i`
    font-size: 40px;
    color: ${({ theme }) => theme.color3};
`;

export const OuterLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.color5};
`;
export const Title = styled.h2`
    font-size: 50px;
    font-weight: 700; 
    text-align: center;
    line-height: 50px;
    color: ${({ theme }) => theme.color5};

    @media (max-width: 560px) {
        font-size: 40px;
    }
`;
