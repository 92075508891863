import styled from 'styled-components';

export const TextWrapper = styled.div`
    margin-top: 47px;
`;

export const Text = styled.p`
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 30px;
    text-indent: 0px;
    text-align: justify;

    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 1400px) {
        font-size: 18px;
    }
    @media (max-width: 1024px) {
        text-align: justify;
        text-indent: 45px;
    }
`;
