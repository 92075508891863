import styled, { css } from 'styled-components';

export const Container = styled.div`
    max-width: ${({ theme }) => theme.containerWidthLg};
    flex-basis: ${({ theme }) => theme.containerWidthLg};
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @media (max-width: 1600px) {
        max-width: ${({ theme }) => theme.containerWidthMd};
        flex-basis: ${({ theme }) => theme.containerWidthMd};
    }

    @media (max-width: 1200px) {
        max-width: ${({ theme }) => theme.containerWidthSm};
        flex-basis: ${({ theme }) => theme.containerWidthSm};
    }
    @media (max-width: 1080px) {
        max-width: available;
        width: 100%;
        flex-basis: 100%;
        padding: 24px;
        box-sizing: border-box;
        align-items: flex-start;
    }

    ${({ flex }) =>
        flex &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        `}
`;
