import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { store } from './services/store';
import { Router } from './Router';
import { GlobalStyles } from './GlobalStyles';
import { theme } from './config/yuppex.theme';

export const App = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Router />
            </ThemeProvider>
        </Provider>
    );
};
