import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import { Loader } from '../Loader';
import {
    FilterWrapper,
    Filter,
    FilterIcon,
    FilterInput,
    FilterInputWrapper,
    FilterInputIcon,
    Table,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableRowCell,
    TableRowCellIcon,
    ErrorWrapper,
    ErrorText,
    AssetsContent
} from './styled';
import { useAssets } from './useAssets';
import config from '../../config/url';
import { formatNumber } from '../../utils/formatNumber';
import { useSelector } from 'react-redux';

export const GameCoins = () => {
    const {
        isLoading,
        tickers,
        activeTab,
        favoriteAssets,
        filters,
        searchValue,
        viewList,
        searchHandler,
        activeTabHandler,
        favoriteHandler
    } = useAssets();
    const { formatMessage } = useIntl();
    const authUser = useSelector(state => state.auth.userInfo.id);
    const favoriteClick = name => e => {
        e.preventDefault();
        favoriteHandler(name);
    };

    return (
        <Section>
            <Container>
                <Title>{formatMessage({ id: 'game_coins_table_title' })}</Title>
                {isLoading ? (
                    <Loader />
                ) : (
                    <AssetsContent>
                        <FilterWrapper>
                            <FilterIcon
                                className={`icon-star-${
                                    activeTab === 'STAR' ? 'filled' : 'empty'
                                }`}
                                active={activeTab === 'STAR'}
                                onClick={activeTabHandler('STAR')}
                            />
                            <Filter
                                active={activeTab === 'ALL'}
                                onClick={activeTabHandler('ALL')}
                            >
                                {formatMessage({
                                    id: 'game_coins_table_tab_all'
                                })}
                            </Filter>
                            {filters.slice(0, 5).map(key => (
                                <Filter
                                    key={key}
                                    active={activeTab === key}
                                    onClick={activeTabHandler(key)}
                                >
                                    {key}
                                </Filter>
                            ))}
                            <FilterInputWrapper>
                                <FilterInput
                                    type="text"
                                    placeholder={formatMessage({
                                        id: 'find_placeholder'
                                    })}
                                    onChange={searchHandler}
                                    value={searchValue}
                                />
                                <FilterInputIcon className="icon-find" />
                            </FilterInputWrapper>
                        </FilterWrapper>
                        <Table>
                            <TableHeader>
                                <TableHeaderCell>
                                    {formatMessage({
                                        id: 'game_coins_table_token'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    {formatMessage({
                                        id: 'game_coins_table_last_traded'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    {formatMessage({
                                        id: 'game_coins_table_change'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    {formatMessage({
                                        id: 'game_coins_table_low'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    {formatMessage({
                                        id: 'game_coins_table_high'
                                    })}
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    {formatMessage({
                                        id: 'game_coins_table_volume'
                                    })}
                                </TableHeaderCell>
                            </TableHeader>
                            <div>
                                {viewList.map(
                                    ({
                                        isFrozen,
                                        last,
                                        volume24hrUSD,
                                        percentChange,
                                        low24hr,
                                        high24hr,
                                        name,
                                        id
                                    }) => {
                                        if (isFrozen) return null;
                                        return (
                                            <TableRow
                                                key={id}                                                
                                                href={`https://ex.yuppex.com/${authUser ? 'dashboard' : 'market'}/${name.replace('/', '-')}`}
                                            >
                                                <TableRowCell>
                                                    <TableRowCellIcon
                                                        className={`icon-star-${
                                                            favoriteAssets.includes(
                                                                name
                                                            )
                                                                ? 'filled'
                                                                : 'empty'
                                                        }`}
                                                        onClick={favoriteClick(
                                                            name
                                                        )}
                                                        active={favoriteAssets.includes(
                                                            name
                                                        )}
                                                    />
                                                    {name}
                                                </TableRowCell>
                                                <TableRowCell>
                                                    {formatNumber(last)}
                                                </TableRowCell>
                                                <TableRowCell
                                                    isColored
                                                    isHigh={percentChange >= 0}
                                                >
                                                    {percentChange
                                                        ? `${Number(
                                                              percentChange
                                                          ).toFixed(2)}%`
                                                        : '0%'}
                                                </TableRowCell>
                                                <TableRowCell>
                                                    {formatNumber(low24hr)}
                                                </TableRowCell>
                                                <TableRowCell>
                                                    {formatNumber(high24hr)}
                                                </TableRowCell>
                                                <TableRowCell>
                                                    {isNaN(volume24hrUSD)
                                                        ? 0
                                                        : formatNumber(
                                                              Number(
                                                                  volume24hrUSD
                                                              )
                                                                  .toFixed(8)
                                                                  .replace(
                                                                      /\.?0+$/,
                                                                      ''
                                                                  )
                                                          )}
                                                </TableRowCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </div>
                        </Table>
                        <ErrorWrapper>
                            {activeTab === 'STAR' ? (
                                !favoriteAssets.length ? (
                                    <ErrorText>
                                        {formatMessage({
                                            id: 'game_coins_favorites_empty'
                                        })}
                                    </ErrorText>
                                ) : (
                                    !viewList.length && (
                                        <ErrorText>
                                            {formatMessage({
                                                id: 'game_coins_empty_search'
                                            })}
                                        </ErrorText>
                                    )
                                )
                            ) : (
                                !viewList.length && (
                                    <ErrorText>
                                        {formatMessage({
                                            id: 'game_coins_empty'
                                        })}
                                    </ErrorText>
                                )
                            )}
                        </ErrorWrapper>
                    </AssetsContent>
                )}
            </Container>
        </Section>
    );
};
