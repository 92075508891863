import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import a from 'color-alpha';

export const LanguageList = styled.div`
    position: absolute;
    background: ${({ theme }) => theme.color1};
    box-shadow: 0 3px 10px ${({ theme }) => a(theme.color5, 0.16)};
    overflow: hidden;
    top: 100%;
    min-width: 180px;
    max-width: 400px;
    opacity: 0;
    pointer-events: none;
    &.language-switcher {
        &-enter {
            opacity: 0;
            pointer-events: none;
        }
        &-enter-active {
            opacity: 1;
            transition: opacity 150ms;
            pointer-events: auto;
        }
        &-enter-done {
            opacity: 1;
            pointer-events: auto;
        }
        &-exit {
            opacity: 1;
        }
        &-exit-active {
            opacity: 0;
            transition: opacity 150ms;
        }
        &-exit-done {
            opacity: 0;
            pointer-events: none;
        }
    }

    @media (max-width: 1610px) {
        right: 0;
    }
`;

export const Language = styled(NavLink)`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.color6};
    padding: 7px 15px;
    width: 100%;
    font-size: 15px;

    &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.color2};
    }
`;

export const Flag = styled.img`
    width: 32px;
    height: 21px;
    object-fit: cover;
    cursor: pointer;
    vertical-align: bottom;
    ${({ small }) =>
        small &&
        css`
            width: 22px;
            height: 15px;
            margin-right: 12px;
        `}
`;

export const LanguageSelector = styled.div`
    position: relative;
`;

export const FlagContainer = styled.div`
    padding: 34px 0;
    border-bottom: 2px solid
        ${({ theme, open }) => (open ? theme.color11 : 'transparent')};
    ${({ open }) =>
        open &&
        css`
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 60px;
            }
        `}
`;
