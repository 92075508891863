import React from 'react';
import { useIntl } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';
import { Section } from '../../Section';
import { Container } from '../../Container';
import { TitleH2 } from '../../Title';
import { Text, TextWrapper } from './styled';

export const Choose = () => {
    const { formatMessage } = useIntl();
    return (
        <Section second>
            <Container>
                <ScrollAnimation animateOnce animateIn="fadeInDown" delay={0}>
                    <TitleH2>
                        {formatMessage({ id: 'choose_company_header' })}
                    </TitleH2>
                </ScrollAnimation>
                <TextWrapper>
                    <ScrollAnimation
                        animateOnce
                        animateIn="fadeInLeft"
                        delay={300}
                    >
                        <Text>
                            {formatMessage({ id: 'choose_company_textpart1' })}
                        </Text>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateOnce
                        animateIn="fadeInRight"
                        delay={600}
                    >
                        <Text>
                            {formatMessage({ id: 'choose_company_textpart2' })}
                        </Text>
                    </ScrollAnimation>
                </TextWrapper>
            </Container>
        </Section>
    );
};
