import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import { Loader } from '../Loader';
import { getDocumentation } from '../../services/actions/documentation';
import { Content, DocumentationContainer } from './styled';
import { useIntl } from 'react-intl';

export const Documentation = ({ alias }) => {
    const { formatMessage } = useIntl();
    const d = useDispatch();
    const { documentation, isLoading } = useSelector(
        state => state.documentation.documentationPages
    );
    const { locale } = useSelector(state => state.localization);

    useEffect(() => {
        if (!documentation[alias]) d(getDocumentation(alias));
    }, [d, alias, documentation]);

    useEffect(() => {
        d(getDocumentation(alias));
    }, [locale]);

    if (!Boolean(documentation[alias])) return <Loader />;

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content={
                        documentation[alias].meta_description &&
                        formatMessage({
                            id: documentation[alias].meta_description
                        })
                    }
                />
                <meta
                    name="keywords"
                    content={
                        documentation[alias].meta_keywords &&
                        formatMessage({
                            id: documentation[alias].meta_keywords
                        })
                    }
                />
                <title>
                    {formatMessage({ id: documentation[alias].meta_title })}
                </title>
            </Helmet>
            <Section>
                <Container>
                    {!isLoading && Object.keys(documentation).includes(alias) && (
                        <DocumentationContainer>
                            <Content
                                dangerouslySetInnerHTML={{
                                    __html: documentation[alias].content
                                }}
                            />
                        </DocumentationContainer>
                    )}
                </Container>
            </Section>
        </React.Fragment>
    );
};
