import React from 'react';
import {
    PageContainer,
    FillImage,
    Content,
    ContentTop,
    Logo,
    Title,
    ErrorCode,
    Text
} from './styled';
import logo from '../../../images/yuppex/logo-white.svg';
import birds from '../../../images/birds.svg';
import { InnerLink } from '../../InnerLink';

export const NotFound = () => {
    return (
        <PageContainer>
            <FillImage src={birds} alt="Birds" />
            <Content>
                <ContentTop>
                    <Logo src={logo} alt="Yuppex" />
                    <Title>Oops looks like we lost one!</Title>
                    <ErrorCode>404</ErrorCode>
                    <Text>
                        Page not found <br />
                        The page you are looking for does not exist
                    </Text>
                </ContentTop>
                <InnerLink to="/" btn>
                    Go to Home page
                </InnerLink>
            </Content>
        </PageContainer>
    );
};
