import styled from 'styled-components';
import a from 'color-alpha';

export const PrevButtonWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme }) =>
        `linear-gradient(to right, ${theme.color1} 20%, ${a(
            theme.color1,
            0
        )})`};
    width:120px;
    display: flex;
    align-items: center;
    z-index: 1; 
    box-sizing: bordborder: 2px solider-box;
    cursor: pointer;

    @media (max-width: 920px) {
        width: 100px; 
    } 
    @media (max-width: 1400px) { 
        width:60px;
    }
`;

export const PrevButtonIcon = styled.img``;

export const NextButtonWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme }) =>
        `linear-gradient(to left, ${theme.color1} 20%, ${a(theme.color1, 0)})`};
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1; 
    box-sizing: border-box;
    cursor: pointer;
    @media (max-width: 920px) {
        width: 100px; 
    }
    @media (max-width: 1400px) { 
        width:60px;
    }
`;

export const NextButtonIcon = styled.img``;
