import React from 'react';
import { Helmet } from 'react-helmet';
import { OurTeam } from '../components/OurTeamComponents';
import { useIntl } from 'react-intl';

export default () => {
    const { formatMessage } = useIntl();
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content={formatMessage({ id: 'ourteam_meta_description' })}
                />
                <meta
                    name="keywords"
                    content={formatMessage({ id: 'ourteam_meta_keywords' })}
                />
                <title>{formatMessage({ id: 'ourteam_meta_title' })}</title>
            </Helmet>
            <OurTeam />
        </React.Fragment>
    );
};
