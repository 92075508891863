import styled from 'styled-components';

export const Expand = styled.div``;
export const LinkTitle = styled.a`
    padding: 24px;
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.color6};
    cursor: pointer;
    text-decoration: none;
    display: block;
    span {
      position:relative;
      div {
        margin-bottom: -5px;
        right: auto;
        left: 100%;
        margin-left: -5px;
      }
    }
`;

export const ExpandTitle = styled.div`
    padding: 24px;
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.color6};
    cursor: pointer;
`;

export const ExpandContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.color2};
    transition: max-height 150ms;
    max-height: ${({ expand }) => (expand ? '300px' : '0')};
    overflow: hidden;

    a {
        text-decoration: none;
        display: block;
        padding: 15px;
        color: ${({ theme }) => theme.color6};
        font-size: 15px;
        &:hover {
            color: ${({ theme }) => theme.color11};
        }
    }
`;
