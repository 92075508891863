import styled from 'styled-components';

export const ButtonHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    h3 {
      font-weight: normal;
      font-size: 18px;
    }
`;
