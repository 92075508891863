import React, { useRef, useState, useEffect } from 'react';
import numeral from 'numeral';
import Radial from './Radial';

export default props => {
  const getCenterNumber = useRef();
  const getInfoNumber = useRef();
  const [centerNumber, setCenterNumber] = useState({ width: 0, height: 0 });
  const [infoNumber, setInfoNumber] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setCenterNumber({
      width: getCenterNumber.current.getBoundingClientRect().width,
      height: getCenterNumber.current.getBoundingClientRect().height,
    });

    setInfoNumber({
      width: getInfoNumber.current.getBoundingClientRect().width,
      height: getInfoNumber.current.getBoundingClientRect().height,
    });
  }, []);

  const {
    size: { width, height },
  } = props;

  const activePosition = () => {
    if (props.value === 0) return 11;
    return props.value < 0 ? 11 + props.value : 11 + props.value;
  };

  const sections = new Array(21).fill(false).fill(true, 0, activePosition());
  const percentToDeg = perc => perc * 360;
  const percentToRad = perc => degToRad(percentToDeg(perc));
  const degToRad = deg => (deg * Math.PI) / 120;

  const realHeight = width - width / 10;
  const halfWidth = width / 2;
  const halfHeight = (width - width / 10) / 2;
  const barWidth = width / 10;

  const renderSections = () => {
    const radius = width / 2;
    const sectionPerc = 1 / sections.length / 2;
    const padRad = 0.08;
    let totalPercent = 0.42;
    const chartInset = 0;

    return sections.map((active, index) => {
      const arcStartRad = percentToRad(totalPercent);
      const arcEndRad = arcStartRad + percentToRad(sectionPerc);
      totalPercent += sectionPerc;
      const startPadRad = index === 0 ? 0 : padRad / 2;
      const endPadRad = index === sections.length ? 0 : padRad / 2;
      const transform = `translate(${width / 4},${width / 4})`;
      return (
        <Radial
          /* eslint-disable-next-line react/no-array-index-key */
          key={`${index}_radial`}
          transform={transform}
          fill={active ? props.ratingColor : '#E3E5EE'}
          stroke={active ?  props.ratingColor: '#E3E5EE'}
          width={width}
          height={height}
          innerRadius={radius - chartInset - barWidth}
          outerRadius={radius - chartInset}
          startAngle={arcStartRad + startPadRad}
          endAngle={arcEndRad - endPadRad}
        />
      );
    });
  };

  return (
    <svg width={width} height={width - width / 10}>
      {renderSections()}
      <text
        ref={getCenterNumber}
        x={halfWidth - centerNumber.width / 2}
        y={halfHeight + centerNumber.height / 2}
        style={{ fontSize: '1.625rem', fontWeight: 'bold', color: '#00124A' }}
      >
        {numeral(props.value).format('0.00')}
      </text>
      <text
        x={halfWidth - 1}
        y={barWidth + infoNumber.height}
        style={{ fontSize: '0.875rem', fontWeight: 'bold', color: '#19367F' }}
      >
        0
      </text>
      <text
        ref={getInfoNumber}
        x={barWidth * 2}
        y={realHeight}
        style={{
          fontSize: '0.875rem',
          fontWeight: 'bold',
          color: '#19367F',
          display: 'block',
        }}
      >
        -10
      </text>
      <text
        x={width - barWidth * 2 - infoNumber.width}
        y={realHeight}
        style={{ fontSize: '0.875rem', fontWeight: 'bold', color: '#19367F' }}
      >
        10
      </text>
      <text
        x={barWidth + infoNumber.width}
        y={halfHeight - barWidth}
        style={{ fontSize: '0.875rem', fontWeight: 'bold', color: '#19367F' }}
      >
        -5
      </text>
      <text
        x={width - barWidth - infoNumber.width * 1.4}
        y={halfHeight - barWidth}
        style={{ fontSize: '0.875rem', fontWeight: 'bold', color: '#19367F' }}
      >
        5
      </text>
    </svg>
  );
};
