import { takeEvery, put, select } from 'redux-saga/effects';
import { Fetch } from '../../utils/fetch';
import config from '../../config/url';
import { setError } from '../actions/error';
import { request_ratings, request_ratings_success, successRatings, successUsers, request_full_user_rating, successFullUserRating } from '../actions/ratings';

export function* RatingsMiddleware() {
    yield takeEvery(request_ratings, getRatings);
    yield takeEvery(request_ratings_success, getUsersData);
    yield takeEvery(request_full_user_rating, getFullRating);
}

function* getRatings({value}) {
    const ratings = yield Fetch(`${config.apiWS}/pub/stat/table-wallets-rating?currency=USD&offset=0&limit=${value}`);
    if (ratings) {
        yield put(successRatings(ratings));
    } else {
        yield put(setError(true));
    }
}

function* getFullRating({id}) {
    const rating = yield Fetch(`${config.apiWS}/pub/stat/profit-loss-count-by-wallet-id?walletId=${id}&currency=USD`);
    if (rating) {
        yield put(successFullUserRating({...rating, id}));
    } else {
        yield put(setError(true));
    }
}

function* getUsersData() {
    const ratings = yield select(state => state.ratings.ratings);
    let userIds = [];
    ratings.map(({user_id}) => userIds.push(user_id));
    const users = yield Fetch(`${config.apiWS}/pub/user/list?ids=${userIds.join()}`);
    users ? yield put(successUsers(users)) : setError(true);
}
