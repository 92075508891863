import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
    Dropdown as DropdownWrapper,
    DropdownTitle,
    DropdownList,
    DropdownLabel,
} from './styled';

export const Dropdown = ({ title, children, withLabel }) => {
    const [isOpen, setOpen] = useState(false);

    const openHandler = () => setOpen(true);
    const closeHandler = () => setOpen(false);

    return (
        <DropdownWrapper onMouseEnter={openHandler} onMouseLeave={closeHandler}>
            <DropdownTitle open={isOpen}>{title}{withLabel && <DropdownLabel>{withLabel}</DropdownLabel>}</DropdownTitle>

            <CSSTransition in={isOpen} timeout={150} classNames="dropdown-list">
                <DropdownList>{children}</DropdownList>
            </CSSTransition>
        </DropdownWrapper>
    );
};
