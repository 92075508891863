import { request_tuition, success_tuition } from '../actions/tuition';

const initialState = {
    isLoading: false,
    tuition: []
};

export const tuitionReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_tuition:
            return {
                ...state,
                isLoading: true
            };
        case success_tuition:
            return {
                ...state,
                tuition: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
