import styled from 'styled-components';

export const Content = styled.div`
    margin-top: 40px;
    @media (max-width: 1024px) {
        text-align: justify;
        text-indent: 45px;
    }
`;

export const P = styled.p`
    font-size: 21px;
    line-height: 36px;
    text-indent: 0px;
    text-align: justify;
`;

export const Span = styled.span`
    color: ${({ theme }) => theme.color11};
`;
