import { takeEvery, put, select } from 'redux-saga/effects';
import { Fetch } from '../../utils/fetch';
import config from '../../config/url';
import {
    request_documentation_list,
    successDocumentationList,
    request_documentation,
    successDocumentation
} from '../actions/documentation';
import { setError } from '../actions/error';

export function* DocumentationMiddleware() {
    yield takeEvery(request_documentation_list, getDocumentationList);
    yield takeEvery(request_documentation, getDocumentation);
}

function* getDocumentationList() {
    const { locale } = yield select(state => state.localization);
    const list = yield Fetch(`${config.apiCMS}/v1/documentation/list`, {
        'accept-language': locale
    });
    if (list) {
        yield put(successDocumentationList(list));
    } else {
        yield put(setError(true));
    }
}

function* getDocumentation(action) {
    const { locale } = yield select(state => state.localization);
    const doc = yield Fetch(
        `${config.apiCMS}/v1/documentation/view?alias=${action.payload}`,
        {
            'accept-language': locale
        }
    );
    if (doc) {
        yield put(successDocumentation(action.payload, doc));
    } else {
        yield put(setError(true));
    }
}
