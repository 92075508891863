import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { arc } from 'd3';

export default ({innerRadius, outerRadius, startAngle, endAngle, ...props}) => {
  const arcr = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(startAngle)
    .endAngle(endAngle);

  return (
    <g transform={props.transform}>
      <path d={arcr()} {...props}/>
    </g>
  );
};