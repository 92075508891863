// local config
let config = {
    apiCMS: '{{ getenv "URL_CMS_API" "https://api-cms.yuppex.com" }}',
    apiPlatform: '{{ getenv "URL_EXCHANGE_API" "https://api.yuppex.com" }}',
    apiWS: '{{ getenv "URL_WORKSPACE_API" "https://api-ws.yuppex.com" }}',
    platformLink: '{{ getenv "URL_EXCHANGE_FRONT" "https://ex.yuppex.com" }}',
    wsLink: '{{ getenv "URL_WORKSPACE_FRONT" "https://ws.yuppex.com" }}',
    launcher: '{{ getenv "URL_LAUNCHER_FRONT" "https://launch.yuppex.com/" }}',
    youTubeApi: 'https://www.googleapis.com/youtube/v3/videos'
};

export default config;
