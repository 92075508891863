import { takeEvery, put } from 'redux-saga/effects';
import { request_assets, successAssets } from '../actions/assets';
import { Fetch } from '../../utils/fetch';
import config from '../../config/url';
import { setError } from '../actions/error';

export function* AssetsMiddleware() {
    yield takeEvery(request_assets, getAssets);
}

function* getAssets() {
    const assets = yield Fetch(`${config.apiPlatform}/v1/public/system-status`);
    if (assets) {
        const adaptedAssets = yield Object.keys(assets).map(key => ({
            ...assets[key],
            asset: key
        }));
        yield put(successAssets(adaptedAssets));
    } else {
        yield put(setError(true));
    }
}
