import { request_faq, success_faq } from '../actions/faq';

const initialState = {
    isLoading: false,
    faq: []
};

export const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_faq:
            return {
                ...state,
                isLoading: true
            };
        case success_faq:
            return {
                ...state,
                faq: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
