import React from 'react';
import { Tuition } from '../components/TuitionPageComponents';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

export default () => {
    const { formatMessage } = useIntl();
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content={formatMessage({ id: 'tuition_meta_description' })}
                />
                <meta
                    name="keywords"
                    content={formatMessage({ id: 'tuition_meta_keywords' })}
                />
                <title>{formatMessage({ id: 'tuition_meta_title' })}</title>
            </Helmet>
            <Tuition />
        </React.Fragment>
    );
};
