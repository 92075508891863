import { takeEvery, put } from 'redux-saga/effects';
import { request_volume, volumeSuccess } from '../actions/volume';
import { Fetch } from '../../utils/fetch';
import config from '../../config/url';
import { setError } from '../actions/error';

export function* VolumeMiddleware() {
    yield takeEvery(request_volume, getVolume);
}

function* getVolume() {
    const volume = yield Fetch(`${config.apiPlatform}/v1/public/volume`);
    if (volume) {
        yield put(volumeSuccess(volume));
    } else {
        yield put(setError(true));
    }
}
