import {
    request_documentation_list,
    success_documentation_list,
    request_documentation,
    success_documentation
} from '../actions/documentation';

const initialState = {
    documentationList: {
        isLoading: true,
        documentation: []
    },
    documentationPages: {
        isLoading: true,
        documentation: {}
    }
};

export const documentationReducer = (state = initialState, action) => {
    switch (action.type) {
        case request_documentation_list:
            return {
                ...state,
                documentationList: {
                    ...state.documentationList,
                    isLoading: true
                }
            };
        case success_documentation_list:
            return {
                ...state,
                documentationList: {
                    ...state.documentationList,
                    documentation: action.payload,
                    isLoading: false
                }
            };

        case request_documentation:
            return {
                ...state,
                documentationPages: {
                    ...state.documentationPages,
                    isLoading: true
                }
            };
        case success_documentation:
            return {
                ...state,
                documentationPages: {
                    ...state.documentationPages,
                    documentation: {
                        ...state.documentationPages.documentation,
                        [action.payload.alias]: action.payload.content
                    },
                    isLoading: false
                }
            };
        default:
            return state;
    }
};
