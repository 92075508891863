import styled from 'styled-components';

export const MainNewsWrapper = styled.div`
    position: relative;
    height: 600px;
`;
export const MainNewsImage = styled.div`
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        transform: translate(-50%, -50%); 
    }
`;

export const NewsTitle = styled.h1`
    margin-bottom: 85px;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    color: ${({ theme }) => theme.color1};
`;
export const NewsContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 0 20%;
`;
export const NewsDate = styled.div`
    margin-bottom: 40px;
    text-decoration: none;
    color: ${({ theme }) => theme.color3};
`;
