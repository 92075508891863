import styled from 'styled-components';
import rgba from 'color-alpha';

export const VideoWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;
    background: ${({ theme }) => rgba(theme.color5, 0.8)};
`;

export const Times = styled.div`
    position: absolute;
    top: 130px;
    right: 170px;
    color: ${({ theme }) => theme.color1};
    font-size: 40px;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color11};
    }
`;

export const VideoIframe = styled.iframe`
    border: none;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
`;
