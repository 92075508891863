import { takeEvery, put } from 'redux-saga/effects';
import {
    check_auth,
    request_user_info,
    getUserInfo,
    successUserInfo,
    failedUserInfo
} from '../actions/auth';
import { setError } from '../actions/error';
import { Fetch } from '../../utils/fetch';
import config from '../../config/url';

export function* AuthMiddleware() {
    yield takeEvery(check_auth, checkAuth);
    yield takeEvery(request_user_info, requestAuth);
}

function* checkAuth() {
    const cookies = yield document.cookie;
    if (cookies) {
        const [, token] = yield cookies.split('token=');
        if (token) {
            yield put(getUserInfo(token));
        }
    }
}

function* requestAuth(action) {
    const info = yield Fetch(`${config.apiWS}/user/index`, {
        authorization: `Bearer ${action.payload}`
    });
        yield put(successUserInfo(info));

}
