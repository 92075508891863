import styled from 'styled-components';
import a from 'color-alpha';

export const MobileMenuContainer = styled.aside`
    position: fixed;
    z-index: 5;
    top: 95px;
    right: ${({ open }) => (open ? '0' : '-120%')};
    width: 400px;
    height: calc(100vh - 90px);
    background: ${({ theme }) => theme.color1};
    transition: right 150ms;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-x: auto;
    box-shadow: 0 3px 10px ${({ theme }) => a(theme.color5, 0.16)};

    @media (max-width: 450px) {
        width: 100%;
    }

    @media (min-width: 1161px) {
        justify-content: center;
    }

    @media (min-width: 1200px) {
        display: none;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
`;

export const ButtonOffset = styled.div`
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
`;
export const InnerLinkWrapper = styled.div`
    a {
        padding: 24px;
        font-size: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme.color6};
        cursor: pointer;
        display: block;
    }
`;

export const MenuContainer = styled.nav`
    margin-bottom: 25px;
    width: 100%;

    @media (min-width: 1200px) {
        display: none;
    }
`;

export const OuterLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.color6};
`;

export const AvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 24px;
    box-sizing: border-box;
`;
export const AvatarImage = styled.img`
    width: 54px;
    height: 54px;
    overflow: hidden;
`;
export const UserName = styled.div`
    text-decoration: none;
    margin-left: 24px;
    color: ${({ theme }) => theme.color6};
`;
export const DefaultAvatar = styled.span`
    width: 54px;
    height: 54px;
    position: relative;
    background: ${({ theme }) => theme.color22};
    color: ${({ theme }) => theme.color21};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
`;
