import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getTicker } from '../../services/actions/ticker';
import { uniq } from 'lodash';

export const useAssets = () => {
    const d = useDispatch();
    const { tickers, isLoading } = useSelector(state => state.ticker);
    const [filters, setFilters] = useState([]);
    const [activeTab, setActiveTab] = useState('ALL');
    const [filteredTickers, setFilteredTickers] = useState([]);
    const [favoriteAssets, setFavoriteAssets] = useState(
        JSON.parse(localStorage.getItem('favorite_assets')) || []
    );
    const [searchValue, setSearchValue] = useState('');
    const [viewList, setViewList] = useState([]);

    useEffect(() => {
        if (!tickers.length) d(getTicker());
    }, []);

    useEffect(() => {
        if (tickers.length) {
            const f = tickers.reduce((value, ticker, idx) => {
                const [c1, c2] = ticker.name.split('/');
                if (ticker.isFrozen) return value;
                if (idx === 1) return [...value.name.split('/'), c1, c2];
                return [...value, c1, c2];
            });
            setFilters(uniq(f));
            setFilteredTickers(tickers);
            setViewList(tickers);
        }
    }, [tickers]);

    useEffect(() => {
        setViewList(filteredTickers);
    }, [filteredTickers]);

    useEffect(() => {
        if (activeTab === 'ALL') setFilteredTickers(tickers);
        else if (activeTab === 'STAR') {
            const f = tickers.filter(ticker =>
                favoriteAssets.includes(ticker.name)
            );
            setFilteredTickers(f);
        } else {
            const f = tickers.filter(ticker => {
                const [c1, c2] = ticker.name.split('/');
                if (activeTab === c1 || activeTab === c2) return ticker;
            });
            setFilteredTickers(f);
        }
        setSearchValue('');
    }, [activeTab, favoriteAssets]);

    useEffect(() => {
        const f = filteredTickers.filter(
            ticker =>
                String(ticker.name)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                String(ticker.last)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                String(ticker.percentChange)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                String(ticker.low24hr)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                String(ticker.high24hr)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                String(ticker.volume24hrUSD)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
        );
        setViewList(f);
    }, [searchValue]);

    const activeTabHandler = key => () => setActiveTab(key);

    const favoriteHandler = name => {
        const favoriteAssets = JSON.parse(
            localStorage.getItem('favorite_assets')
        );

        if (favoriteAssets) {
            if (favoriteAssets.filter(asset => asset === name).length) {
                localStorage.setItem(
                    'favorite_assets',
                    JSON.stringify(
                        favoriteAssets.filter(asset => asset !== name)
                    )
                );
                setFavoriteAssets(
                    favoriteAssets.filter(asset => asset !== name)
                );
            } else {
                localStorage.setItem(
                    'favorite_assets',
                    JSON.stringify([...favoriteAssets, name])
                );
                setFavoriteAssets([...favoriteAssets, name]);
            }
        } else {
            localStorage.setItem('favorite_assets', JSON.stringify([name]));
            setFavoriteAssets([name]);
        }
    };

    const searchHandler = e => setSearchValue(e.target.value);

    return {
        isLoading,
        tickers,
        activeTab,
        activeTabHandler,
        filters,
        searchHandler,
        searchValue,
        viewList,
        favoriteAssets,
        favoriteHandler
    };
};
