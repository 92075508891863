import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getTuition } from '../../services/actions/tuition';

export const useTuition = () => {
    const d = useDispatch();
    const { tuition, isLoading } = useSelector(state => state.tuition);
    const { locale } = useSelector(state => state.localization);
    const { formatMessage } = useIntl();
    const [showVideo, setShowVideo] = useState(false);
    const [video, setVideo] = useState('');
    const [showVideoList, setShowVideoList] = useState([]);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!tuition.length || !value) d(getTuition());
    }, [value]);

    useEffect(() => {
        const t = tuition.filter(el =>
            el.title && el.title.toLowerCase().includes(value.toLowerCase())
        );
        setShowVideoList(t);
    }, [value]);

    useEffect(() => {
        setShowVideoList(tuition);
    }, [tuition]);

    useEffect(() => {
        d(getTuition());
    }, [locale]);

    useEffect(() => {
        if (video) setShowVideo(true);
    }, [video]);

    const closeHandler = () => {
        setShowVideo(false);
        setVideo('');
    };

    const selectVideo = id => setVideo(id);

    const filterHandler = e => setValue(e.target.value);

    return {
        locale,
        formatMessage,
        closeHandler,
        setVideo,
        showVideo,
        isLoading,
        selectVideo,
        filterHandler,
        value,
        video,
        showVideoList
    };
};
