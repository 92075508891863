import styled from 'styled-components';
import a from 'color-alpha';
import { Link } from '../../InnerLink/styled';
export const Template = styled.header`
    padding: 0 40px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    @media (max-width: 1500px) {
        padding: 0 20px;
    }
`;

export const Offset = styled.div`
    width: 100px;
    height: 94px;
`;

export const Navigation = styled.nav`
    margin-left: 45px;
    display: flex;
    @media (max-width: 1500px) {
        margin-left: 20px;
    }
    @media (max-width: 1199px) {
        display: none;
    }

    @media (max-width: 1160px) {
        display: none;
    }
`;

export const ButtonOffset = styled.div`
    margin-left: 24px;
    @media (max-width: 1500px) {
        margin-left: 14px;
    }
    @media (max-width: 1199px) {
        display: none;
    }
`;

export const TemplateBlock = styled.div`
    display: flex;
    align-items: center;
    ${Link} {
        height: 40px;
    }
`;

export const Shadow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
`;

export const OuterLink = styled.a`
    color: #fff;
    text-decoration: none;
    &:hover {
        color: ${({ theme }) => theme.color11};
    }
`;

export const AvatarWrapper = styled.div`
    width: 54px;
    height: 54px;
    overflow: hidden;
    margin-left: 24px;

    @media (max-width: 1200px) {
        display: none;
    }
`;
export const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
`;
export const DefaultAvatar = styled.span`
    width: 100%;
    height: 100%;
    position: relative;
    background: ${({ theme }) => theme.color22};
    color: ${({ theme }) => theme.color21};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
`;
