import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../../Section';
import { Container } from '../../Container';
import { Title as TitleComponent } from '../../Title';
import { SubTitle } from './styled';

export const Title = () => {
    const { formatMessage } = useIntl();

    return (
        <Section>
            <Container>
                <TitleComponent>
                    {formatMessage({ id: 'content_news_tittle' })}
                </TitleComponent>
                <SubTitle>
                    {formatMessage({ id: 'content_news_header' })}
                </SubTitle>
            </Container>
        </Section>
    );
};
