import React from 'react';
import { LoaderContainer, LoaderBlock, LoaderWrapper } from './styled';

export const Loader = ({ full }) => {
    return (
        <LoaderContainer full={full}>
            <LoaderWrapper>
                <LoaderBlock />
                <LoaderBlock />
                <LoaderBlock />
                <LoaderBlock />
                <LoaderBlock />
                <LoaderBlock />
                <LoaderBlock />
                <LoaderBlock />
                <LoaderBlock />
            </LoaderWrapper>
        </LoaderContainer>
    );
};
