import styled, { css } from 'styled-components';

export const Text = styled.p`
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    margin: 10px 0;
    @media (max-width: 1400px) {
        font-size: 18px;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
    a {
        text-align: center;
        @media (max-width: 1400px) {
            font-size: 18px;
        }
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
    }
`;

export const ButtonOffset = styled.div`
    margin-right: 37px;
    @media (max-width: 700px) {
        margin-right: 0;
        margin-bottom: 37px;
    }
`;

export const ExchangeContainer = styled.div`
    height: 720px;
    position: relative;

    @media (max-width: 1850px) {
        height: 35vw;
    }

    @media (max-width: 1160px) {
        height: 400px;
    }
`;

export const ExchangeContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    top: 200px;
    margin-bottom: 100px;
    width: 42%;
    transition: left 1500ms, transform 1500ms;

    @media (min-width: 1920px) {
        top: auto;
        bottom: 100px;
    }
    @media (max-width: 1440px) {
        width: 38vw;
        font-size: 18px;
        top: 150px;
    }

    @media (max-width: 1160px) {
        position: relative;
        width: 100%;
        margin-bottom: 0;
    }

    ${({ active }) =>
        active &&
        css`
            left: 100%;
            transform: translateX(-100%);
        `}
`;

export const ExchangeContentText = styled.p`
    max-width: 630px;
    line-height: 28px;
    padding-bottom: 40px;

    @media (max-width: 1380px) {
        width: 33vw;
        padding-bottom: 10px;
    }

    @media (max-width: 1160px) {
        width: 100%;
        max-width: 100%;
        padding-bottom: 24px;
    }
`;

export const ExchangeContentButtonWrapperSlide = styled.div`
    position: absolute;
    left: 0;
    transition: left 1500ms, transform 1500ms;
    width: 217px;

    @media (max-width: 1160px) {
        width: 100%;
    }

    ${({ active }) =>
        active &&
        css`
            left: 100%;
            transform: translateX(-100%);
        `}
`;
export const ExchangeContentButtonWrapper = styled.div`
    position: relative;
    height: 90px;
`;
export const ControlWrapper = styled.div`
    height: calc(38vh - 100px);

    @media (min-width: 1920px) {
        height: calc(34vh - 100px);
    }
    @media (max-width: 1440px) {
        height: calc(32vh - 50px);
    }
    @media (max-height: 800px and max-width: 1400px) {
        height: calc(32vh - 90px);
    }
    @media (min-width: 2500px) {
        height: calc(20vh - 100px);
    }
`;

export const ExchangeContentButton = styled.button`
    width: 90px;
    height: 90px;
    border: 2px solid transparent;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    margin-right: 37px;
    cursor: pointer;
    background: ${({ theme, second }) =>
        second ? theme.color8 : theme.color11};
    color: ${({ theme }) => theme.color1};

    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background: ${({ theme, second }) =>
            second ? theme.color5 : theme.color7};
    }

    &:active {
        background: ${({ theme, second }) =>
            second ? theme.color5 : theme.color7};
    }

    &:focus {
        outline: none;
    }
`;

export const Chart = styled.img`
    position: absolute;
    left: 0;
    top: 130px;
    width: 100%;
    z-index: 1;
    transition: opacity 1000ms, transform 0ms 1000ms;
    opacity: ${({ visible }) => (visible ? '1' : '0')};

    @media (min-width: 1400px) {
        top: 150px;
    }

    @media (max-width: 1160px) {
        display: none;
    }
    @media (min-width: 1920px) {
        top: 180px;
    }
    @media (min-width: 2500px) {
        top: 50px;
    }

    ${({ active }) =>
        active &&
        css`
            left: 100%;
            transform: translateX(-100%) rotateY(180deg);
        `}
`;

export const ComputersFirst = styled.img`
    position: absolute;
    right: 0;
    width: 65%;
    bottom: 0;
    transition: opacity 800ms, right 1500ms;

    @media (min-width: 1851px) {
        width: 75%;
        right: -100px;
    }
    @media (max-width: 1400px) {
        width: 57%;
    }

    @media (max-width: 1160px) {
        position: relative;
        display: block;
        margin: -120px auto 0;
        width: 100%;
        bottom: -120px;
        ${({ active }) =>
            active &&
            css`
                display: none;
            `}
    }

    ${({ active }) =>
        active &&
        css`
            right: -1000px !important;
            opacity: 0;
        `}
`;

export const ComputersSecond = styled.img`
    position: absolute;
    left: -1000px !important;
    width: 65%;
    opacity: 0;
    bottom: 0;
    transition: opacity 1500ms, left 1500ms;

    @media (min-width: 1851px) {
        width: 75%;
        left: -100px;
    }
    @media (max-width: 1400px) {
        width: 57%;
    }
    @media (max-width: 1160px) {
        position: relative;
        display: block;
        margin: -120px auto 0;
        width: 100%;
        bottom: -120px;
        ${({ active }) =>
            !active &&
            css`
                display: none;
            `}
    }

    ${({ active }) =>
        active &&
        css`
            left: 0 !important;
            opacity: 1;
        `}
`;
