import { createGlobalStyle } from 'styled-components';
import 'normalize.css';
import './fonts/MuseoSans/stylesheet.css';
import './fonts/iconfont/style.css';
import './fonts/iconcurrency/style.css';
import 'animate.css/animate.min.css';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, sans-serif;
    font-weight: 300;
    font-size: 21px;
    background: ${({ theme }) => theme.color1};
    color: ${({ theme }) => theme.color5};
    line-height: 24px;
    overflow-x: hidden;
  }
  
  h1, h2, h3 {
      margin: 0;
  }
`;
