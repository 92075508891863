import styled, { css } from 'styled-components';

export const Hamburger = styled.div`
    margin-left: 24px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1200px) {
        display: none;
    }
`;

export const HamburgerInner = styled.div`
    width: 40px;
    height: 3px;
    background: ${({ theme }) => theme.color1};
    position: relative;
    transition: background 150ms;

    &::after,
    &::before {
        content: '';
        display: block;
        width: 40px;
        height: 3px;
        background: ${({ theme }) => theme.color1};
        position: absolute;
        transition: transform 150ms;
    }

    &::after {
        bottom: -14px;
        transform-origin: bottom right;
    }

    &::before {
        transform-origin: top right;
        top: -14px;
    }

    ${({ active }) =>
        active &&
        css`
            background: 0;
            &::after {
                transform: translateX(-10px) rotate(45deg);
            }

            &::before {
                transform: translateX(-10px) rotate(-45deg);
            }
        `}
`;
