import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import { getAssets } from '../../services/actions/assets';
import {
    FilterWrapper,
    FilterInput,
    FilterIcon,
    TableHeader,
    Table,
    TableCell,
    TableBody,
    TableBodyRow,
    TableBodyRowCell,
    TableBodyRowCellIcon,
    ErrorText
} from './styled';

export const AssetsStatus = () => {
    const d = useDispatch();
    const [value, setValue] = useState('');
    const [filtered, setFiltered] = useState([]);
    const { assets, isLoading } = useSelector(state => state.assets);
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (!assets.length) d(getAssets());
    }, [d]);

    useEffect(() => {
        setFiltered(assets);
    }, [assets]);

    useEffect(() => {
        if (assets.length) {
            const f = assets.filter(
                el =>
                    el.name.toLowerCase().includes(value.toLowerCase()) ||
                    el.asset.toLowerCase().includes(value.toLowerCase())
            );
            setFiltered(f);
        }
    }, [value]);

    const filterHandler = e => setValue(e.target.value);

    return (
        <Section>
            <Container>
                <Title>
                    {formatMessage({
                        id: 'assets_status_h1'
                    })}
                </Title>
                <FilterWrapper>
                    <FilterInput
                        type="text"
                        onChange={filterHandler}
                        placeholder={formatMessage({
                            id: 'find_placeholder'
                        })}
                    />
                    <FilterIcon className="icon-find" />
                </FilterWrapper>
                <Table>
                    <TableHeader>
                        <TableCell>
                            {formatMessage({ id: 'assets_status_asset' })}
                        </TableCell>
                        <TableCell>
                            {formatMessage({ id: 'assets_status_name' })}
                        </TableCell>
                        <TableCell>
                            {formatMessage({ id: 'assets_status_deposits' })}
                        </TableCell>
                        <TableCell>
                            {formatMessage({ id: 'assets_status_trading' })}
                        </TableCell>
                        <TableCell>
                            {formatMessage({ id: 'assets_status_withdrawals' })}
                        </TableCell>
                    </TableHeader>
                    {!assets.length && (
                        <ErrorText>
                            {formatMessage({ id: 'assets_status_empty' })}
                        </ErrorText>
                    )}
                    <TableBody>
                        {!isLoading && filtered.length
                            ? filtered.map(
                                  ({
                                      name,
                                      asset,
                                      can_withdraw,
                                      can_deposit,
                                      cat_trade
                                  }) => (
                                      <TableBodyRow>
                                          <TableBodyRowCell>
                                              <TableBodyRowCellIcon
                                                  className={`icon-${asset.toLowerCase()}`}
                                              />
                                              <span>{asset}</span>
                                          </TableBodyRowCell>
                                          <TableBodyRowCell>
                                              {name}
                                          </TableBodyRowCell>
                                          <TableBodyRowCell
                                              colored
                                              active={can_deposit}
                                          >
                                              {can_deposit
                                                  ? formatMessage({
                                                        id:
                                                            'assets_status_active'
                                                    })
                                                  : formatMessage({
                                                        id:
                                                            'assets_status_offline'
                                                    })}
                                          </TableBodyRowCell>
                                          <TableBodyRowCell
                                              colored
                                              active={cat_trade}
                                          >
                                              {cat_trade
                                                  ? formatMessage({
                                                        id:
                                                            'assets_status_active'
                                                    })
                                                  : formatMessage({
                                                        id:
                                                            'assets_status_offline'
                                                    })}
                                          </TableBodyRowCell>
                                          <TableBodyRowCell
                                              colored
                                              active={can_withdraw}
                                          >
                                              {can_withdraw
                                                  ? formatMessage({
                                                        id:
                                                            'assets_status_active'
                                                    })
                                                  : formatMessage({
                                                        id:
                                                            'assets_status_offline'
                                                    })}
                                          </TableBodyRowCell>
                                      </TableBodyRow>
                                  )
                              )
                            : assets.length && (
                                  <ErrorText>
                                      {formatMessage({
                                          id: 'assets_status_empty_search'
                                      })}
                                  </ErrorText>
                              )}
                    </TableBody>
                </Table>
            </Container>
        </Section>
    );
};
