import React from 'react';
import { useSelector } from 'react-redux';
import { Link, SimpleLink } from './styled';

export const InnerLink = ({
    title,
    to,
    children,
    onClick,
    btn,
    activeClassName,
    simpleLink
}) => {
    const { locale, defaultLocale } = useSelector(state => state.localization);
    const getToPath = to => {
        if (locale === defaultLocale) {
            return to;
        }
        const [url] = locale.split('-');
        return `/${url}` + to;
    };

    return (
        <>
            {simpleLink ? (
                <SimpleLink href={to}>{title || children}</SimpleLink>
            ) : (
                <Link
                    onClick={onClick}
                    to={getToPath(to)}
                    btn={btn}
                    activeClassName={activeClassName}
                >
                    {title || children}
                </Link>
            )}
        </>
    );
};
