import styled from 'styled-components';

export const Content = styled.div`
    margin-top: 40px;
    @media (max-width: 1024px) {
        text-align: justify;
        text-indent: 45px;
    }
`;

export const DocumentationContainer = styled.div`
    min-height: 1000px;
`;
