import styled from 'styled-components';

export const TextWrapper = styled.div`
    margin-top: 40px;
    text-indent: 0px;
    text-align: justify;
`;

export const Text = styled.p`
    text-align: center;
    margin: 0;
    font-size: 21px;
    line-height: 36px;
    text-indent: 0px;
    text-align: justify;
    @media (max-width: 1024px) {
        text-align: justify;
        text-indent: 45px;
    }
`;
