import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import { P, Span, Content } from './styled';

export const Exchange = () => {
    const { formatMessage } = useIntl();
    return (
        <Section>
            <Container>
                <Title>{formatMessage({ id: 'content_exchange_title' })}</Title>
                <Content>
                    <P>
                        <Span>
                            {formatMessage({
                                id: 'content_our_team_section_header_company'
                            })}
                        </Span>
                        &nbsp;
                        {formatMessage({ id: 'content_exchange_header' })}
                    </P>
                    <P>{formatMessage({ id: 'content_exchange_section' })}</P>
                    <P>
                        {formatMessage({ id: 'content_exchange_section_one' })}
                    </P>
                    <P>{formatMessage({ id: 'content_exchange_footer' })}</P>
                </Content>
            </Container>
        </Section>
    );
};
