import { takeEvery, put, select } from 'redux-saga/effects';
import {
    request_news,
    successNews,
    request_single_news,
    successSingleNews
} from '../actions/news';
import { Fetch } from '../../utils/fetch';
import config from '../../config/url';
import { setError } from '../actions/error';

export function* NewsMiddleware() {
    yield takeEvery(request_news, getNews);
    yield takeEvery(request_single_news, getSingleNews);
}

function* getNews(action) {
    const { locale } = select(state => state.localization);
    const news = yield Fetch(
        `${config.apiCMS}/v1/news/list?page=1&per-page=${action.payload}`,
        {
            'accept-language': locale
        }
    );
    if (news) {
        yield put(successNews(news));
    } else {
        yield put(setError(true));
    }
}

function* getSingleNews(action) {
    const { locale } = yield select(state => state.localization);
    const news = yield Fetch(
        `${config.apiCMS}/v1/news/view?alias=${action.payload}`,
        {
            'accept-language': locale
        }
    );
    yield put(successSingleNews(action.payload, news));
}
