import styled from 'styled-components';

export const PageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    background: ${({ theme }) => theme.color1};
`;

export const FillImage = styled.img`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    pointer-events: none;

    @media (max-height: 895px) {
        bottom: -100px;
    }

    @media (max-height: 785px) {
        display: none;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 100px 0;
    box-sizing: border-box;
    position: relative;
`;

export const ContentTop = styled(Content)`
    height: auto;
    padding: 0;
`;

export const Logo = styled.img`
    width: 375px;
    display: block;
    margin-bottom: 20px;
`;

export const Title = styled.h1`
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: ${({ theme }) => theme.color6};
    margin-bottom: 25px;
    text-align: center;
`;

export const ErrorCode = styled.div`
    font-size: 120px;
    line-height: 120px;
    font-weight: 300;
    color: ${({ theme }) => theme.color3};
`;

export const Text = styled.p`
    text-align: center;
    font-size: 24px;
    line-height: 36px;
`;
