import styled from 'styled-components';
import rgba from 'color-alpha';

export const Text = styled.p`
    margin: 40px auto 0;
    font-size: 24px;
    line-height: 36px;
    max-width: 965px;
    text-indent: 0px;
    text-align: justify;
`;
export const SelectedText = styled.span`
    color: ${({ theme }) => theme.color11};
`;

export const InputWrapper = styled.div`
    margin: 35px auto 0;
    position: relative;
    max-width: 965px;
`;

export const Input = styled.input`
    line-height: 40px;
    border: 1px solid ${({ theme }) => theme.color3};
    font-weight: 300;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    &::placeholder {
        color: ${({ theme }) => theme.color3};
        opacity: 1;
    }
`;

export const InputIcon = styled.i`
    position: absolute;
    right: 15px;
    top: 9px;
    color: ${({ theme }) => theme.color3};
`;

export const VideoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 24px;
    margin-top: 50px;

    @media (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 720px) {
        grid-template-columns: 1fr;
    }
`;

export const Duration = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color17};
`;

export const Live = styled.div`
    color: ${({ theme }) => theme.color16};
`;

export const LiveBull = styled.span`
    font-size: 30px;
    color: ${({ theme }) => theme.color16};
`;

export const VideoWrapper = styled.div`
    position: relative;
    min-height: 220px;
    overflow: hidden;
    background-image: ${({ src }) => `url(${src})`};
    background-position: center center;
    background-size: 100%;
    padding: 18px 22px;
    transition: background-size 150ms;
    cursor: pointer;
    display: flex;
    @media (max-width: 720px) {
        min-width: 100%;
        box-sizing: border-box;
    }

    &:hover {
        background-size: 120%;
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${({ theme }) => rgba(theme.color5, 0.8)};
        pointer-events: none;
    }
`;

export const VideoContent = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export const VideoTitle = styled.div`
    font-size: 24px;
    color: ${({ theme }) => theme.color1};
`;

export const VideoInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const VideoViewWrapper = styled.div`
    color: ${({ theme }) => theme.color1};
    display: flex;
`;

export const VideoViewIcon = styled.i`
    margin-right: 5px;
`;
export const VideoViewValue = styled.div``;

export const Error = styled.div`
    text-align: center;
    font-size: 40px;
    line-height: 50px;
    color: #4a5e9c;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
`;
