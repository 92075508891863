import styled, { css } from 'styled-components';
import rgba from 'color-alpha';

export const Text = styled.p`
    font-size: 21px;
    line-height: 36px;
    text-indent: 0px;
    text-align: justify;
    @media (max-width: 1024px) {
        text-align: justify;
        text-indent: 45px;
    }
`;

export const TitleH2 = styled.h2`
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    line-height: 50px;
    color: ${({ theme }) => theme.color11};

    @media (max-width: 560px) {
        font-size: 40px;
    }
`;

export const TitleH3 = styled.h3`
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    color: ${({ theme }) => theme.color11};
`;

export const Content = styled.div`
    margin: 60px 0 0 0;
`;

export const CheckList = styled.div`
    margin: 27px 12px 60px 12px;
`;

export const CheckListItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const CheckDot = styled.div`
    width: 30px;
    height: 30px;
    background: ${({ theme }) => theme.color11};
    margin-right: 12px;
    position: relative;
    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 2px;
        height: 10px;
        background: ${({ theme }) => theme.color1};
    }
    &:after {
        transform: rotate(-45deg);
        top: 12px;
        left: 9px;
    }
    &:before {
        transform: rotate(35deg);
        height: 17px;
        top: 6px;
        left: 17px;
    }
`;
export const CheckText = styled.div`
    font-size: 21px;
`;

export const StepWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: ${({ theme }) => theme.color11};
        position: absolute;
        top: 50%;
        z-index: 1;
    }

    @media (max-width: 1000px) {
        flex-direction: column;

        &:before {
            width: 2px;
            height: 100%;
            top: auto;
            left: 50%;
        }
    }
`;

export const StepItem = styled.div`
    padding: 16px 24px;
    background: linear-gradient(to right, #f8c932 0%,#ecca7b 40%,#c59535 60%,#eec95b 100%);
    display: flex;
    flex-basis: 255px;
    align-items: center;
    min-height: 75px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    @media (max-width: 1000px) {
        margin-right: 0;
        margin-bottom: 40px;
        width: 100%;
        justify-content: center;
        flex-basis: auto;
    }
`;

export const StepNumber = styled.div`
    font-size: 40px;
    color: ${({ theme }) => theme.color4};
    margin-right: 10px;
`;

export const StepText = styled.div`
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
`;

export const ListingFrom = styled.div`
    border: 1px solid ${({ theme }) => theme.color4};
    padding: 20px;
    margin-bottom: 47px;
`;

export const ListingPanel = styled.div`
    border: 1px solid ${({ theme }) => theme.color4};
    padding: 16px;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const ListingPanelHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ListingPanelHeaderTitle = styled.h3`
    font-size: 36px;
    font-weight: 700;
    line-height: 21px;
    color: ${({ theme }) => theme.color5};
    @media (max-width: 560px) {
        font-size: 25px;
    }
`;
export const ListingPanelHeaderArrow = styled.i`
    font-size: 21px;
`;
export const ListingPanelInputsGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    margin-top: 20px;
    align-items: flex-end;

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
`;

export const ListingPanelInputsGroupDuo = styled.div`
    display: grid;
    grid-template-columns: 370px 1fr;
    grid-column-gap: 40px;
    align-items: flex-end;
    margin-top: 20px;
    @media (max-width: 860px) {
        grid-template-columns: 1fr;
    }
`;

export const ListingPanelInputWrapper = styled.div`
    margin-top: ${({ offset }) => offset && '20px'};
    @media (max-width: 1000px) {
        margin-top: 20px;
    }
`;

export const ListingPanelInputLabel = styled.div`
    font-size: 18px;
    line-height: 24px;

    ${({ sub }) =>
        sub &&
        css`
        &:after {
            content: "${sub}";
            display: block;
            font-size: 14px;
            line-height: 18px;
        }
    `}
`;

export const ListingPanelInput = styled.div`
    padding: 5px 10px;
    background: ${({ theme }) => theme.color2};
    line-height: 24px;
    color: ${({ theme }) => rgba(theme.color5, 0.5)};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ListingPanelTextareaWrapper = styled.div`
    margin-top: 13px;
`;

export const ListingPanelTextarea = styled.div`
    padding: 5px 10px;
    background: ${({ theme }) => theme.color2};
    line-height: 24px;
    color: ${({ theme }) => rgba(theme.color5, 0.5)};
    min-height: 100px;
`;

export const ListingPanelAddButton = styled.div`
    width: 35px;
    height: 35px;
    background: ${({ theme }) => theme.color8};
    margin-top: 17px;
    position: relative;
    &:after,
    &:before {
        content: '';
        display: block;
        width: 1px;
        height: 17px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: ${({ theme }) => theme.color1};
    }
    &:before {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    &:after {
        transform: translate(-50%, -50%);
    }
`;

export const ListingPanelCheckboxes = styled.div`
    margin: 10px 0;
`;

export const ListingPanelCheckboxItem = styled.div`
    display: flex;
    align-items: center;
`;

export const ListingPanelCheckbox = styled.div`
    width: 16px;
    height: 16px;
    border: 1px solid
        ${({ theme, active }) => (active ? theme.color4 : theme.color5)};
    margin-right: 25px;

    ${({ active }) =>
        active &&
        css`
            position: relative;
            &:after,
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 2px;
                height: 10px;
                background: ${({ theme }) => theme.color4};
            }
            &:after {
                transform: rotate(-45deg);
                top: 5px;
                left: 4px;
            }
            &:before {
                transform: rotate(35deg);
                height: 13px;
                top: 2px;
                left: 11px;
            }
        `}
`;

export const ListingPanelCheckboxText = styled.div`
    font-size: 18px;
    line-height: 24px;
`;

export const ListingPanelInputIcon = styled.i`
    font-size: 12px;
`;

export const ListingPanelLabelSub = styled.span`
    font-size: 14px;
    line-height: 18px;
`;

export const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 95px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;
export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1000px) {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;
export const InfoLabel = styled.div`
    font-size: 21px;
    line-height: 36px;
`;
export const InfoValue = styled.a`
    font-size: 21px;
    line-height: 36px;
    text-decoration: none;
    color: ${({ theme }) => theme.color11};
    transition: color 150ms;
    &:hover {
        color: ${({ theme }) => theme.color9};
    }
`;
