import styled from 'styled-components';
import Slider from 'react-slick';
import { Container } from '../../../Container';

export const SliderContainer = styled.div`
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 150px;
    transition: transform 500ms;
    transform-origin: bottom center;
    transform: scaleY(${({ show }) => (show ? '0' : '1')});
    background: ${({ theme }) => theme.color1};
    @media (min-height: 1201px) {
        position: relative;
    }

    @media (max-height: 1044px) {
        position: relative;
    }
 
    ${Container} {
        @media (max-width: 768px) {
            padding: 0 24px;
        }
    }
`;

export const CustomSlider = styled(Slider)`
    padding-top: 10px;
    overflow: hidden;
`;
