import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import { Date, Image, Content, SectionStyled } from './styled';
import { getSingleNews } from '../../services/actions/news';
import { Loader } from '../Loader';
import { InnerLink } from '../InnerLink';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const SingleNews = ({ alias }) => {
    const d = useDispatch();
    const { news, isLoading } = useSelector(state => state.news.singleNews);
    const { locale } = useSelector(state => state.localization);
    const { formatMessage } = useIntl();
    useEffect(() => {
        if (!news[alias]) d(getSingleNews(alias));
    }, [d, alias]);

    useEffect(() => {
        d(getSingleNews(alias));
    }, [locale]);

    if (isLoading && !Object.keys(news).includes(alias)) return <Loader />;

    return (
        <React.Fragment>
            {Object.keys(news).includes(alias) && (
                <React.Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{news[alias].meta_title}</title>
                        <meta
                            name="description"
                            content={news[alias].meta_description}
                        />
                        <meta
                            name="keywords"
                            content={news[alias].meta_keywords}
                        />
                    </Helmet>
                    <SectionStyled>
                        <React.Fragment>
                            <Container>
                                <Title>{news[alias].title}</Title>
                                <Date>
                                    {moment
                                        .unix(news[alias].created_at)
                                        .format('MMM DD, YYYY | HH:mm')}
                                </Date>
                            </Container>
                            <Image>
                                <LazyLoadImage
                                    src={news[alias].image}
                                    alt={news[alias].title}
                                    effect="opacity"
                                    delayTime={500}
                                />
                            </Image>
                            <Container>
                                <Content
                                    dangerouslySetInnerHTML={{
                                        __html: news[alias].content
                                    }}
                                />
                                <InnerLink btn to="/news">
                                    {formatMessage({
                                        id: 'content_news_button_back'
                                    })}
                                </InnerLink>
                            </Container>
                        </React.Fragment>
                    </SectionStyled>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
