import styled, { css } from 'styled-components';

export const Section = styled.section`
    padding: ${({ isRating }) => (isRating ? '0' : '70px 0')};
    position: relative;
    overflow: hidden;
    background: ${({ theme, second }) =>
        second ? theme.color2 : theme.color1};

    ${({ clearTopOffset }) =>
        clearTopOffset &&
        css`
            padding-top: 0;
        `}

    ${({ clearBottomOffset }) =>
        clearBottomOffset &&
        css`
            padding-bottom: 0;
        `}
           @media (max-width: 1024px) {
        padding: 30px 0;
    }
`;
