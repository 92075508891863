export const check_auth = 'auth.check';
export const request_user_info = 'user-info.request';
export const success_user_info = 'user-info.success';
export const failed_user_info = 'user-info.failed';

export const checkAuth = () => ({
    type: check_auth
});

export const getUserInfo = token => ({
    type: request_user_info,
    payload: token
});

export const successUserInfo = info => ({
    type: success_user_info,
    payload: info
});

export const failedUserInfo = () => ({
    type: failed_user_info
});
