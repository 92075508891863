import styled from 'styled-components';
import { Section } from '../Section';

export const Date = styled.div`
    line-height: 40px;
    text-align: center;
    color: ${({ theme }) => theme.color4};
    margin-top: 52px;
`;

export const Image = styled.div`
    img {
        display: block;
        margin-top: 60px;
        width: 100%;
        object-fit: cover;
        max-height: 700px;
    }
`;

export const Content = styled.div`
    margin-top: 120px;
    margin-bottom: 48px;
    table:last-child {
        tbody {
            a {
                text-decoration: none;
                color: ${({ theme }) => theme.color1};
                padding: 15px 25px;
                background: ${({ theme }) => theme.color8};
                font-size: 18px;
                -webkit-transition: background 150ms;
                transition: background 150ms;
                font-weight: inherit;
                white-space: nowrap;
                text-align: center;
                &:hover {
                  background: ${({ theme }) => theme.color7};
                }
            }
        }
    }
    a {
        color: ${({ theme }) => theme.color11};
        text-decoration: none;
        transition: color 150ms;
        &:hover {
            color: ${({ theme }) => theme.color10};
        }
    }
`;
export const SectionStyled = styled(Section)`
    @media (max-width: 768px) {
        overflow: scroll;
    }
`;
