export const request_documentation_list = 'documentation-list.request';
export const success_documentation_list = 'documentation-list.success';

export const request_documentation = 'documentation.request';
export const success_documentation = 'documentation.success';

export const getDocumentationList = () => ({
    type: request_documentation_list
});

export const successDocumentationList = list => ({
    type: success_documentation_list,
    payload: list
});

export const getDocumentation = alias => ({
    type: request_documentation,
    payload: alias
});

export const successDocumentation = (alias, content) => ({
    type: success_documentation,
    payload: {
        alias,
        content
    }
});
