import React from 'react';
import { Helmet } from 'react-helmet';
import { CustomizableInterface } from '../components/CustomizablePageComponents/CustomizableInterface';
import { CustomizableContent } from '../components/CustomizablePageComponents/CustomizableContent';
import { useIntl } from 'react-intl';

export default () => {
    const { formatMessage } = useIntl();
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content={formatMessage({
                        id: 'customizable_interface_meta_description'
                    })}
                />
                <meta
                    name="keywords"
                    content={formatMessage({
                        id: 'customizable_interface_meta_keywords'
                    })}
                />
                <title>
                    {formatMessage({ id: 'customizable_interface_meta_title' })}
                </title>
            </Helmet>
            <CustomizableInterface />
            <CustomizableContent />
        </React.Fragment>
    );
};
