import { takeEvery, put } from 'redux-saga/effects';
import { Fetch } from '../../utils/fetch';
import { request_press, successPress } from '../actions/press';
import { setError } from '../actions/error';
import config from '../../config/url';

export function* PressMiddleware() {
    yield takeEvery(request_press, getPress);
}

function* getPress() {
    const press = yield Fetch(`${config.apiCMS}/v1/press/list`);
    if (press) {
        yield put(successPress(press));
    } else {
        yield put(setError(true));
    }
}
