import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import { Section } from '../../Section';
import { Container } from '../../Container';
import { TitleH2 } from '../../Title';
import { Button } from '../../Button';
import {
    Text,
    ButtonOffset,
    ButtonsContainer,
    ExchangeContainer,
    ExchangeContent,
    ExchangeContentButton,
    ExchangeContentButtonWrapper,
    ExchangeContentText,
    Chart,
    ComputersFirst,
    ComputersSecond,
    ExchangeContentButtonWrapperSlide,
    ControlWrapper
} from './styled';
import people from '../../../images/yuppex/people.png';
import computers1Image from '../../../images/yuppex/computers1.png';
import computers2Image from '../../../images/yuppex/computers2.png';
import config from '../../../config/url';

export const Exchange = () => {
    const { error, userInfo } = useSelector(state => state.auth);
    const [activeFirst, setActiveFirst] = useState(true);
    const [bgImageVisible, setBgImageVisible] = useState(true);
    const { formatMessage } = useIntl();
    const changeActiveFirst = value => () => {
        setActiveFirst(value);
        setBgImageVisible(false);
        setTimeout(() => {
            setBgImageVisible(true);
        }, 1500);
    };

    return (
        <Section clearBottomOffset>
            <Chart
                active={!activeFirst}
                visible={bgImageVisible}
                src={people}
                alt="Chart"
            />
            <Container>
                <ControlWrapper>
                <TitleH2>
                    {formatMessage({ id: 'exchange_trade_header' })}
                </TitleH2>
                <Text>
                    {formatMessage({ id: 'exchange_trade_header_text' })}
                </Text>
                {(error || !Object.keys(userInfo).length) && (
                    <ButtonsContainer>
                        <ButtonOffset>
                            <ScrollAnimation
                                animateOnce
                                animateIn="fadeInLeft"
                                delay={0}
                            >
                                <Button
                                    href={formatMessage({
                                        id:
                                            'label_home_page_themes_part_button_registration_url'
                                    })}                                    
                                    third
                                >
                                    {formatMessage({
                                        id:
                                            'label_home_page_themes_part_button_registration'
                                    })}
                                </Button>
                            </ScrollAnimation>
                        </ButtonOffset>
                        <ScrollAnimation
                            animateOnce
                            animateIn="fadeInRight"
                            delay={0}
                        >
                            <Button
                                href={formatMessage({
                                    id:
                                        'label_home_page_themes_part_button_login_url'
                                })}                                
                            >
                                {formatMessage({
                                    id:
                                        'label_home_page_themes_part_button_login'
                                })}
                            </Button>
                        </ScrollAnimation>
                    </ButtonsContainer>
                )}
                </ControlWrapper>
                <ComputersSecond
                    active={!activeFirst}
                    src={computers2Image}
                    alt="Computers"
                />
                <ComputersFirst
                    active={!activeFirst}
                    src={computers1Image}
                    alt="Computers"
                />
                <ScrollAnimation animateOnce animateIn="fadeInLeft" delay={300}>
                    <ExchangeContainer>
                        <ExchangeContent active={!activeFirst}>
                            <ExchangeContentText>
                                {formatMessage({ id: 'exchange_trade_text' })}
                            </ExchangeContentText>
                            <ExchangeContentButtonWrapper active={!activeFirst}>
                                <ExchangeContentButtonWrapperSlide
                                    active={!activeFirst}
                                >
                                    <ExchangeContentButton
                                        active={!activeFirst}
                                        onClick={changeActiveFirst(false)}
                                    >
                                        {`${formatMessage({
                                            id: 'exchange_trade_button_dark'
                                        })} ${formatMessage({
                                            id: 'exchange_trade_button_theme'
                                        })}`}
                                    </ExchangeContentButton>
                                    <ExchangeContentButton
                                        second
                                        active={activeFirst}
                                        onClick={changeActiveFirst(true)}
                                    >
                                        {`${formatMessage({
                                            id: 'exchange_trade_button_light'
                                        })} ${formatMessage({
                                            id: 'exchange_trade_button_theme'
                                        })}`}
                                    </ExchangeContentButton>
                                </ExchangeContentButtonWrapperSlide>
                            </ExchangeContentButtonWrapper>
                        </ExchangeContent>
                    </ExchangeContainer>
                </ScrollAnimation>
            </Container>
        </Section>
    );
};
