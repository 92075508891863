export const request_volume = 'volume.request';
export const request_volume_success = 'volume.request.success';
export const request_volume_failed = 'volume.request.failed';

export const getVolume = () => ({
    type: request_volume
});

export const volumeSuccess = volume => ({
    type: request_volume_success,
    payload: volume
});

export const failedVolume = () => ({
    type: request_volume_failed
});
