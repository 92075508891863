import React from 'react';
import copy from 'copy-to-clipboard';
import { useIntl } from 'react-intl';
import { CopyIcon, SelectedText } from './styled';

export const Copy = ({ value, white }) => {
    const { formatMessage } = useIntl();
    const [copyIsShow, setCopyIsShow] = React.useState(false);

    const copyHandler = () => {
        setCopyIsShow(true);
        copy(value);
        setTimeout(() => {
            setCopyIsShow(false);
        }, 1000);
    };
    return (
        <CopyIcon
            className="icon-copy"
            onClick={() => copyHandler()}
            white={white}
        >
            <SelectedText isShow={copyIsShow} white={white}>
                {formatMessage({ id: 'footer_donate_tooltip_copy' })}
            </SelectedText>
        </CopyIcon>
    );
};
