import React from 'react';
import { useIntl } from 'react-intl';
import { TableWrap, Line, LineTitle, LineValue } from '../../styled';


const Table = ({data, highlighted}) => {
    const { formatMessage } = useIntl();
    return (
        <TableWrap>
            {data.map(({title, value}) => 
                <Line>
                    <LineTitle>{formatMessage({id: title})}</LineTitle>
                    <LineValue highlighted={highlighted} isPositive={+value > 0} isNegative={+value < 0}>{value}</LineValue>
                </Line>
                )
            }            
        </TableWrap>
        
    );
};

export default Table;