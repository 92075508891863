import styled from 'styled-components';

export const NewsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 38px;
    align-items: center;
    justify-content: center;

    @media (max-width: 1500px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 950px) {
        grid-template-columns: 1fr;
    }
`;

export const NewsImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    transition: transform 150ms, filter 150ms;
    filter: brightness(50%);
    object-fit: cover;

    &:hover {
        transform: scale(1.2);
        filter: brightness(100%);
    }
`;

export const NewsCard = styled.div`
    position: relative;
    height: 500px;
    overflow: hidden;
`;

export const NewsCardContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 12px 25px;
    pointer-events: none;
    box-sizing: border-box;
`;

export const NewsTitle = styled.h2`
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.color1};
`;

export const NewsDate = styled.div`
    text-align: right;
    color: ${({ theme }) => theme.color3};
    margin-top: 5px;
`;

export const Button = styled.button`
    display: block;
    padding: 15px 25px;
    background: ${({ theme, second }) =>
        second ? theme.color8 : theme.color11};
    text-decoration: none; 
    color: ${({ theme }) => theme.color1};
    font-size: 18px;
    font-weight: 500;
    transition: background 150ms;
    white-space: nowrap;
    border: none;
    margin: 90px auto 0;
    cursor: pointer;

    &:hover {
        background: ${({ theme, second }) =>
            second ? theme.color7 : theme.color10};
    }
    &:active {
        background: ${({ theme, second }) =>
            second ? theme.color6 : theme.color9};
    }

    &:disabled {
        background: ${({ theme }) => theme.color3};
    }
`;
