export const request_press = 'press.request';
export const success_press = 'press.success';
export const failed_press = 'press.failed';

export const getPress = () => ({
    type: request_press
});

export const successPress = press => ({
    type: success_press,
    payload: press
});

export const failedPress = () => ({
    type: failed_press
});
