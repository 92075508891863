import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

export const LocalizationRouter = ({ children }) => {
    const { locale, defaultLocale } = useSelector(state => state.localization);

    const getRedirectPath = ({ pathname }) => {
        const [, path] = pathname.split(/^\/[a-z]{2}/);
        return path;
    };

    return (
        <BrowserRouter>
            <Route path="/:lang([a-z]{2})">
                {({ match, location }) => {
                    if (locale === defaultLocale && match) {
                        return <Redirect to={getRedirectPath(location)} />;
                    }
                    return children;
                }}
            </Route>
        </BrowserRouter>
    );
};
