export const request_tuition = 'tuition.request';
export const success_tuition = 'tuition.success';
export const failed_tuition = 'tuition.failed';

export const getTuition = () => ({
    type: request_tuition
});

export const successTuition = tuition => ({
    type: success_tuition,
    payload: tuition
});

export const failedTuition = () => ({
    type: failed_tuition
});
