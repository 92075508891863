import styled, { css } from 'styled-components';

export const Button = styled.a`
    display: block;
    padding: ${({small}) => small ? '0.25rem 0' : '15px 25px'};
    background: ${({ theme, second }) =>
        second ? 'none' : theme.color11};
    text-decoration: none; 
    color: ${({ theme }) => theme.color1};
    font-size: ${({small}) => small ? '1.13rem' : '18px'};
    transition: background 150ms;
    font-weight: ${({small}) => small ? '300' : 'inherit'};    
    white-space: nowrap;
    text-align: center;
    ${({second}) => second && css`
        border: 1px solid ${({ theme }) => theme.color11};
    `}
    
    ${({small}) => small && css`
        min-width: 13.75rem;
    `}
    ${({third, theme}) => third && css`
        background: ${theme.color8};
        border-color: ${theme.color8};
        &:hover {        
          background: ${theme.color5};
        }
    `}
    &:hover {        
        background: ${({ theme, third }) => third ?  theme.color26 : theme.color7};
    }
    &:active {
        background: ${({ theme, second }) =>
            second ? 'none' : theme.color11};
    }

    &:disabled {
        background: ${({ theme }) => theme.color3};
    }
    
    @media (max-width: 1400px) {
        font-size: ${({ theme }) => theme.fontMd};
        padding:10px 25px;
    }
    ${({ fluid }) =>
        fluid &&
        css`
            width: 100%;
            box-sizing: border-box;
            text-align: center;
        `}

    ${({ width }) =>
        width &&
        css`
            min-width: ${width}px;
            text-align: center;
            box-sizing: border-box;
        `}
    
     ${({ custom }) =>
         custom &&
         css`
             width: 200px;
             text-align: center;
             box-sizing: border-box;
             margin: 47px auto 0;
         `}
         
          @media (max-width: 1024px) {
        font-size:21px;
    }   
`;
