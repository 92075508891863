import styled, { css } from 'styled-components';

export const TableWrapper = styled.table`
    background: ${({ theme }) => theme.color23};
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.color23};
    border-image: initial;
    width: calc(100% - 2px);
    border-collapse: collapse;
    margin-top: 20px;
`;
export const TableHeader = styled.thead`
    width: 100%;
    height: 2rem;
    background-color: ${({ theme }) => theme.color24};
    padding: 0 2rem;
`;
export const TableContainer = styled.div`
    width: 100%;
    overflow: auto;
`;
export const TableRow = styled.tr`
    border: 1px solid ${({ theme }) => theme.color24};
    cursor: pointer;
`;
export const TableCell = styled.td`
    font-size: 0.825rem;
    font-weight: bold;
    padding-left: ${({ first }) => first ? 0 : '0.5rem'};;
    background: ${({ theme, isInner }) => isInner && theme.color1};
    padding-right: 0.5rem;
    color: ${({ theme }) => theme.color26};
    text-align: ${({ center, right }) => {
        if (center) return 'center';
        if (right) return 'right';
        return 'left';
        }};
    white-space: pre;
`;
export const TableBody = styled.tbody``;
export const User = styled.div`
    display: flex;    
`;
export const TableWrap = styled.div`
    border: 1px solid ${({ theme }) => theme.color24};
`;

export const Line = styled.div`
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;    
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    color: ${({ theme }) => theme.color26};
    flex: 1 0 0;
    padding: 0 0.75rem;
    overflow: hidden;
    transition: width 0.3s ease 0s, min-width, padding, opacity;
    &:nth-child(even) {
        background: ${({ theme }) => theme.color24};
    }    
`;

export const LineTitle = styled.div`
    flex: 1;
`;

export const Speed = styled.div`
    min-width: 12.75rem;
`;

export const ButtonsHolder = styled.div`
    padding: 0 1.25rem 1.25rem;
    display: flex;    
    justify-content: space-between;
    cursor: default;
`;

export const ButtonsBlock = styled.div`    
    display: flex;    
    a:first-child {
        margin-right: 2rem;
    }
`;

export const TwoTables = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Inner = styled.div`
    padding: 1.25rem;
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 2.6fr 1fr;
    grid-column-gap: 1.25rem;
    cursor: default;
    .floating-tooltip {
        position: absolute;
        z-index: 7;
        border: 1px solid ${({ theme }) => theme.color11};
        background: ${({ theme }) => theme.color1};
        padding: 4px;
        line-height: 1;
    }
    .tv-lightweight-charts {
        position: relative;
    }
`;

export const LineValue = styled.div`
    display: flex;  
    ${({isPositive,  highlighted}) =>  highlighted && isPositive && css`
        color: ${({ theme }) => theme.color28};
    `}    
    ${({isNegative,  highlighted}) =>  highlighted && isNegative && css`
        color: ${({ theme }) => theme.color27};
    `}  
`;
export const UserData = styled.div`
    display: flex;
    margin-left: 1rem;
`;
export const AvatarImg = styled.img`
    width: 2.5rem;
    height: 2.5rem;
`;

export const ChartWrapper = styled.div`
    pointer-events: none;
    text-align: center;
    width: 250px;
    height: 50px;
    position:relative;
    overflow: hidden;
    margin: 0 auto;
    .tv-lightweight-charts {        
        position: absolute;
        right: 0;        
    }    
    .floating-tooltip {
        position: absolute;
        z-index: 7;
        border: 1px solid 
    }
`;

export const RatingCell = styled.div`
    padding-left: 2rem;
    position: relative;
`;
export const DefaultAvatar = styled.span`
     width: 2.5rem;
    height: 2.5rem;
    position: relative;
    background: ${({ theme }) => theme.color22};
    color: ${({ theme }) => theme.color21};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
`;
export const ToggleArrow = styled.i`
    color: ${({ theme }) => theme.color25};
    left: 0.875rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.625rem;
`;
export const TableHeaderCell = styled.th`
    font-size: 0.825rem;
    font-weight: bold;
    color: ${({ theme }) => theme.color25};
    white-space: nowrap;
    text-align: ${({ center, right }) => {
        if (center) return 'center';
        if (right) return 'right';
        return 'left';
        }};
    padding-left: ${({ first }) => first ? '2rem' : '0.5rem'};
    padding-right: ${({ last }) => last ? '2rem' : '0.5rem'};
    ${({nickname}) => nickname && css`
        padding-left: 4rem;
    `}
`;
