import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { getTicker } from '../../../../services/actions/ticker';
import { NextButton, PrevButton } from '../CryptoCarouselButton';

export const useCryptoCarousel = () => {
    const d = useDispatch();
    const { tickers, isLoading } = useSelector(state => state.ticker);
    useEffect(() => {
        if (!tickers.length) d(getTicker());
    }, [d, tickers.length]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        variableWidth: true,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        autoplay: true,
        pauseOnHover: true,
        centerMode: true,
        nextArrow: <NextButton />,
        prevArrow: <PrevButton />
    };
    return {
        settings,
        tickers,
        isLoading
    };
};
