import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Image,
    Wrapper,
    TextWrap,
    Title,
    Text,
    Content,
    ImageHolder,
    ButtonHolder,
    IconClose,
} from './styled';
import cookies from './cookies.svg';
import { Button } from '../Button';

export const Cookies = ({ value, white }) => {
    const { formatMessage } = useIntl();

    const setCookie = (name, value, days) => {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    };
    const getCookie = name => {
        let nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    };
    const [isShow, setShow] = useState(getCookie('privacy_policy'));
    const handleClose = () => {
        setCookie('privacy_policy', true, 1000);
        setShow(true);
    };

    return (
        <>
            {!isShow && (
                <Wrapper>
                    <Content>
                        <ImageHolder>
                            <Image src={cookies} />
                        </ImageHolder>
                        <TextWrap>
                            <Title>
                                {formatMessage({
                                    id:
                                        'cookies_policy_notification_desc_url_1_text'
                                })}
                                <IconClose className="icon-plus" onClick={handleClose} />
                            </Title>
                            <Text>
                                {formatMessage(
                                    { id: 'cookies_policy_notification_desc' },
                                    {
                                        cookies_policy_notification_desc_url_1_text: (
                                            <a href={formatMessage({id: 'forcookies_policy_notification_desc_url_1'})}>
                                                {formatMessage({id: 'cookies_policy_notification_desc_url_1_text'})}
                                            </a>
                                        ),
                                        cookies_policy_notification_desc_url_2_text: (
                                            <a href={formatMessage({id: 'cookies_policy_notification_desc_url_2'})}>
                                                {formatMessage({id: 'cookies_policy_notification_desc_url_2_text'})}
                                            </a>
                                        )
                                    }
                                )}
                            </Text>
                        </TextWrap>
                        <ButtonHolder onClick={handleClose}>
                            <Button>
                                {formatMessage({ id: 'accept_button' })}
                            </Button>
                        </ButtonHolder>
                    </Content>
                </Wrapper>
            )}
        </>
    );
};
