import React from 'react';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import {
    Input,
    InputIcon,
    InputWrapper,
    SelectedText,
    Text,
    VideoContainer,
    Live,
    LiveBull,
    Duration,
    VideoWrapper,
    VideoContent,
    VideoTitle,
    VideoInfo,
    VideoViewWrapper,
    VideoViewIcon,
    VideoViewValue,
    Error,
    ButtonWrapper
} from './styled';
import { useTuition } from './useTuition';
import { Loader } from '../Loader';
import { Video } from './Video';
import { Button } from '../Button';
import config from '../../config/url';

export const Tuition = () => {
    const {
        closeHandler,
        filterHandler,
        isLoading,
        showVideo,
        video,
        formatMessage,
        selectVideo,
        value,
        showVideoList
    } = useTuition();

    if (isLoading) return <Loader />;
    return (
        <Section>
            <Container>
                <Title>
                    {formatMessage({
                        id: 'tuition_title'
                    })}
                </Title>
                <Text>
                    <SelectedText>
                        {formatMessage({
                            id: 'content_our_team_section_header_company'
                        })}
                    </SelectedText>
                    &nbsp;
                    {formatMessage({ id: 'tuition_section' })}
                </Text>
                <InputWrapper>
                    <Input
                        type="text"
                        placeholder={formatMessage({ id: 'find_placeholder' })}
                        onChange={filterHandler}
                        value={value}
                    />
                    <InputIcon className="icon-find" />
                </InputWrapper>

                <VideoContainer>
                    {showVideoList.map(video => (
                        <VideoWrapper
                            key={video.code}
                            src={video.image}
                            onClick={() => selectVideo(video.code)}
                        >
                            <VideoContent>
                                <VideoTitle>{video.title}</VideoTitle>
                                <VideoInfo>
                                    <VideoViewWrapper>
                                        <VideoViewIcon className="icon-star-empty" />
                                        <VideoViewValue>
                                            {video.viewCount}
                                        </VideoViewValue>
                                    </VideoViewWrapper>
                                    <Duration>
                                        {video.duration === 'live' ? (
                                            <Live>
                                                <LiveBull>&bull;</LiveBull> Live
                                            </Live>
                                        ) : (
                                            video.duration
                                        )}
                                    </Duration>
                                </VideoInfo>
                            </VideoContent>
                        </VideoWrapper>
                    ))}
                </VideoContainer>

                {!showVideoList.length && (
                    <Error>{formatMessage({ id: 'tuition_video_empty_search' })}</Error>
                )}
                {showVideo && <Video onClose={closeHandler} id={video} />}
                <ButtonWrapper>
                    <Button
                        href={formatMessage({ id: 'tuition_ask_button_url' })}                        
                    >
                        {formatMessage({ id: 'tuition_ask_button' })}
                    </Button>
                </ButtonWrapper>
            </Container>
        </Section>
    );
};
