import styled from 'styled-components';

export const FilterWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 35px;
`;

export const FilterInput = styled.input`
    flex: 1;
    margin-left: 50px;
    border: 1px solid ${({ theme }) => theme.color3};
    padding: 0 10px;
    box-sizing: border-box;
    font-weight: 300;
    max-width: 294px;
    &::placeholder {
        color: ${({ theme }) => theme.color4};
        opacity: 1;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px ${({ theme }) => theme.color4};
    }
`;
export const FilterIcon = styled.i`
    position: absolute;
    font-size: 14px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.color3};
`;

export const Table = styled.div`
    margin-top: 20px;
    overflow: auto;
`;

export const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 130px repeat(3, 1fr) 150px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.color3};
    @media (max-width: 1200px) {
        min-width: 600px;
        overflow: scroll;
    }
`;

export const TableCell = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.color4};

    &:nth-child(1),
    &:nth-child(2) {
        text-align: left;
    }
`;

export const TableBody = styled.div``;

export const TableBodyRow = styled.div`
    display: grid;
    grid-template-columns: 130px repeat(3, 1fr) 150px;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.color3};
    @media (max-width: 1200px) {
        min-width: 600px;
        overflow: scroll;
    }
`;

export const TableBodyRowCell = styled.div`
    display: flex;
    align-items: center;

    justify-content: center;
    color: ${({ theme, active, colored }) =>
        colored ? (active ? theme.color15 : theme.color16) : theme.color5};

    &:nth-child(1),
    &:nth-child(2) {
        justify-content: flex-start;
    }
`;

export const TableBodyRowCellIcon = styled.i`
    margin-right: 20px;
    color: ${({ theme }) => theme.color3};
    font-size: 24px;
`;

export const ErrorText = styled.h3`
    text-align: center; 
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: ${({ theme }) => theme.color6};
    margin-top: 20px;
`;
