import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Section } from '../../Section';
import { Loader } from '../../Loader';
import {
    MainNewsImage,
    MainNewsWrapper,
    NewsTitle,
    NewsContent,
    NewsDate
} from './styled';
import { InnerLink } from '../../InnerLink';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const MainNews = () => {
    const { news, isLoading } = useSelector(state => state.news.newsList);

    if (isLoading) return <Loader />;

    return (
        <Section clearTopOffset>
            {!isLoading && news.length && (
                <InnerLink to={`/news/${news[0].alias}`} params={news[0].alias}>
                    <MainNewsWrapper>
                        <MainNewsImage>
                            <LazyLoadImage
                                src={news[0].image}
                                effect="opacity"
                                delayTime={500}
                                alt=""
                            />
                        </MainNewsImage>
                        <NewsContent>
                            <NewsTitle>{news[0].title}</NewsTitle>
                            <NewsDate>
                                {moment
                                    .unix(news[0].created_at)
                                    .format('MMM DD, YYYY | HH:mm')}
                            </NewsDate>
                        </NewsContent>
                    </MainNewsWrapper>
                </InnerLink>
            )}
        </Section>
    );
};
