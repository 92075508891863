import React, { useRef, useEffect } from 'react';
import { createChart } from 'lightweight-charts';
import moment from 'moment';

export default function Chart({ name, data, isNegative, alt, locale }) {
    const chartRef = useRef(null);

    useEffect(() => {
        chartRef.current.innerHTML = '';
        if (data) {
            const chart = createChart(chartRef.current, {
                width: alt ? chartRef.current.offsetWidth : 350,
                height: chartRef.current.offsetHeight || 53,
                localization: {
                    locale
                },
                priceScale: {
                    autoScale: true,
                    scaleMargins: {
                        top: 0.4,
                        bottom: 0.4
                    },
                    position: alt ? 'left' : 'none'
                },
                timeScale: {
                    visible: alt || false,
                    // fixLeftEdge: true,
                    secondsVisible: true,
                    timeVisible: true
                },
                crosshair: {
                    visible: alt || false,
                    horzLine: {
                        visible: alt || false
                    },
                    vertLine: {
                        visible: alt || false
                    }
                },
                layout: {
                    backgroundColor: 'transparent'
                },
                grid: {
                    vertLines: {
                        color: alt ? '#E6E6E6' : 'transparent'
                    },
                    horzLines: {
                        color: alt ? '#E6E6E6' : 'transparent'
                    }
                }
            });
            const lineSeries = chart.addLineSeries({
                priceLineVisible: false,
                color: isNegative ? '#BF0000' : '#2B9241',
                lineWidth: 2
            });
            lineSeries.applyOptions({
                lastValueVisible: false,
                priceFormat: {
                    type: 'custom',
                    minMove: 0.02,
                    formatter: price => price.toFixed(8)
                },
                handleScroll: {
                    mouseWheel: false,
                    pressedMouseMove: false
                },
                handleScale: {
                    axisPressedMouseMove: false,
                    mouseWheel: false,
                    pinch: false
                }
            });

            const modifiedData = data.map(item => ({
                value: item.v,
                time: item.k
            }));
            lineSeries.setData(modifiedData);
            chart.timeScale().fitContent();
            alt &&
                chart.resize(
                    chartRef.current.offsetWidth,
                    chartRef.current.offsetHeight
                );

                chart.timeScale().applyOptions({
                    rightOffset: -0.5,
                });

            if (alt) {
                const toolTipMargin = 10;

                const toolTip = document.createElement('div');
                toolTip.className = 'floating-tooltip';
                chartRef.current
                    .querySelector('.tv-lightweight-charts')
                    .appendChild(toolTip);
                // update tooltip
                chart.subscribeCrosshairMove(function(param) {
                    if (
                        !param.time ||
                        param.point.x < 0 ||
                        param.point.x >
                            chartRef.current.querySelector('div').offsetWidth ||
                        param.point.y < 0 ||
                        param.point.y >
                            chartRef.current.querySelector('div').offsetHeight
                    ) {
                        toolTip.style.display = 'none';
                        return;
                    }

                    toolTip.style.display = 'block';
                    var price = param.seriesPrices.get(lineSeries);
                    toolTip.innerHTML =
                        '<div style="font-size: 10px; margin: 0">' +
                        moment.unix(param.time).format('MM.DD.YYYY hh:mm:ss') +
                        '</div>' +
                        '<div style="font-size: 12px; margin-top: 5px">' +
                        price.toFixed(8) +
                        '</div>';

                    var y = param.point.y;

                    var left = param.point.x + toolTipMargin;
                    if (
                        left >
                        chartRef.current.querySelector('div').offsetWidth -
                            toolTip.offsetWidth
                    ) {
                        left =
                            param.point.x - toolTipMargin - toolTip.offsetWidth;
                    }

                    var top = y + toolTipMargin;
                    if (
                        top >
                        chartRef.current.querySelector('div').offsetHeight -
                            toolTip.offsetHeight
                    ) {
                        top = y - toolTip.offsetHeight - toolTipMargin;
                    }

                    toolTip.style.left = left + 'px';
                    toolTip.style.top = top + 'px';
                });
            }
        }
    }, [data, locale]);

    return <div ref={chartRef} />;
}
