import styled, { css } from 'styled-components';

export const FirstScreenWrapper = styled.section`
    height: calc(100vh - 94px);
    background-image: ${({ background }) => `url(${background})`};
    background-position: 90% 15%;
    background-repeat: no-repeat;
    background-size: 35%;
    padding-bottom: 160px;
    box-sizing: border-box;
    background-color: #000;

    @media (max-height: 950px) {
        min-height: 550px;
    }

    @media (min-height: 1201px) {
        max-height: 1200px;
    }

    @media (max-width: 460px) {
        padding-bottom: 160px;
        max-height: inherit;
    }

    @media (max-width: 1024px) {
        background: #000;
    }
`;

const title = css`
    font-size: 40px;
    line-height: 50px;
    color: ${({ theme }) => theme.color11};
    font-weight: 700;

    @media (max-width: 800px) {
        text-align: center;
    }
    @media (max-height: 950px) {
        font-size: 30px;
        line-height: 40px;
        color: ${({ theme }) => theme.color1};
    }
    @media (max-width: 490px) {
        font-size: 20px;
    }
`;

export const TitleH1 = styled.h1`
    ${title};
    @media (max-height: 950px) {
        font-size: 50px;
        line-height: 40px;
        color: ${({ theme }) => theme.color1};
    }
`;

export const TitleH2 = styled.h2`
    ${title};
`;
export const TitleSpan = styled.span`
    ${title};
    @media (max-height: 950px) {
        color: ${({ theme }) => theme.color11};
    }
`;

export const Logo = styled.img`
    width: 537px;
    margin: 40px 0;

    @media (max-width: 800px) {
        display: block;
        margin: 40px auto;
    }

    @media (max-height: 950px) {
        width: 400px;
        margin: 20px 0;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const Description = styled.div`
    font-size: 24px;
    font-weight: 300;
    margin-top: 40px;
    color: ${({ theme }) => theme.color1};
    max-width: 578px;
    line-height: 36px;

    @media (max-width: 800px) {
        width: 100%;
        max-width: inherit;
    }
    @media (max-height: 950px) {
        font-size: 18px;
        margin-top: 20px;
    }
    @media (max-width: 490px) {
        font-size: 15px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 40px;

    @media (max-width: 800px) {
        justify-content: center;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const ButtonOffset = styled.div`
    margin-right: 37px;

    &:last-child {
        margin-right: 0;
    }

    @media (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 37px;
        width: 217px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: 490px) {
        width: auto;
    }
`;
