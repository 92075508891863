import styled from 'styled-components';

export const Text = styled.p`
    text-align: center;
    font-size: 24px;
    line-height: 36px;
`;
export const SelectedText = styled.span`
    color: ${({ theme }) => theme.color5};
    background: ${({ theme }) => theme.color2};
    padding: 5px; 
    position: absolute;
    font-family: 'Museo Sans Cyrl', Arial, sans-serif;
    font-weight: 300;
    font-size: 21px;
    left: 50%;
    transform: translateX(-50%);
    top: -37px;
    opacity: 0;
    transition: opacity 150ms;
`;

export const PressContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 35px;

    @media (max-width: 1600px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1380px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 760px) {
        grid-template-columns: 1fr;
    }
`;

export const PressItem = styled.div`
    width: 100%;
    max-height: 140px;
    border: 1px solid ${({ theme }) => theme.color3};
`;

export const PressImage = styled.img`
    width: 100%;
    height: 95px;
    object-fit: cover;
    display: block;
`;

export const PressLinkWrapper = styled.div`
    background: ${({ theme }) => theme.color5};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
`;

export const PressLink = styled.div`
    color: ${({ theme }) => theme.color1};
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 395px) {
        width: 200px;
    }
`;

export const PressIcon = styled.i`
    color: ${({ theme }) => theme.color2};
    font-size: 24px;
    cursor: pointer;
    position: relative;
    &:hover {
        color: ${({ theme }) => theme.color11};
    }
    &:active span {
        opacity: 1;
    }
`;
export const EmptyText = styled.div`
    text-align: center;
    font-size: 40px;
    line-height: 50px; 
    color: ${({ theme }) => theme.color6};
`;
