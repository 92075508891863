import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import {
    Text,
    TitleH2,
    TitleH3,
    Content,
    StepText,
    CheckDot,
    StepItem,
    CheckText,
    CheckList,
    StepNumber,
    StepWrapper,
    ListingFrom,
    ListingPanel,
    CheckListItem,
    ListingPanelInput,
    ListingPanelHeader,
    ListingPanelCheckbox,
    ListingPanelAddButton,
    ListingPanelInputLabel,
    ListingPanelCheckboxes,
    ListingPanelHeaderTitle,
    ListingPanelHeaderArrow,
    ListingPanelCheckboxText,
    ListingPanelCheckboxItem,
    ListingPanelInputsGroup,
    ListingPanelInputWrapper,
    ListingPanelTextarea,
    ListingPanelTextareaWrapper,
    ListingPanelInputsGroupDuo,
    ListingPanelInputIcon,
    ListingPanelLabelSub,
    Info,
    InfoLabel,
    InfoValue,
    InfoWrapper
} from './styled';
import { Button } from '../Button';
import config from '../../config/url';

export const Listing = () => {
    const { formatMessage } = useIntl();
    return (
        <Section>
            <Container>
                <Title>{formatMessage({ id: 'listing_main_title' })}</Title>
                <Text>
                    {formatMessage({ id: 'listing_paragraph_1' })}&nbsp;
                    {formatMessage({ id: 'listing_paragraph_1_1' })}
                </Text>
                <Content>
                    <TitleH3>
                        {formatMessage({ id: 'listing_list_title' })}
                    </TitleH3>
                    <CheckList>
                        <CheckListItem>
                            <CheckDot />
                            <CheckText>
                                {formatMessage({ id: 'listing_list_item_1' })}
                            </CheckText>
                        </CheckListItem>
                        <CheckListItem>
                            <CheckDot />
                            <CheckText>
                                {formatMessage({ id: 'listing_list_item_2' })}
                            </CheckText>
                        </CheckListItem>
                        <CheckListItem>
                            <CheckDot />
                            <CheckText>
                                {formatMessage({ id: 'listing_list_item_3' })}
                            </CheckText>
                        </CheckListItem>
                        <CheckListItem>
                            <CheckDot />
                            <CheckText>
                                {formatMessage({ id: 'listing_list_item_4' })}
                            </CheckText>
                        </CheckListItem>
                    </CheckList>
                    <TitleH2>
                        {formatMessage({ id: 'listing_main_title_2' })}
                    </TitleH2>
                    <StepWrapper>
                        <StepItem>
                            <StepNumber>1</StepNumber>
                            <StepText>
                                {formatMessage({ id: 'listing_step_1' })}
                            </StepText>
                        </StepItem>
                        <StepItem>
                            <StepNumber>2</StepNumber>
                            <StepText>
                                {formatMessage({ id: 'listing_step_2' })}
                            </StepText>
                        </StepItem>
                        <StepItem>
                            <StepNumber>3</StepNumber>
                            <StepText>
                                {formatMessage({ id: 'listing_step_3' })}
                            </StepText>
                        </StepItem>
                        <StepItem>
                            <StepNumber>4</StepNumber>
                            <StepText>
                                {formatMessage({ id: 'listing_step_4' })}
                            </StepText>
                        </StepItem>
                    </StepWrapper>
                    <TitleH2>
                        {formatMessage({ id: 'listing_main_title_3' })}
                    </TitleH2>
                    <Text>{formatMessage({ id: 'listing_paragraph_2' })}</Text>
                    <ListingFrom>
                        <ListingPanel>
                            <ListingPanelHeader>
                                <ListingPanelHeaderTitle>
                                    {formatMessage({
                                        id: 'listing_form_contacts'
                                    })}
                                </ListingPanelHeaderTitle>
                                <ListingPanelHeaderArrow className="icon-arrow-down" />
                            </ListingPanelHeader>
                            <ListingPanelInputsGroup>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_email'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id: 'listing_form_email_placeholder'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_full_name'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id:
                                                'listing_form_full_name_placeholder'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_title'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id: 'listing_form_title_placeholder'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                            </ListingPanelInputsGroup>
                        </ListingPanel>

                        <ListingPanel>
                            <ListingPanelHeader>
                                <ListingPanelHeaderTitle>
                                    {formatMessage({
                                        id: 'listing_form_project_overview'
                                    })}
                                </ListingPanelHeaderTitle>
                                <ListingPanelHeaderArrow className="icon-arrow-down" />
                            </ListingPanelHeader>
                            <ListingPanelInputsGroup>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_project_name'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id:
                                                'listing_form_project_name_placeholder'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_project_website'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id:
                                                'listing_form_project_website_placeholder'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id:
                                                'listing_form_project_whitepaper'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id:
                                                'listing_form_project_whitepaper_placeholder'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                            </ListingPanelInputsGroup>
                            <ListingPanelTextareaWrapper>
                                <ListingPanelInputLabel>
                                    {formatMessage({
                                        id: 'listing_form_project_description'
                                    })}
                                </ListingPanelInputLabel>
                                <ListingPanelTextarea>
                                    {formatMessage({
                                        id:
                                            'listing_form_project_description_placeholder'
                                    })}
                                </ListingPanelTextarea>
                            </ListingPanelTextareaWrapper>
                        </ListingPanel>

                        <ListingPanel>
                            <ListingPanelHeader>
                                <ListingPanelHeaderTitle>
                                    {formatMessage({
                                        id: 'listing_form_token'
                                    })}
                                </ListingPanelHeaderTitle>
                                <ListingPanelHeaderArrow className="icon-arrow-down" />
                            </ListingPanelHeader>
                            <ListingPanelInputsGroup>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_token_name'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id: 'listing_form_token_name_plc'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_token_symbol'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id: 'listing_form_token_symbol_plc'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel
                                        sub={formatMessage({
                                            id:
                                                'listing_form_token_blockchain_1'
                                        })}
                                    >
                                        {formatMessage({
                                            id: 'listing_form_token_blockchain'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id:
                                                'listing_form_token_blockchain_plc'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                            </ListingPanelInputsGroup>
                            <ListingPanelInputsGroup>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel
                                        sub={formatMessage({
                                            id: 'listing_form_token_smart_1'
                                        })}
                                    >
                                        {formatMessage({
                                            id: 'listing_form_token_smart'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id: 'listing_form_token_smart_plc'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_token_official'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id:
                                                'listing_form_token_official_plc'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id: 'listing_form_token_github'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id: 'listing_form_token_github_plc'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                            </ListingPanelInputsGroup>
                            <ListingPanelTextareaWrapper>
                                <ListingPanelInputLabel>
                                    {formatMessage({
                                        id: 'listing_form_token_description'
                                    })}
                                </ListingPanelInputLabel>
                                <ListingPanelTextarea>
                                    {formatMessage({
                                        id: 'listing_form_token_description_plc'
                                    })}
                                </ListingPanelTextarea>
                            </ListingPanelTextareaWrapper>
                        </ListingPanel>

                        <ListingPanel>
                            <ListingPanelHeader>
                                <ListingPanelHeaderTitle>
                                    {formatMessage({
                                        id: 'listing_form_exchanges'
                                    })}
                                </ListingPanelHeaderTitle>
                                <ListingPanelHeaderArrow className="icon-arrow-down" />
                            </ListingPanelHeader>
                            <ListingPanelInputsGroupDuo>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id:
                                                'listing_form_exchanges_requested'
                                        })}
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id:
                                                'listing_form_exchanges_requested_plc'
                                        })}{' '}
                                        <ListingPanelInputIcon className="icon-arrow-down" />
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                                <ListingPanelInputWrapper>
                                    <ListingPanelInputLabel>
                                        {formatMessage({
                                            id:
                                                'listing_form_exchanges_coinmarketcap'
                                        })}
                                        &nbsp;
                                        <ListingPanelLabelSub>
                                            {formatMessage({
                                                id:
                                                    'listing_form_exchanges_coinmarketcap_1'
                                            })}
                                        </ListingPanelLabelSub>
                                    </ListingPanelInputLabel>
                                    <ListingPanelInput>
                                        {formatMessage({
                                            id:
                                                'listing_form_exchanges_coinmarketcap_plc'
                                        })}
                                    </ListingPanelInput>
                                </ListingPanelInputWrapper>
                            </ListingPanelInputsGroupDuo>
                            <ListingPanelInputWrapper offset>
                                <ListingPanelInputLabel>
                                    {formatMessage({
                                        id: 'listing_form_exchanges_already'
                                    })}
                                    &nbsp;
                                    <ListingPanelLabelSub>
                                        {formatMessage({
                                            id:
                                                'listing_form_exchanges_already_1'
                                        })}
                                    </ListingPanelLabelSub>
                                </ListingPanelInputLabel>
                                <ListingPanelInput>
                                    {formatMessage({
                                        id: 'listing_form_exchanges_already_plc'
                                    })}
                                </ListingPanelInput>
                            </ListingPanelInputWrapper>
                            <ListingPanelAddButton />
                        </ListingPanel>

                        <ListingPanel>
                            <ListingPanelHeader>
                                <ListingPanelHeaderTitle>
                                    {formatMessage({
                                        id: 'listing_form_community'
                                    })}
                                </ListingPanelHeaderTitle>
                                <ListingPanelHeaderArrow className="icon-arrow-down" />
                            </ListingPanelHeader>
                            <ListingPanelInputWrapper offset>
                                <ListingPanelInputLabel>
                                    {formatMessage({
                                        id: 'listing_form_community_social'
                                    })}
                                </ListingPanelInputLabel>
                                <ListingPanelInput>
                                    {formatMessage({
                                        id: 'listing_form_community_social_plc'
                                    })}
                                </ListingPanelInput>
                            </ListingPanelInputWrapper>
                            <ListingPanelAddButton />
                        </ListingPanel>

                        <ListingPanel>
                            <ListingPanelHeader>
                                <ListingPanelHeaderTitle>
                                    {formatMessage({
                                        id: 'listing_form_marketing'
                                    })}
                                </ListingPanelHeaderTitle>
                                <ListingPanelHeaderArrow className="icon-arrow-down" />
                            </ListingPanelHeader>
                            <ListingPanelInputWrapper offset>
                                <ListingPanelInputLabel>
                                    {formatMessage({
                                        id: 'listing_form_marketing_title'
                                    })}
                                </ListingPanelInputLabel>
                            </ListingPanelInputWrapper>

                            <ListingPanelCheckboxes>
                                <ListingPanelCheckboxItem>
                                    <ListingPanelCheckbox active />
                                    <ListingPanelCheckboxText>
                                        {formatMessage({
                                            id: 'listing_form_marketing_item_1'
                                        })}
                                    </ListingPanelCheckboxText>
                                </ListingPanelCheckboxItem>
                                <ListingPanelCheckboxItem>
                                    <ListingPanelCheckbox active />
                                    <ListingPanelCheckboxText>
                                        {formatMessage({
                                            id: 'listing_form_marketing_item_2'
                                        })}
                                    </ListingPanelCheckboxText>
                                </ListingPanelCheckboxItem>
                                <ListingPanelCheckboxItem>
                                    <ListingPanelCheckbox />
                                    <ListingPanelCheckboxText>
                                        {formatMessage({
                                            id: 'listing_form_marketing_item_3'
                                        })}
                                    </ListingPanelCheckboxText>
                                </ListingPanelCheckboxItem>
                                <ListingPanelCheckboxItem>
                                    <ListingPanelCheckbox />
                                    <ListingPanelCheckboxText>
                                        {formatMessage({
                                            id: 'listing_form_marketing_item_4'
                                        })}
                                    </ListingPanelCheckboxText>
                                </ListingPanelCheckboxItem>
                                <ListingPanelCheckboxItem>
                                    <ListingPanelCheckbox />
                                    <ListingPanelCheckboxText>
                                        {formatMessage({
                                            id: 'listing_form_marketing_item_5'
                                        })}
                                    </ListingPanelCheckboxText>
                                </ListingPanelCheckboxItem>
                                <ListingPanelCheckboxItem>
                                    <ListingPanelCheckbox active />
                                    <ListingPanelCheckboxText>
                                        {formatMessage({
                                            id: 'listing_form_marketing_item_6'
                                        })}
                                    </ListingPanelCheckboxText>
                                </ListingPanelCheckboxItem>
                            </ListingPanelCheckboxes>

                            <ListingPanelTextarea>
                                {formatMessage({
                                    id: 'listing_form_marketing_proposal'
                                })}
                            </ListingPanelTextarea>
                        </ListingPanel>
                    </ListingFrom>
                    <Text>{formatMessage({ id: 'listing_paragraph_3' })}</Text>
                    <Button
                        custom
                        href={formatMessage({ id: 'listing_submit_url' })}                        
                    >
                        {formatMessage({ id: 'listing_submit' })}
                    </Button>
                    {/*<InfoWrapper>*/}
                    {/*    <Info>*/}
                    {/*        <InfoLabel>*/}
                    {/*            {formatMessage({*/}
                    {/*                id: 'listing_footer_general'*/}
                    {/*            })}*/}
                    {/*        </InfoLabel>*/}
                    {/*        <InfoValue*/}
                    {/*            href={`mailto:${formatMessage({*/}
                    {/*                id: 'listing_footer_mail_info'*/}
                    {/*            })}`}*/}
                    {/*        >*/}
                    {/*            {formatMessage({*/}
                    {/*                id: 'listing_footer_mail_info'*/}
                    {/*            })}*/}
                    {/*        </InfoValue>*/}
                    {/*    </Info>*/}
                    {/*    <Info>*/}
                    {/*        <InfoLabel>*/}
                    {/*            {formatMessage({*/}
                    {/*                id: 'listing_footer_listing'*/}
                    {/*            })}*/}
                    {/*        </InfoLabel>*/}
                    {/*        <InfoValue*/}
                    {/*            href={`mailto:${formatMessage({*/}
                    {/*                id: 'listing_footer_mail_listing'*/}
                    {/*            })}`}*/}
                    {/*        >*/}
                    {/*            {formatMessage({*/}
                    {/*                id: 'listing_footer_mail_listing'*/}
                    {/*            })}*/}
                    {/*        </InfoValue>*/}
                    {/*    </Info>*/}
                    {/*    <Info>*/}
                    {/*        <InfoLabel>*/}
                    {/*            {formatMessage({*/}
                    {/*                id: 'listing_footer_partnership'*/}
                    {/*            })}*/}
                    {/*        </InfoLabel>*/}
                    {/*        <InfoValue*/}
                    {/*            href={`mailto:${formatMessage({*/}
                    {/*                id: 'listing_footer_mail_partnership'*/}
                    {/*            })}`}*/}
                    {/*        >*/}
                    {/*            {formatMessage({*/}
                    {/*                id: 'listing_footer_mail_partnership'*/}
                    {/*            })}*/}
                    {/*        </InfoValue>*/}
                    {/*    </Info>*/}
                    {/*</InfoWrapper>*/}
                </Content>
            </Container>
        </Section>
    );
};
