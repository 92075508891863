import React from 'react';
import { useIntl } from 'react-intl';
import { Section } from '../Section';
import { Container } from '../Container';
import { Title } from '../Title';
import {
    P,
    Span,
    Content,
    Li,
    Ul,
    TitleText,
    SelectedTitle,
    TitleDescription
} from './styled';

export const OurTeam = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <Section>
                <Container>
                    <Title>
                        {formatMessage({ id: 'content_our_team_tittle' })}
                    </Title>
                    <Content>
                        <P>
                            <Span>
                                {formatMessage({
                                    id:
                                        'content_our_team_section_header_company'
                                })}
                            </Span>
                            &nbsp;
                            {formatMessage({
                                id: 'content_our_team_section_header'
                            })}
                        </P>
                        <P>
                            {formatMessage({
                                id: 'content_our_team_section_company_two'
                            })}
                        </P>
                        <Ul>
                            <Li>
                                {formatMessage({
                                    id: 'content_our_team_list1_market'
                                })}
                            </Li>
                            <Li>
                                {formatMessage({
                                    id: 'content_our_team_list1_marketing'
                                })}
                            </Li>
                            <Li>
                                {formatMessage({
                                    id: 'content_our_team_list1_software'
                                })}
                            </Li>
                            <Li>
                                {formatMessage({
                                    id: 'content_our_team_list1_professional'
                                })}
                            </Li>
                            <Li>
                                {formatMessage({
                                    id: 'content_our_team_list1_angel'
                                })}
                            </Li>
                        </Ul>
                        <P>
                            {formatMessage({
                                id: 'content_our_team_section_technology'
                            })}
                        </P>
                        <P>
                            <Span>
                                {formatMessage({
                                    id:
                                        'content_our_team_section_header_company'
                                })}
                            </Span>
                            &nbsp;
                            {formatMessage({
                                id: 'content_our_team_section_company'
                            })}
                        </P>
                    </Content>
                </Container>
            </Section>
            <Section second>
                <Container>
                    <TitleText>
                        {formatMessage({
                            id: 'content_our_team_section_header_company'
                        })}
                        &nbsp;
                        {formatMessage({
                            id: 'content_our_team_section_company_mission'
                        })}
                    </TitleText>
                    <TitleDescription>
                        {formatMessage({
                            id: 'content_our_team_section_company_one'
                        })}
                    </TitleDescription>
                </Container>
            </Section>
            <Section>
                <Container>
                    <P>
                        {formatMessage({
                            id: 'content_our_team_section_company_two'
                        })}
                    </P>
                    <Ul>
                        <Li>
                            {formatMessage({
                                id: 'content_our_team_list2_sustainability'
                            })}
                        </Li>
                        <Li>
                            {formatMessage({
                                id: 'content_our_team_list2_user'
                            })}
                        </Li>
                        <Li>
                            {formatMessage({
                                id: 'content_our_team_list2_secure'
                            })}
                        </Li>
                        <Li>
                            {formatMessage({
                                id: 'content_our_team_list2_operationally'
                            })}
                        </Li>
                        <Li>
                            {formatMessage({
                                id: 'content_our_team_list2_attractive'
                            })}
                        </Li>
                        <Li>
                            {formatMessage({
                                id: 'content_our_team_list2_dependable'
                            })}
                        </Li>
                        <Li>
                            {formatMessage({
                                id: 'content_our_team_list2_technologically'
                            })}
                        </Li>
                    </Ul>
                    <P>
                        {formatMessage({
                            id: 'content_our_team_section_considered'
                        })}
                    </P>
                </Container>
            </Section>
        </>
    );
};
