import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { PromoActivities } from '../components/PromoActivitiesComponents';

export default () => {
    const { formatMessage } = useIntl();
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content={formatMessage({
                        id: 'promo_activities_meta_description'
                    })}
                />
                <meta
                    name="keywords"
                    content={formatMessage({
                        id: 'promo_activities_meta_keywords'
                    })}
                />
                <title>
                    {formatMessage({ id: 'promo_activities_meta_title' })}
                </title>
            </Helmet>
            <PromoActivities />
        </React.Fragment>
    );
};
